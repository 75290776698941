import {
    FETCH_GRADOS_SUCCESS,
    FETCH_GRADOS_FAILURE,
    CREATE_GRADO_SUCCESS,
    CREATE_GRADO_FAILURE,
    UPDATE_GRADO_SUCCESS,
    UPDATE_GRADO_FAILURE,
    DELETE_GRADO_SUCCESS,
    DELETE_GRADO_FAILURE,
    FETCH_GRADO_REQUEST,
    FETCH_GRADO_SUCCESS,
    FETCH_GRADO_FAILURE

 
  } from '../actions/gradoActions';
  
  const initialState = {
    grados: [],
    grado:[],
    error: null
  };
  
  const gradoReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_GRADOS_SUCCESS:
        return {
          ...state,
          grados: action.payload,
          error: null
        };
      case FETCH_GRADO_SUCCESS:
          return {
            ...state,
            grado: action.payload,
            error: null
          };  
      case FETCH_GRADOS_FAILURE:
      case CREATE_GRADO_FAILURE:
      case UPDATE_GRADO_FAILURE:
      case DELETE_GRADO_FAILURE:
        return {
          ...state,
          error: action.payload
        };
      case CREATE_GRADO_SUCCESS:
        return {
          ...state,
          grados: [...state.grados, action.payload],
          error: null
        };
      case UPDATE_GRADO_SUCCESS:
        return {
          ...state,
          grados: state.grados.map(grado =>
            grado.id === action.payload.id ? action.payload : grado
          ),
          error: null
        };
      case DELETE_GRADO_SUCCESS:
        return {
          ...state,
          grados: state.grados.filter(grado => grado.id !== action.payload),
          error: null
        };
      default:
        return state;
    }
  };
  
  export default gradoReducer;
  