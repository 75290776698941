// cuotaReducer.js
import {
    FETCH_CUOTAS_REQUEST,
    FETCH_CUOTAS_SUCCESS,
    FETCH_CUOTAS_FAILURE,
    UPDATE_CUOTA_REQUEST,
    UPDATE_CUOTA_SUCCESS,
    UPDATE_CUOTA_FAILURE,
    FETCH_CUOTAS_BY_RECIBO_REQUEST,
    FETCH_CUOTAS_BY_RECIBO_SUCCESS,
    FETCH_CUOTAS_BY_RECIBO_FAILURE,
    GENERAR_CUOTAS_REQUEST,
    GENERAR_CUOTAS_SUCCESS,
    GENERAR_CUOTAS_FAILURE,
    FETCH_CUOTAS_PENDIENTES_REQUEST,
    FETCH_CUOTAS_PENDIENTES_SUCCESS,
    FETCH_CUOTAS_PENDIENTES_FAILURE,
    CLEAR_CUOTAS
} from '../actions/cuotaActions';

const initialState = {
    cuotasPendientes:[],
    cuotas: [],
    loading: false,
    error: null,
    success: false,
    message: '',
};

const cuotaReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CUOTAS_REQUEST:
        case FETCH_CUOTAS_BY_RECIBO_REQUEST:
        case FETCH_CUOTAS_PENDIENTES_REQUEST:    
        case UPDATE_CUOTA_REQUEST:
            return {
                ...state,
                loading: true,
                cuotas:[],
                cuotasPendientes:[]
            };
        case FETCH_CUOTAS_SUCCESS:    
        case FETCH_CUOTAS_BY_RECIBO_SUCCESS:
            return {
                ...state,
                loading: false,
                cuotas: action.payload,
                error: null
            };
        case FETCH_CUOTAS_PENDIENTES_SUCCESS:
            return {
                ...state,
                loading: false,
                cuotasPendientes: action.payload,
                error: null
            };   
        case UPDATE_CUOTA_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                cuotas: state.cuotas.map((cuota) =>
                    cuota.id === action.payload.id ? action.payload : cuota
                )
            };
        case FETCH_CUOTAS_FAILURE:
        case FETCH_CUOTAS_BY_RECIBO_FAILURE:
        case FETCH_CUOTAS_PENDIENTES_FAILURE:
        case UPDATE_CUOTA_FAILURE:
            return {
                ...state,
                cuotas:[],
                cuotasPendientes:[],
                loading: false,
                error: action.payload
            };
        case GENERAR_CUOTAS_REQUEST:
                return { ...state, loading: true, success: false, error: null };
        case GENERAR_CUOTAS_SUCCESS:
                return { ...state, loading: false, success: true, error: null };
        case GENERAR_CUOTAS_FAILURE:
                return { ...state, loading: false, success: false, error: action.payload };
        case CLEAR_CUOTAS:
                    return { ...state, cuotas: [] };
        default:
            return state;
    }
};

export default cuotaReducer;

