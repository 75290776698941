import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { updateSocio } from '../actions/socioActions';
import { fetchCuotasBySocio, clearCuotas } from '../actions/cuotaActions';
import { FaExclamationTriangle } from 'react-icons/fa';
import './SocioCambioEstado.css'; // Importar el archivo CSS

const SocioCambioEstado = ({ show, handleClose, socio, onSocioUpdated }) => {
  const [newState, setNewState] = useState(socio.estado);
  const [saldo, setSaldo] = useState(0);
  const [cuotasVencidas, setCuotasVencidas] = useState(0);
  const dispatch = useDispatch();
  const { cuotas, loading, error } = useSelector(state => state.cuota);

  useEffect(() => {
    if (socio && show) {
      dispatch(clearCuotas());
      dispatch(fetchCuotasBySocio(socio.id));
    }
  }, [socio, show, dispatch]);

  useEffect(() => {
    if (cuotas) {
      const fechaActual = new Date();
      const cuotasVencidas = cuotas.filter(cuota => cuota.estado === 0 && new Date(cuota.feemis) <= fechaActual);
      const totalSaldo = cuotasVencidas.reduce((acc, cuota) => acc + cuota.monto, 0);
      const cuotasCount = cuotasVencidas.length;
      
      setSaldo(totalSaldo);
      setCuotasVencidas(cuotasCount);
    }
  }, [cuotas]);

  const handleSave = () => {
    if (newState !== socio.estado) {
      dispatch(updateSocio(socio.id, { estado: newState }))
        .then(() => {
          alert('Estado del socio actualizado correctamente');
          handleClose();
          onSocioUpdated();
        })
        .catch(error => alert('Error al actualizar el estado del socio: ' + error.message));
    } else {
      alert('El estado es el mismo...');
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Actualizar Estado del Socio</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <FaExclamationTriangle style={{ color: 'red', marginRight: '5px', fontSize: '3em' }} />
          <span style={{ fontSize: '1.5em', color: 'darkgreen', fontWeight: 'bold', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)' }}>Alerta:</span>
        </div>
        <p style={{ marginBottom: '20px' }}>
          El cambio de Estado de un socio, implica que algunas funcionalidades estarán restringidas. Los socios inactivos no podrán acceder a certificados, pero se generan las cuotas anuales. Para los socios dados de baja No se generan las cuotas anuales.
        </p>
        {loading ? (
          <div>Cargando...</div>
        ) : error ? (
          <div>Error: {error}</div>
        ) : (
          <Form>
            <Row className="mb-3">
              <Col xs={6}>
                <strong>Registro Nro.:</strong> <span className="data-large">{socio.codreg}</span>
              </Col>
              <Col xs={6}>
                <strong>Socio:</strong> <span className="data-large">{`${socio.apPat} ${socio.apMat} ${socio.nombres}`}</span>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={6}>
                <strong>Saldo Deudor:</strong> <span className="data-large">{saldo}</span>
              </Col>
              <Col xs={6}>
                <strong>Nro. de Cuotas Vencidas:</strong> <span className="data-large">{cuotasVencidas}</span>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12}>
                <strong>Estado Actual:</strong> <span className="estado-actual">{socio.estado === 1 ? 'Activo' : socio.estado === 0 ? 'Inactivo' : 'Dado de Baja'}</span>
              </Col>
            </Row>
            <Form.Group controlId="formNuevoEstado" className="mb-3">
              <Form.Label>Nuevo Estado:</Form.Label>
              <Form.Control
                as="select"
                value={newState}
                onChange={(e) => setNewState(Number(e.target.value))}
              >
                <option value="1">Activo</option>
                <option value="0">Inactivo</option>
                <option value="2">Dado de Baja</option>
              </Form.Control>
            </Form.Group>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer className='button-group'>
        <button className="button btn-cancel" onClick={handleClose}>
          Cancelar
        </button>
        <button className="button btn-accent" onClick={handleSave}>
          Grabar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default SocioCambioEstado;
