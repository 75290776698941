import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCargos, deleteCargo } from '../actions/cargoActions';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import CargoForm from './CargoForm';
import { useNavigate, useLocation } from 'react-router-dom';
import './CargoList.css';  // Importamos los estilos CSS
import { toast } from 'react-toastify';
import { Pagination, Table, Button } from 'react-bootstrap';
import CustomPrintModal from './CustomPrintModal';

const CargoList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        const fromHeader = location.state?.fromHeader;
        //console.log('fromHeader =', fromHeader);
        if (!fromHeader) {
            navigate('/'); // Redirige a la página principal si no es llamado desde Header
        }
    }, [navigate]);
    const dispatch = useDispatch();
    const { cargos, error } = useSelector(state => state.cargo);
    const [showForm, setShowForm] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [showPrintModal, setShowPrintModal] = useState(false);
    const [content, setContent] = useState(null);

    useEffect(() => {
        dispatch(fetchCargos());
    }, [dispatch]);

    const handleDelete = (id) => {
        confirmAlert({
            title: 'Confirmar eliminación',
            message: '¿Estás seguro de que deseas eliminar este cargo?',
            buttons: [
                {
                    label: 'Sí',
                    onClick: () => {
                        dispatch(deleteCargo(id))
                            .then(() => {
                                toast.success('Cargo eliminado correctamente');
                                dispatch(fetchCargos());
                            })
                            .catch(error => {
                                toast.error(`Error al eliminar el cargo: ${error.message}`);
                            });
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    };

    const handleShowForm = (id = null) => {
        setSelectedId(id);
        setIsEdit(!!id);
        setShowForm(true);
    };

    const handleCloseForm = () => {
        setShowForm(false);
        setSelectedId(null);
        setIsEdit(false);
    };

    const handlePrintModalShow = () => {
        const content = {
            head: [["N°", "Nombre", "Presidente", "Estado"]],
            body: cargos.map((cargo, index) => [
                index+1,
                cargo.cargo,
                cargo.presidente ? 'Sí' : 'No',
                cargo.estado === 1 ? 'ACTIVO' : 'INACTIVO'
            ])
        };
        setContent(content);
        setShowPrintModal(true);
    };

    const handlePrintModalClose = () => {
        setShowPrintModal(false);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentCargos = cargos.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(1);
    };
    const totalPages = Math.ceil(cargos.length / itemsPerPage);

    if (error) {
        toast.error(`Error al cargar los datos: ${error}`);
    }

    return (
        <div className="list2-container container-list">
            <h2>Listado de Cargos</h2>
            <div className="row mt-3">
              
                <div className='col-lg-8'>
                <label htmlFor="items-per-page">Registros por página:</label>
                <select id="items-per-page" value={itemsPerPage} onChange={handleItemsPerPageChange}>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                </select></div>
                <div className='col-lg-3 float-lg-end'>
                <button onClick={() => handleShowForm()} className="button btn-accent">
                    <i className="bi bi-plus-lg"></i> 
                    Nuevo Cargo
                </button>
                </div>
            </div>
            <div className='table-container'>
                <Table hover striped border={1}>
                    <thead>
                        <tr>
                            <th className='th-cod'>N°</th>
                            <th>Nombre</th>
                            <th>Presidente</th>
                            <th>Estado</th>
                            <th className='th-actions'>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentCargos.map((cargo, index) => (
                            <tr key={cargo.id}>
                               <td className='td-cod'>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                               <td>{cargo.cargo}</td>
                                <td>{cargo.presidente ? 'Sí' : 'No'}</td>
                                <td>{cargo.estado === 1 ? 'ACTIVO' : 'INACTIVO'}</td>
                                <td>
                                    <button onClick={() => handleShowForm(cargo.id)} className="button btn-accent-outline"><i className="bi bi-pencil"></i></button>
                                    <button onClick={() => handleDelete(cargo.id)} className="button btn-accent-outline"><i className="bi bi-trash"></i></button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
            
            <div className='button-group separate'>
                <button onClick={() => navigate('/')} className="button btn-cancel">
                <i className="bi bi-arrow-left"></i>  Volver</button>
                <button onClick={handlePrintModalShow} className="button btn-accent">
                <i className="bi bi-printer"></i> Imprimir</button>
            </div>

            <div className="pagination-container">
                <Pagination className="custom-pagination">
                    <Pagination.First
                        onClick={() => paginate(1)}
                        disabled={currentPage === 1}
                    />
                    <Pagination.Prev
                        onClick={() => paginate(currentPage - 1)}
                        disabled={currentPage === 1}
                    />
                    {[...Array(totalPages)].map((_, index) => (
                        <Pagination.Item
                            key={index + 1}
                            active={index + 1 === currentPage}
                            onClick={() => paginate(index + 1)}
                        >
                            {index + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next
                        onClick={() => paginate(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    />
                    <Pagination.Last
                        onClick={() => paginate(totalPages)}
                        disabled={currentPage === totalPages}
                    />
                </Pagination>
            </div>

            {showForm && (
                <CargoForm
                    show={showForm}
                    handleClose={handleCloseForm}
                    isEdit={isEdit}
                    selectedId={selectedId}
                />
            )}

            <CustomPrintModal show={showPrintModal} handleClose={handlePrintModalClose} title="LISTADO DE CARGOS" content={content} />
        </div>
    );
};

export default CargoList;
