import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createNoticia, updateNoticia, fetchNoticiaById } from '../actions/noticiaActions';
import { Modal, Button, Form, Image } from 'react-bootstrap';
import { toast } from 'react-toastify';
import placeholder from '../assets/user-profile-photo.png';
import { getAuthToken } from '../util/auth';
import { formatDate, getCurrentDate } from '../util/utilFunctions';

const NoticiaForm = ({ show, handleClose, isEdit, selectedId }) => {
    const dispatch = useDispatch();
    const { currentNoticia } = useSelector(state => state.noticia);


    const [noticiaData, setNoticiaData] = useState({ 
        titulo: '', 
        descripcion: '', 
        fecha: getCurrentDate(), 
        foto: null 
    });

    const [originalData, setOriginalData] = useState(null);
    const [fotoPreview, setFotoPreview] = useState(placeholder);
    const [newPhotoPreview, setNewPhotoPreview] = useState(null);
    const [showPreview, setShowPreview] = useState(false);
    const UPLOADS_BASE_URL = process.env.REACT_APP_UPLOADS_BASE_URL;

    useEffect(() => {
        if (isEdit && selectedId) {
            dispatch(fetchNoticiaById(selectedId));
        }
    }, [dispatch, isEdit, selectedId]);

    useEffect(() => {
        if (isEdit && currentNoticia && currentNoticia.id === selectedId) {
            const initialData = {
                titulo: currentNoticia.titulo,
                descripcion: currentNoticia.descripcion,
                fecha: formatDate(currentNoticia.fecha),
                foto: currentNoticia.foto,
            };
            setNoticiaData(initialData);
            setOriginalData(initialData);

            const token = getAuthToken();
            const timestamp = new Date().getTime();

            if (currentNoticia.foto) {
                const fotoUrl = `${UPLOADS_BASE_URL}/${currentNoticia.foto}?token=${token}&timestamp=${timestamp}`;
                setFotoPreview(fotoUrl);
            }
        }
    }, [isEdit, currentNoticia, selectedId]);

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        if (type === 'file') {
            const file = files[0];
            const newPhotoUrl = URL.createObjectURL(file);
            setNoticiaData({ ...noticiaData, [name]: file });
            setNewPhotoPreview(newPhotoUrl);
        } else {
            setNoticiaData({
                ...noticiaData,
                [name]: value
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        for (const key in noticiaData) {
            formData.append(key, noticiaData[key]);
        }

        if (JSON.stringify(noticiaData) === JSON.stringify(originalData) && !newPhotoPreview) {
            toast.info('No se han realizado cambios.');
            handleClose();
            return;
        }

        try {
            if (isEdit) {
                await dispatch(updateNoticia(selectedId, formData));
                toast.success('Noticia actualizada correctamente');
            } else {
                await dispatch(createNoticia(formData));
                toast.success('Noticia creada correctamente');
            }
            handleClose();
        } catch (error) {
            toast.error(`Error al procesar la noticia: ${error.message || error}`);
        }
    };

    const resetPhoto = () => {
        setNewPhotoPreview(null);
        setNoticiaData({ ...noticiaData, foto: originalData.foto });
    };

    const togglePreview = () => {
        setShowPreview(!showPreview);
    };

    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="md">
            <Modal.Header closeButton>
                <Modal.Title>{isEdit ? 'Editar Noticia' : 'Crear Noticia'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="d-flex justify-content-between">
                        <div style={{ flex: 1 }}>
                            <Form.Group controlId="titulo">
                                <Form.Label>Título:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="titulo"
                                    value={noticiaData.titulo}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="contenido" className="mt-3">
                                <Form.Label>Descripción:</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={5}
                                    name="descripcion"
                                    value={noticiaData.descripcion}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="fecha" className="mt-3">
                                <Form.Label>Fecha:</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="fecha"
                                    value={noticiaData.fecha}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="foto" className="mt-3">
                                <Form.Label>Foto:</Form.Label>
                                <Form.Control
                                    type="file"
                                    name="foto"
                                    onChange={handleChange}
                                />
                                {isEdit && (
                                    <Button variant="info" onClick={togglePreview} className="mt-2">
                                        {showPreview ? 'Ocultar Fotos' : 'Preview Fotos'}
                                    </Button>
                                )}
                            </Form.Group>
                        </div>
                        {showPreview && (
                            <div style={{ flex: 1, textAlign: 'center' }}>
                                {fotoPreview && typeof fotoPreview === 'string' && (
                                    <div>
                                        <Image 
                                            src={fotoPreview} 
                                            alt="Foto previa" 
                                            style={{ maxWidth: '100%', maxHeight: '100%', border: '1px solid #ccc', padding: '10px' }}
                                        />
                                        <div>Foto Anterior</div>
                                    </div>
                                )}
                                {newPhotoPreview && typeof newPhotoPreview === 'string' && (
                                    <div>
                                        <Image 
                                            src={newPhotoPreview} 
                                            alt="Foto nueva" 
                                            style={{ maxWidth: '100%', maxHeight: '100%', border: '1px solid #ccc', padding: '10px' }}
                                        />
                                        <div>Foto Nueva</div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="button-group">
                        <button onClick={handleClose} className="button btn-cancel me-5">
                            Cancelar
                        </button>
                        <button type="submit" className="button btn-accent">
                            <i className="bi bi-save"></i>  Guardar
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default NoticiaForm;
