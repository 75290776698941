import createAxiosInstance from "../util/axiosInstance";

const api = createAxiosInstance('/grados');

  // Definir las funciones de API usando la instancia de Axios configurada
  
const fetchGrados = () => {
    return api.get('/');
};

const fetchGrado = (id) => {
    //console.log("En service...",id)
    return api.get(`/${id}`);
};

const createGrado = (data) => {
    return api.post('/', data);
};

const updateGrado = (id, data) => {
    return api.put(`/${id}`, data);
};

const deleteGrado = (id) => {
    return api.delete(`/${id}`);
};

export default {
    fetchGrados,
    createGrado,
    updateGrado,
    deleteGrado,
    fetchGrado
};
