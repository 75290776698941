import {
    FETCH_TITULOS_REQUEST,
    FETCH_TITULOS_SUCCESS,
    FETCH_TITULOS_FAILURE,
    FETCH_TITULO_REQUEST,
    FETCH_TITULO_SUCCESS,
    FETCH_TITULO_FAILURE,
    CREATE_TITULO_REQUEST,
    CREATE_TITULO_SUCCESS,
    CREATE_TITULO_FAILURE,
    UPDATE_TITULO_REQUEST,
    UPDATE_TITULO_SUCCESS,
    UPDATE_TITULO_FAILURE,
    DELETE_TITULO_REQUEST,
    DELETE_TITULO_SUCCESS,
    DELETE_TITULO_FAILURE
} from '../actions/titulosActions';

const initialState = {
    titulos: [],
    currentTitulo: null,
    loading: false,
    error: null
};

function titulosReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_TITULOS_REQUEST:
        case FETCH_TITULO_REQUEST:
        case CREATE_TITULO_REQUEST:
        case UPDATE_TITULO_REQUEST:
        case DELETE_TITULO_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_TITULOS_SUCCESS:
            return {
                ...state,
                titulos: action.payload,
                loading: false,
                error: null
            };
        case FETCH_TITULO_SUCCESS:
            return {
                ...state,
                currentTitulo: action.payload,
                loading: false,
                error: null
            };
        case CREATE_TITULO_SUCCESS:
            return {
                ...state,
                titulos: [...state.titulos, action.payload],
                loading: false,
                error: null
            };
        case UPDATE_TITULO_SUCCESS:
            return {
                ...state,
                titulos: state.titulos.map(titulo => titulo.id === action.payload.id ? action.payload : titulo),
                loading: false,
                error: null
            };
        case DELETE_TITULO_SUCCESS:
            return {
                ...state,
                titulos: state.titulos.filter(titulo => titulo.id !== action.payload),
                loading: false,
                error: null
            };
        case FETCH_TITULOS_FAILURE:
        case FETCH_TITULO_FAILURE:
        case CREATE_TITULO_FAILURE:
        case UPDATE_TITULO_FAILURE:
        case DELETE_TITULO_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}

export default titulosReducer;
