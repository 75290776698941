import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchNoticias, deleteNoticia } from '../actions/noticiaActions';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import NoticiaForm from './NoticiaForm';
import { toast } from 'react-toastify';
import { Table, Button } from 'react-bootstrap';
import CustomPrintModal from './CustomPrintModal';
import { formatDate, formatDateShowed } from '../util/utilFunctions';

const NoticiaList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { noticias, error, success } = useSelector(state => state.noticia || {});
    const [showForm, setShowForm] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [showPrintModal, setShowPrintModal] = useState(false);
    const [content, setContent] = useState(null);

    useEffect(() => {
        dispatch(fetchNoticias());
    }, [dispatch]);

    const handleDelete = (id) => {
        confirmAlert({
            title: 'Confirmar eliminación',
            message: '¿Estás seguro de que deseas eliminar esta noticia?',
            buttons: [
                {
                    label: 'Sí',
                    onClick: async () => {
                        try {
                            const response = await dispatch(deleteNoticia(id));
                            if (success && !error) {
                                toast.success('Noticia eliminada de la base de datos.');
                                dispatch(fetchNoticias());
                            } else {
                                toast.error('Error al eliminar la noticia de la base de datos... tiene dependencias...');
                            }
                        } catch (error) {
                            toast.error('Error al eliminar la noticia de la base de datos... tiene dependencias');
                        }
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    };

    const handleShowForm = (id = null) => {
        setSelectedId(id);
        setIsEdit(!!id);
        setShowForm(true);
    };

    const handleCloseForm = () => {
        setShowForm(false);
        setSelectedId(null);
        setIsEdit(false);
    };

    useEffect(() => {
        if (error) {
            toast.error(`Error al cargar los datos: ${error}`);
        }
    }, [error]);

    const handlePrintModalShow = () => {
        const content = {
            head: [["N°", "Título", "Fecha"]],
            body: Array.isArray(noticias) ? noticias.map((noticia, index) => [
                index+1,
                noticia.titulo,
                formatDateShowed(noticia.fecha)
            ]) : []
        };
        setContent(content);
        setShowPrintModal(true);
    };

    const handlePrintModalClose = () => {
        setShowPrintModal(false);
    };

    return (
        <div className="list1-container container-list">
            <h2>Noticias</h2>
            <div className="mt-3">
                <button onClick={() => handleShowForm()} className="button btn-accent float-end">
                    <i className="bi bi-plus-lg"></i>
                    Nueva Noticia
                </button>
            </div>
            <div className='table-container'>
                <Table hover striped border={1}>
                    <thead>
                        <tr>
                            <th>N°</th>
                            <th>Título</th>
                            <th>Fecha</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(noticias) && noticias.length > 0 ? noticias.map((noticia, index) => (
                            <tr key={noticia.id}>
                                <td>{index+1}</td>
                                <td>{noticia.titulo}</td>
                                <td>{formatDateShowed(noticia.fecha)}</td>
                                <td>
                                    <button onClick={() => handleShowForm(noticia.id)} className="button btn-accent-outline"><i className="bi bi-pencil"></i></button>
                                    <button onClick={() => handleDelete(noticia.id)} className="button btn-accent-outline"><i className="bi bi-trash"></i></button>
                                </td>
                            </tr>
                        )) : (
                            <tr>
                                <td colSpan="4" className="text-center">No hay noticias disponibles</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
            <div className="button-group">
                <button onClick={() => navigate("/")} className="button btn-cancel" style={{ marginRight: '10px' }}>
                    <i className="bi bi-arrow-left"></i> Volver
                </button>
                <button onClick={handlePrintModalShow} className="button btn-accent">
                    <i className="bi bi-printer"></i> Imprimir
                </button>
            </div>
            {showForm && (
                <NoticiaForm
                    show={showForm}
                    handleClose={handleCloseForm}
                    isEdit={isEdit}
                    selectedId={selectedId}
                />
            )}
            <CustomPrintModal show={showPrintModal} handleClose={handlePrintModalClose} title="Listado de Noticias" content={content} />
        </div>
    );
};

export default NoticiaList;
