import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

const CustomPrintModal = ({ show, handleClose, title, content }) => {
    const [pdfUrl, setPdfUrl] = useState(null);

    useEffect(() => {
        if (show) {
            generatePdf();
        }
    }, [show, content]);

    const generatePdf = () => {
        const doc = new jsPDF('p', 'pt', 'letter'); // Establecer tamaño de hoja a carta

        const marginLeftRight = 55; // Márgenes izquierdo y derecho
        const marginTop = 55; // Margen superior
        const marginBottom = 75; // Ajustar el margen inferior para bajar el pie de página
        const headerHeight = 100; // Altura de la cabecera

        const addHeader = (doc, pageNumber) => {
            doc.addImage('/assets/cabeceraciab.png', 'PNG', marginLeftRight, marginTop, doc.internal.pageSize.getWidth() - (marginLeftRight * 2), headerHeight);
            doc.setFontSize(12);
            doc.text(title, doc.internal.pageSize.getWidth() / 2, marginTop + headerHeight + 20, { align: 'center' });
            doc.setFontSize(10);
            if(pageNumber>0){
            doc.text(`Página ${pageNumber}`, doc.internal.pageSize.getWidth() - marginLeftRight, marginTop + headerHeight + 20, { align: 'right' });
            }
        };

        const addFooter = (doc) => {
            const pageHeight = doc.internal.pageSize.getHeight();
            const pageWidth = doc.internal.pageSize.getWidth();

            doc.setDrawColor(0, 50, 0); // Verde más oscuro
            doc.setLineWidth(1);
            doc.line(marginLeftRight, pageHeight - marginBottom - 10, pageWidth - marginLeftRight, pageHeight - marginBottom - 10);

            doc.setFontSize(12);
            doc.setTextColor(0, 50, 0); // Verde más oscuro
            doc.setFont("times", "normal");
            doc.text('Telf. Cel. 77171320; e-mail: ciabtarija@gmail.com', pageWidth / 2, pageHeight - marginBottom + 20, { align: 'center' });
            doc.text('Tarija - Bolivia', pageWidth / 2, pageHeight - marginBottom + 30, { align: 'center' });
            doc.setFontSize(10);
            doc.text(`Impreso el: ${new Date().toLocaleString()}`, pageWidth - marginLeftRight, pageHeight - marginBottom + 40, { align: 'right' });
        };

        // Inicializa el número de página
        let pageNumber = 0;

        if (typeof content === 'string') {
            addHeader(doc, pageNumber);
            doc.text(content, marginLeftRight, marginTop + headerHeight + 40);
            addFooter(doc);
        } else {
            addHeader(doc, pageNumber);
            autoTable(doc, {
                head: content.head,
                body: content.body,
                startY: marginTop + headerHeight + 40,
                margin: { top: marginTop + headerHeight + 40, left: marginLeftRight, right: marginLeftRight, bottom: marginBottom }, // Ajustar márgenes para incluir cabecera y pie de página
                didDrawPage: function (data) {
                    pageNumber++;
                    addHeader(doc, pageNumber);
                    addFooter(doc);
                },
                pageBreak: 'auto', // Asegurar que el contenido se mueva a una nueva página si es necesario
            });
        }

        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfUrl(pdfUrl);
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {pdfUrl && (
                    <iframe src={pdfUrl} width="100%" height="500px" title="Vista Previa del PDF"></iframe>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CustomPrintModal;

