import createAxiosInstance from "../util/axiosInstance";

const api = createAxiosInstance('/ciudad');

// Función para obtener todas las ciudades
const fetchCiudades = () => {
    return api.get('/');
};

const fetchCiudad = (id) => {
    return api.get(`/${id}`);
};

// Función para crear una nueva ciudad
const createCiudad = (data) => {
    return api.post('/', data);
};

// Función para actualizar una ciudad existente
const updateCiudad = (id, data) => {
    return api.put(`/${id}`, data);
};

// Función para eliminar una ciudad
const deleteCiudad = (id) => {
    return api.delete(`/${id}`);
};



export default {
    fetchCiudades,
    createCiudad,
    updateCiudad,
    deleteCiudad,
    fetchCiudad
};