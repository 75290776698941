import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchCiudades } from "../actions/ciudadActions";
import PrintPreviewModal from './PrintPreviewModal';

const SocioFicha = ({
  showModalFicha,
  closeModalFicha,
  sociof,
  fotoUrlFicha,
}) => {
  const dispatch = useDispatch();
  const ciudades = useSelector((state) => state.ciudad.ciudades);

  useEffect(() => {
    dispatch(fetchCiudades());
  }, [dispatch]);

  const [vistaPreviaFoto, setVistaPreviaFoto] = useState(fotoUrlFicha);
  const [showPrintPreview, setShowPrintPreview] = useState(false);

  useEffect(() => {
    setVistaPreviaFoto(fotoUrlFicha);
  }, [fotoUrlFicha]);

  const obtenerNombreCiudad = (codciu) => {
    const ciudad = ciudades.find((ciudad) => ciudad.id === codciu);
    return ciudad ? ciudad.nombre : "N/A";
  };

  const obtenerGenero = (genero) => {
    switch (genero) {
      case "M":
        return "Masculino";
      case "F":
        return "Femenino";
      default:
        return "Otros";
    }
  };

  const handlePrint = () => {
    setShowPrintPreview(true);
  };

  const handleClosePrintPreview = () => {
    setShowPrintPreview(false);
  };

  return (
    <>
      <Modal
        show={showModalFicha}
        onHide={() => closeModalFicha(false)}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <img
              src="/assets/ciab1.png"
              alt="Logotipo"
              style={{ width: '50px', height: '50px', marginRight: '10px' }}
            />
            FICHA DE REGISTRO DEL SOCIO
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row" style={{ fontSize: "0.9rem" }}>
            <img
              key={vistaPreviaFoto}
              src={vistaPreviaFoto}
              style={{ width: "11rem", height: "11rem" }}
              alt="Foto del Socio"
            />
            <div className="col-auto" style={{ borderRight: "1px solid black" }}>
              <p><strong>NROREG:</strong></p>
              <p><strong>FECHA DE REGISTRO:</strong></p>
              <p><strong>APELLIDO PATERNO:</strong></p>
              <p><strong>APELLIDO MATERNO:</strong></p>
              <p><strong>NOMBRES:</strong></p>
            </div>
            <div className="col-auto">
              <p>{sociof.codreg}</p>
              <p>{new Date(sociof.fereg).toLocaleDateString()}</p>
              <p>{sociof.apPat}</p>
              <p>{sociof.apMat}</p>
              <p>{sociof.nombres}</p>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-auto" style={{ borderRight: "1px solid black" }}>
              <p><strong>CÉDULA DE IDENTIDAD:</strong></p>
              <p><strong>FECHA DE NACIMIENTO:</strong></p>
              <p><strong>GÉNERO:</strong></p>
              <p><strong>CIUDAD:</strong></p>
            </div>
            <div className="col-auto">
              <p>{sociof.ci}</p>
              <p>{new Date(sociof.fenac).toLocaleDateString()}</p>
              <p>{obtenerGenero(sociof.genero)}</p>
              <p>{obtenerNombreCiudad(sociof.codciu)}</p>
            </div>
            <div className="col-auto" style={{ borderRight: "1px solid black" }}>
              <p><strong>CELULAR:</strong></p>
              <p><strong>EMAIL:</strong></p>
              <p><strong>ESTADO:</strong></p>
            </div>
            <div className="col-auto">
              <p>{sociof.celular}</p>
              <p>{sociof.email}</p>
              <p>{sociof.estado === "1" ? "Activo" : "Inactivo"}</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="button-group">
          <button className="button btn-cancel" onClick={() => closeModalFicha(false)}> <i className="bi bi-arrow-left"></i>  Volver</button>
          <button className="button btn-accent" onClick={handlePrint}><i className="bi bi-printer"></i>  Imprimir</button>
        </Modal.Footer>
      </Modal>
      <PrintPreviewModal
        show={showPrintPreview}
        handleClose={handleClosePrintPreview}
        socio={sociof}
        vistaPreviaFoto={vistaPreviaFoto}
      />
    </>
  );
};

export default SocioFicha;




