import React, { useState, useEffect, useMemo } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRoles } from '../actions/rolActions';
import { fetchUserRoles, deleteUserRoles, createUserRole, updateUserRole } from '../actions/usuarolActions';
import { toast } from 'react-toastify';
import { FaExclamationTriangle } from 'react-icons/fa';

const UsuarioRol = ({ show, handleClose, selectedId, onUsuarioUpdated }) => {
  const dispatch = useDispatch();
  const { roles, error: rolesError } = useSelector(state => state.rol);
  const { usuarios, error: usuariosError } = useSelector(state => state.usuario);
  const { userRoles:usuarioRoles, error: usuarioRolesError } = useSelector(state => state.usuarol);

  const usuario = useMemo(() => (usuarios ? usuarios.find(u => u.id === selectedId) : {}), [usuarios, selectedId]);
  const usuarioRol = useMemo(() => (usuarioRoles ? usuarioRoles.find(ur => ur.idusu === selectedId) : {}), [usuarioRoles, selectedId]);

  const [usuarioData, setUsuarioData] = useState({ idusu: selectedId, idrol: '' });

  useEffect(() => {
    if (selectedId) {
      dispatch(fetchRoles());
      dispatch(fetchUserRoles(selectedId));
    }
  }, [dispatch, selectedId]);

  useEffect(() => {
    ////console.log("En usuarioRol a ver que tiene usuarioROl:",usuarioRol," usuarioRoles=",usuarioRoles)
    if (usuarioRol) {
      setUsuarioData({ idusu: selectedId, idrol: usuarioRol.idrol });
    }
  }, [usuarioRol, selectedId]);

  const handleChange = (event) => {
    setUsuarioData({ ...usuarioData, [event.target.name]: event.target.value });
  };

  const handleSave = async () => {
    const datos = { idusu:selectedId, idrol: usuarioData.idrol }
    //console.log("Enviando", datos, usuarioRol)
    try {
      dispatch(updateUserRole(datos)).then(response => {
        //console.log("dataa", response.data,response.status)
        if(response.status === 202 && response.data.message){
          toast.info(response.data.message)
        }else if(response.status === 200)
        {toast.success('Rol asignado correctamente');}
        handleClose();
      });
    } catch (error) {
      toast.error(`Error al asignar el rol`);
    }
  };

  const currentRole = useMemo(() => {
    if (usuarioRol && roles) {
      //console.log(usuarioRol,roles)
      const role = roles.find(r => r.id === usuarioRol.idrol);
      return role ? role.nombre : 'N/A';
    }
    return 'N/A';
  }, [roles, usuarioRol]);

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Asignar Rol</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <FaExclamationTriangle style={{ color: 'red', marginRight: '5px', fontSize: '3em' }} />
          <span style={{ fontSize: '1.5em', color: 'darkgreen', fontWeight: 'bold', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)' }}>Alerta:</span>
        </div>
        <p style={{ marginBottom: '20px' }}>
          Asignar un nuevo rol a un usuario puede cambiar los permisos y accesos del usuario dentro del sistema.
        </p>
        {rolesError ? (
          <div>Error: {rolesError}</div>
        ) : usuariosError ? (
          <div>Error: {usuariosError}</div>
        ) : usuarioRolesError ? (
          <div>Error: {usuarioRolesError}</div>
        ) : (
          <Form>
            <Row className="mb-3">
            
              <Col xs={6}>
                <strong>Usuario:</strong> <span className="data-large">{usuario?.usuario}</span>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12}>
                <strong>Rol actual:</strong> <span className="data-large">{currentRole}</span>
              </Col>
            </Row>
            <Form.Group controlId="formRol" className="mb-3">
              <Form.Label>Rol del Usuario:</Form.Label>
              <Form.Select
                name="idrol"
                value={usuarioData.idrol}
                onChange={handleChange}
                required
              >
                <option value="">Seleccione un Rol</option>
                {roles && roles.length > 0 ? (
                  roles.map((rol) => (
                    <option key={rol.id} value={rol.id}>
                      {rol.nombre}
                    </option>
                  ))
                ) : (
                  <option value="" disabled>Cargando roles...</option>
                )}
              </Form.Select>
            </Form.Group>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer className='button-group'>
        <button className="button btn-cancel" onClick={handleClose}>
          Cancelar
        </button>
        <button className="button btn-accent" onClick={handleSave}>
          Grabar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default UsuarioRol;
