import React, { useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PrintPreviewModal = ({ show, handleClose, socio, vistaPreviaFoto }) => {

  useEffect(() => {
    if (show && socio) {
      generatePDF();
    }
  }, [show, socio]);

  const loadImage = async (url) => {
    const response = await fetch(url);
    const arrayBuffer = await response.arrayBuffer();
    return arrayBuffer;
  };

  const generatePDF = async () => {
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([612, 792]); // Tamaño carta

    const logoBytes = await loadImage(process.env.PUBLIC_URL + '/assets/ciab1.png');
    const logoImage = await pdfDoc.embedPng(logoBytes);

    let photoImage;
    if (vistaPreviaFoto) {
      const photoBytes = await loadImage(vistaPreviaFoto);
      try {
        photoImage = await pdfDoc.embedPng(photoBytes);
      } catch (e) {
        try {
          photoImage = await pdfDoc.embedJpg(photoBytes);
        } catch (e) {
          toast.error("Error al cargar la imagen del socio.");
          handleClose();
          return;
        }
      }
    }

    // Ajustar el tamaño de las imágenes
    const logoScale = 0.05; // Escala del logo para hacerlo 10 veces más pequeño
    const logoDims = logoImage.scale(logoScale);
    const photoScale = 0.8; // Escala de la foto para hacerla 10 veces más grande
    const photoDims = photoImage ? { width: photoImage.width * photoScale, height: photoImage.height * photoScale } : null;

    // Posicionar y dibujar el logo
    page.drawImage(logoImage, {
      x: 40,
      y: page.getHeight() - logoDims.height - 40,
      width: logoDims.width,
      height: logoDims.height,
    });

    // Título centrado
    const fontBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
    page.drawText('FICHA DE REGISTRO DEL SOCIO', {
      x: (page.getWidth() / 2) - 100,
      y: page.getHeight() - 50,
      size: 16,
      font: fontBold,
      color: rgb(0, 0, 0),
    });

    // Posicionar y dibujar la foto del socio
    if (photoImage) {
      page.drawImage(photoImage, {
        x: 40,
        y: page.getHeight() - logoDims.height - photoDims.height - 80,
        width: photoDims.width,
        height: photoDims.height,
      });
    }

    // Incrustar una fuente estándar
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);

    // Texto del cuerpo
    const texts = [
      { label: "CODREG:", value: socio.codreg },
      { label: "FECHA DE REGISTRO:", value: new Date(socio.fereg).toLocaleDateString() },
      { label: "APELLIDO PATERNO:", value: socio.apPat },
      { label: "APELLIDO MATERNO:", value: socio.apMat },
      { label: "NOMBRES:", value: socio.nombres },
      { label: "CÉDULA DE IDENTIDAD:", value: socio.ci },
      { label: "FECHA DE NACIMIENTO:", value: new Date(socio.fenac).toLocaleDateString() },
      { label: "GÉNERO:", value: socio.genero === "M" ? "Masculino" : socio.genero === "F" ? "Femenino" : "Otros" },
      { label: "CIUDAD:", value: socio.ciudad },
      { label: "CELULAR:", value: socio.celular },
      { label: "EMAIL:", value: socio.email },
      { label: "ESTADO:", value: socio.estado === "1" ? "Activo" : "Inactivo" },
    ];

    const startX = 40;
    let y = page.getHeight() - logoDims.height - (photoDims ? photoDims.height : 0) - 120;

    texts.forEach((text, index) => {
      if (index === 5 || index === 10) {
        y -= 20; // Añadir espacio extra entre secciones
      }
      page.drawText(`${text.label}`, {
        x: startX,
        y,
        size: 12,
        font,
        color: rgb(0, 0, 0),
      });
      page.drawText(`${text.value}`, {
        x: startX + 150,
        y,
        size: 12,
        font,
        color: rgb(0, 0, 0),
      });
      y -= 20;
    });

    const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
    const iframe = `<iframe width='100%' height='600px' src='${pdfDataUri}'></iframe>`;
    document.getElementById('pdf-preview').innerHTML = iframe;
  };

  const printDocument = async () => {
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([612, 792]); // Tamaño carta

    const logoBytes = await loadImage(process.env.PUBLIC_URL + '/assets/ciab1.png');
    const logoImage = await pdfDoc.embedPng(logoBytes);

    let photoImage;
    if (vistaPreviaFoto) {
      const photoBytes = await loadImage(vistaPreviaFoto);
      try {
        photoImage = await pdfDoc.embedPng(photoBytes);
      } catch (e) {
        try {
          photoImage = await pdfDoc.embedJpg(photoBytes);
        } catch (e) {
          toast.error("Error al cargar la imagen del socio.");
          handleClose();
          return;
        }
      }
    }

    // Ajustar el tamaño de las imágenes
    const logoScale = 0.1; // Escala del logo para hacerlo 10 veces más pequeño
    const logoDims = logoImage.scale(logoScale);
    const photoScale = 10; // Escala de la foto para hacerla 10 veces más grande
    const photoDims = photoImage ? { width: photoImage.width * photoScale, height: photoImage.height * photoScale } : null;

    // Posicionar y dibujar el logo
    page.drawImage(logoImage, {
      x: 40,
      y: page.getHeight() - logoDims.height - 40,
      width: logoDims.width,
      height: logoDims.height,
    });

    // Título centrado
    const fontBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
    page.drawText('FICHA DE REGISTRO DEL SOCIO', {
      x: (page.getWidth() / 2) - 100,
      y: page.getHeight() - 50,
      size: 16,
      font: fontBold,
      color: rgb(0, 0, 0),
    });

    // Posicionar y dibujar la foto del socio
    if (photoImage) {
      page.drawImage(photoImage, {
        x: 40,
        y: page.getHeight() - logoDims.height - photoDims.height - 80,
        width: photoDims.width,
        height: photoDims.height,
      });
    }

    // Incrustar una fuente estándar
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);

    // Texto del cuerpo
    const texts = [
      { label: "CODREG:", value: socio.codreg },
      { label: "FECHA DE REGISTRO:", value: new Date(socio.fereg).toLocaleDateString() },
      { label: "APELLIDO PATERNO:", value: socio.apPat },
      { label: "APELLIDO MATERNO:", value: socio.apMat },
      { label: "NOMBRES:", value: socio.nombres },
      { label: "CÉDULA DE IDENTIDAD:", value: socio.ci },
      { label: "FECHA DE NACIMIENTO:", value: new Date(socio.fenac).toLocaleDateString() },
      { label: "GÉNERO:", value: socio.genero === "M" ? "Masculino" : socio.genero === "F" ? "Femenino" : "Otros" },
      { label: "CIUDAD:", value: socio.ciudad },
      { label: "CELULAR:", value: socio.celular },
      { label: "EMAIL:", value: socio.email },
      { label: "ESTADO:", value: socio.estado === "1" ? "Activo" : "Inactivo" },
    ];

    const startX = 40;
    let y = page.getHeight() - logoDims.height - (photoDims ? photoDims.height : 0) - 120;

    texts.forEach((text, index) => {
      if (index === 5 || index === 10) {
        y -= 20; // Añadir espacio extra entre secciones
      }
      page.drawText(`${text.label}`, {
        x: startX,
        y,
        size: 12,
        font,
        color: rgb(0, 0, 0),
      });
      page.drawText(`${text.value}`, {
        x: startX + 150,
        y,
        size: 12,
        font,
        color: rgb(0, 0, 0),
      });
      y -= 20;
    });

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'ficha_socio.pdf';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Vista Previa de Impresión</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="pdf-preview"></div>
      </Modal.Body>
      <Modal.Footer>
        <button className="button btn-cancel" onClick={handleClose}>
          Cerrar
        </button>
        <button className="button btn-accent" onClick={printDocument}>
          Imprimir
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default PrintPreviewModal;

