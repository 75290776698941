import ciudadService from '../services/ciudadService'; // Asegúrate de tener este archivo y que está correctamente configurado
import axios from 'axios';

export const FETCH_CIUDADES_REQUEST = 'FETCH_CIUDADES_REQUEST';
export const FETCH_CIUDADES_SUCCESS = 'FETCH_CIUDADES_SUCCESS';
export const FETCH_CIUDADES_FAILURE = 'FETCH_CIUDADES_FAILURE';

export const FETCH_CIUDAD_REQUEST = 'FETCH_CIUDAD_REQUEST';
export const FETCH_CIUDAD_SUCCESS = 'FETCH_CIUDAD_SUCCESS';
export const FETCH_CIUDAD_FAILURE = 'FETCH_CIUDAD_FAILURE';

export const CREATE_CIUDAD_REQUEST = 'CREATE_CIUDAD_REQUEST';
export const CREATE_CIUDAD_SUCCESS = 'CREATE_CIUDAD_SUCCESS';
export const CREATE_CIUDAD_FAILURE = 'CREATE_CIUDAD_FAILURE';

export const UPDATE_CIUDAD_REQUEST = 'UPDATE_CIUDAD_REQUEST';
export const UPDATE_CIUDAD_SUCCESS = 'UPDATE_CIUDAD_SUCCESS';
export const UPDATE_CIUDAD_FAILURE = 'UPDATE_CIUDAD_FAILURE';

export const DELETE_CIUDAD_REQUEST = 'DELETE_CIUDAD_REQUEST';
export const DELETE_CIUDAD_SUCCESS = 'DELETE_CIUDAD_SUCCESS';
export const DELETE_CIUDAD_FAILURE = 'DELETE_CIUDAD_FAILURE';

// Acción para obtener todas las ciudades
export const fetchCiudades = () => async (dispatch) => {
  dispatch({ type: FETCH_CIUDADES_REQUEST });
  try {
    const response = await ciudadService.fetchCiudades();
    dispatch({ type: FETCH_CIUDADES_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_CIUDADES_FAILURE, payload: error.message });
  }
};

// Acción para obtener una ciudad específica por ID
export const fetchCiudad = (id) => async (dispatch) => {
    dispatch({ type: FETCH_CIUDAD_REQUEST });
    try {
      const response = await ciudadService.fetchCiudad(id); // Ajusta la URL según tu configuración
      //console.log('AQUI ESTOY fetching city:',response.data);  
      dispatch({ type: FETCH_CIUDAD_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      dispatch({ type: FETCH_CIUDAD_FAILURE, payload: error.message });
    }
  };

// Acción para crear una nueva ciudad
export const createCiudad = (ciudadData) => async (dispatch) => {
    dispatch({ type: CREATE_CIUDAD_REQUEST });
    try {
      const response = await ciudadService.createCiudad(ciudadData);
      dispatch({ type: CREATE_CIUDAD_SUCCESS, payload: response.data });
      return response.data;
    } catch (error) {
      dispatch({ type: CREATE_CIUDAD_FAILURE, payload: error.message });
      return Promise.reject(error);
    }
  };

// Acción para actualizar una ciudad existente
export const updateCiudad = (id, ciudadData) => async (dispatch) => {
    dispatch({ type: UPDATE_CIUDAD_REQUEST });
    try {
      const response = await ciudadService.updateCiudad(id, ciudadData);
      dispatch({ type: UPDATE_CIUDAD_SUCCESS, payload: response.data });
      return response.data;
    } catch (error) {
      dispatch({ type: UPDATE_CIUDAD_FAILURE, payload: error.message });
      return Promise.reject(error);
    }
  };

/*// Acción para eliminar una ciudad
export const deleteCiudad = (id) => async (dispatch) => {
    dispatch({ type: DELETE_CIUDAD_REQUEST });
    try {
      await ciudadService.deleteCiudad(id);
      dispatch({ type: DELETE_CIUDAD_SUCCESS, payload: id });
    } catch (error) {
      dispatch({ type: DELETE_CIUDAD_FAILURE, payload: error.message });
      return Promise.reject(error);
    }
  };*/

  // Acción para eliminar una ciudad
export const deleteCiudad = (id) => async (dispatch) => {
    dispatch({ type: DELETE_CIUDAD_REQUEST });
    try {
      const response = await ciudadService.deleteCiudad(id);
      if (response.status === 200) {  // Make sure the status is checked if your API responds with it
        dispatch({ type: DELETE_CIUDAD_SUCCESS, payload: id });
        dispatch(fetchCiudades());  // Re-fetch the list of cities to update the state
      } else {
        throw new Error('No se pudo eliminar..');
      }
    } catch (error) {
      dispatch({ type: DELETE_CIUDAD_FAILURE, payload: error.message });
      return Promise.reject(error);
    }
};

