// src/reducers/directivaReducer.js
import {
    FETCH_DIRECTIVAS_REQUEST,
    FETCH_DIRECTIVAS_SUCCESS,
    FETCH_DIRECTIVAS_FAILURE,
    FETCH_DIRECTIVA_REQUEST,
    FETCH_DIRECTIVA_SUCCESS,
    FETCH_DIRECTIVA_FAILURE,
    CREATE_DIRECTIVA_REQUEST,
    CREATE_DIRECTIVA_SUCCESS,
    CREATE_DIRECTIVA_FAILURE,
    UPDATE_DIRECTIVA_REQUEST,
    UPDATE_DIRECTIVA_SUCCESS,
    UPDATE_DIRECTIVA_FAILURE,
    DELETE_DIRECTIVA_REQUEST,
    DELETE_DIRECTIVA_SUCCESS,
    DELETE_DIRECTIVA_FAILURE
} from '../actions/directivaActions';

const initialState = {
    directivas: [],
    currentDirectiva: null,
    loading: false,
    error: null
};

const directivaReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DIRECTIVAS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null // Resetear error al iniciar una nueva solicitud
            };
        case DELETE_DIRECTIVA_REQUEST:
            return {
                ...state,
                loading: true,
                error: null // Resetear error al iniciar una nueva solicitud
            };
        case FETCH_DIRECTIVAS_SUCCESS:
            return {
                ...state,
                loading: false,
                directivas: action.payload
            };
        case DELETE_DIRECTIVA_SUCCESS:
            return {
                ...state,
                loading: false,
                directivas: state.directivas.filter(directiva => directiva.id !== action.payload)
            };
        case FETCH_DIRECTIVAS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isLoadError: true // Añadimos este campo para rastrear el origen del error
            };
        case DELETE_DIRECTIVA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isLoadError: false // Añadimos este campo para rastrear el origen del error
            };
        case FETCH_DIRECTIVA_SUCCESS:
            return {
                ...state,
                loading: false,
                currentDirectiva: action.payload,
            };
        default:
            return state;
    }
};

export default directivaReducer;
