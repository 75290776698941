import direcsocService from '../services/direcsocService';

export const FETCH_DIRECSOC_REQUEST = 'FETCH_DIRECSOC_REQUEST';
export const FETCH_DIRECSOC_SUCCESS = 'FETCH_DIRECSOC_SUCCESS';
export const FETCH_DIRECSOC_FAILURE = 'FETCH_DIRECSOC_FAILURE';
export const DELETE_DIRECSOC_REQUEST = 'DELETE_DIRECSOC_REQUEST';
export const DELETE_DIRECSOC_SUCCESS = 'DELETE_DIRECSOC_SUCCESS';
export const DELETE_DIRECSOC_FAILURE = 'DELETE_DIRECSOC_FAILURE';
export const SAVE_DIRECSOC_REQUEST = 'SAVE_DIRECSOC_REQUEST';
export const SAVE_DIRECSOC_SUCCESS = 'SAVE_DIRECSOC_SUCCESS';
export const SAVE_DIRECSOC_FAILURE = 'SAVE_DIRECSOC_FAILURE';
export const GET_ALL_DIRECSOC_REQUEST = 'GET_ALL_DIRECSOC_REQUEST';
export const GET_ALL_DIRECSOC_SUCCESS = 'GET_ALL_DIRECSOC_SUCCESS';
export const GET_ALL_DIRECSOC_FAILURE = 'GET_ALL_DIRECSOC_FAILURE';
export const GET_DIRECSOC_REQUEST = 'GET_DIRECSOC_REQUEST';
export const GET_DIRECSOC_SUCCESS = 'GET_DIRECSOC_SUCCESS';
export const GET_DIRECSOC_FAILURE = 'GET_DIRECSOC_FAILURE';
export const UPDATE_DIRECSOC_REQUEST = 'UPDATE_DIRECSOC_REQUEST';
export const UPDATE_DIRECSOC_SUCCESS = 'UPDATE_DIRECSOC_SUCCESS';
export const UPDATE_DIRECSOC_FAILURE = 'UPDATE_DIRECSOC_FAILURE';
export const CLEAR_DIRECSOC = 'CLEAR_DIRECSOC';
export const RESET_DIRECSOC = 'RESET_DIRECSOC';

export const fetchDirectivaSocios = (directivaId) => async (dispatch) => {
    dispatch({ type: FETCH_DIRECSOC_REQUEST });
    try {
        const response = await direcsocService.fetchDirectivaSocios(directivaId);
        dispatch({ type: FETCH_DIRECSOC_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: FETCH_DIRECSOC_FAILURE, payload: error.message });
    }
};

export const deleteDirectivaSocios = (directivaId) => async (dispatch) => {
    dispatch({ type: DELETE_DIRECSOC_REQUEST });
    try {
        await direcsocService.deleteDirectivaSocios(directivaId);
        dispatch({ type: DELETE_DIRECSOC_SUCCESS, payload: directivaId });
    } catch (error) {
        dispatch({ type: DELETE_DIRECSOC_FAILURE, payload: error.message });
    }
};

export const saveDirectivaSocios = (socioData) => async (dispatch) => {
    dispatch({ type: SAVE_DIRECSOC_REQUEST });
    try {
        await direcsocService.saveDirectivaSocios(socioData);
        dispatch({ type: SAVE_DIRECSOC_SUCCESS });
    } catch (error) {
        dispatch({ type: SAVE_DIRECSOC_FAILURE, payload: error.message });
        throw error;
    }
};

export const getAllDirecsoc = () => async (dispatch) => {
    dispatch({ type: GET_ALL_DIRECSOC_REQUEST });
    try {
        const response = await direcsocService.getAllDirecsoc();
        dispatch({ type: GET_ALL_DIRECSOC_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: GET_ALL_DIRECSOC_FAILURE, payload: error.message });
    }
};

export const getDirecsoc = (id) => async (dispatch) => {
    dispatch({ type: GET_DIRECSOC_REQUEST });
    try {
        const response = await direcsocService.getDirecsoc(id);
        dispatch({ type: GET_DIRECSOC_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: GET_DIRECSOC_FAILURE, payload: error.message });
    }
};

export const updateDirecsoc = (id, socioData) => async (dispatch) => {
    dispatch({ type: UPDATE_DIRECSOC_REQUEST });
    try {
        const response = await direcsocService.updateDirecsoc(id, socioData);
        dispatch({ type: UPDATE_DIRECSOC_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: UPDATE_DIRECSOC_FAILURE, payload: error.message });
    }
};

export const deleteDirecsoc = (id) => async (dispatch) => {
    dispatch({ type: DELETE_DIRECSOC_REQUEST });
    try {
        await direcsocService.deleteDirecsoc(id);
        dispatch({ type: DELETE_DIRECSOC_SUCCESS, payload: id });
    } catch (error) {
        dispatch({ type: DELETE_DIRECSOC_FAILURE, payload: error.message });
    }
};



export const clearDirectivaSocios = () => ({
    type: CLEAR_DIRECSOC
});


export const resetDirecsoc = () => ({
    type: RESET_DIRECSOC
});