import createAxiosInstance from "../util/axiosInstance";

const api = createAxiosInstance('/usuarol');

const fetchUserRoles = (userId) => {
   // //console.log("Entro a service fetchUserRoles con ",userId)
    return api.get(`/user/${userId}`);
};

const createUserRole = (data) => {
  //  //console.log("Entro a service cerate..",data)
    return api.post('/', data);
};

const updateUserRole = (data) => {
    //  //console.log("Entro a service cerate..",data)
      return api.put(`/`, data);
  };
  
const deleteUserRoles = (userId) => {
   // //console.log("Entro a service delete..",userId)
    return api.delete(`/user/${userId}`);
};

export default {
    fetchUserRoles,
    createUserRole,
    updateUserRole,
    deleteUserRoles
};
