// Función para formatear la fecha a dd/mm/yyyy
export const formatDateShowed = (date) => {
    const [year, month, day] = date.split('T')[0].split('-');
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
}
export const formatDate = (date) => {
    const formattedDate = date.split('T')[0];
    return formattedDate;
}
export const getCurrentDate = () => {
    const date = new Date().toISOString().split('T')[0];
    return date;
};
export const getUploadUrl= (image) => {
    if(image){
    const fileName = (image.split('uploads\\')[1] || image.split('uploads/')[1]) || image;
    return `${process.env.REACT_APP_UPLOADS_BASE_URL}/${fileName}`}

    return null

  }