import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createGrado, updateGrado, fetchGrado, fetchGrados } from '../actions/gradoActions';
//import './PaisForm.css';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';

const GradoForm = ({ isOpen, onRequestClose, gradoId }) => {
  const dispatch = useDispatch();
  const grado = useSelector(state => state.grado.grados.find(g => g.id === gradoId));
  const [gradoData, setGradoData] = useState({ nombre: '', refer: '' });

  useEffect(() => {
    if (gradoId && grado) {
      setGradoData({
        nombre: grado.nombre || '',
        refer: grado.refer || ''
      });
    } else {
      setGradoData({ nombre: '', refer: '' });
    }
  }, [gradoId, grado]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGradoData({ ...gradoData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (gradoId) {
      if (!gradoData.nombre || !gradoData.refer ) {
        toast.error(
          "Por favor complete los campos requeridos: nombre, referencia"
        );
        return;
      }
      dispatch(updateGrado(gradoId, gradoData))
        .then(() => {
          toast.success('Grado actualizado correctamente');
          dispatch(fetchGrados());
          onRequestClose();
        })
        .catch(error => {
          toast.error(`Error al actualizar el grado: ${error.message || error}`);
        });
    } else {
      if (!gradoData.nombre || !gradoData.refer ) {
        toast.error(
          "Por favor complete los campos requeridos: nombre, referencia"
        );
        return;
      }
      dispatch(createGrado(gradoData))
        .then(() => {
          toast.success('Grado creado correctamente');
          dispatch(fetchGrados());
          onRequestClose();
        })
        .catch(error => {
          toast.error(`Error al crear el grado: ${error.message || error}`);
        });
    }
  };

  return (
    <Modal show={isOpen} onHide={onRequestClose} backdrop="static" keyboard={false} size="md">
            <Modal.Header closeButton>
                <Modal.Title>{gradoId ? 'Editar grado' : 'Crear grado'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <label>Nombre del Grado</label>
          <input
            type="text"
            name="nombre"
            value={gradoData.nombre}
            onChange={handleChange}
          />
          <label>Referencia</label>
          <input
            type="text"
            name="refer"
            value={gradoData.refer}
            onChange={handleChange}
          />
          <div className='button-group'>
            <button type="button" className="button btn-cancel" onClick={onRequestClose}>Cancelar</button>
            <button type="submit" className="button btn-accent">Guardar</button>
          </div>
        </form>
        </Modal.Body>
    </Modal>
  );
};

export default GradoForm;
