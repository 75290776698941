import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCuotasBySocio, updateCuota } from '../actions/cuotaActions';
import { createRecibo } from '../actions/reciboActions';
import { Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import './CuotasList.css';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ReciboModal from './ReciboModal';
import CustomPrintModal from './CustomPrintModal'; // Importar el modal personalizado

const CuotasList = ({ showCuotasModal, closeCuotasModal, socio }) => {
    const dispatch = useDispatch();
    const { cuotas, loading, error } = useSelector(state => state.cuota);
    const [cuotasEditadas, setCuotasEditadas] = useState({});
    const [selectedCuotas, setSelectedCuotas] = useState([]);
    const [showReciboModal, setShowReciboModal] = useState(false);
    const [reciboData, setReciboData] = useState({
        socio: null,
        cuotas: [],
        reciboNumero: null,
        reciboFecha:new Date()
    });
    

    
    const [showPrintModal, setShowPrintModal] = useState(false); // Estado para mostrar el modal de impresión
    const [printTitle, setPrintTitle] = useState('');
    const [printContent, setPrintContent] = useState({ head: [], body: [] });

    useEffect(() => {
        if (socio && socio.id) {
            dispatch(fetchCuotasBySocio(socio.id));
        }
    }, [dispatch, socio]);

    useEffect(() => {
        if (cuotas && cuotas.length > 0) {
            const initialCuotasEditadas = {};
            cuotas.forEach(cuota => {
                if (cuota.estado === 0) {
                    initialCuotasEditadas[cuota.id] = {
                        ...cuota,
                        fepago: new Date(),
                        montopagado: cuota.monto
                    };
                }
            });
            setCuotasEditadas(initialCuotasEditadas);
        }
    }, [cuotas]);

    const handleAmountChange = (cuotaId, amount) => {
        const floatAmount = parseFloat(amount) || 0;
        setCuotasEditadas({
            ...cuotasEditadas,
            [cuotaId]: {
                ...cuotasEditadas[cuotaId],
                montopagado: floatAmount,
                fepago: cuotasEditadas[cuotaId]?.fepago || new Date()
            }
        });
    };

    const handleObservationChange = (cuotaId, observation) => {
        setCuotasEditadas({
            ...cuotasEditadas,
            [cuotaId]: {
                ...cuotasEditadas[cuotaId],
                observ: observation,
                fepago: cuotasEditadas[cuotaId]?.fepago || new Date()
            }
        });
    };

    const handleSelectCuota = (cuota) => {
        const isSelected = selectedCuotas.some(item => item.id === cuota.id);
        if (!isSelected) {
            const montoPagado = cuotasEditadas[cuota.id]?.montopagado !== undefined ? cuotasEditadas[cuota.id]?.montopagado : cuota.montopagado || cuota.monto;
            if (montoPagado <= 0) {
                toast.error('El monto pagado debe ser mayor que 0 para seleccionar esta cuota');
                return;
            }
            if (montoPagado < cuota.monto && !cuotasEditadas[cuota.id]?.observ) {
                toast.error('La observación es obligatoria si el monto pagado es menor que el monto');
                return;
            }
            setSelectedCuotas([...selectedCuotas, { ...cuota, fepago: new Date(), montopagado: montoPagado }]);
        } else {
            setSelectedCuotas(selectedCuotas.filter(item => item.id !== cuota.id));
        }
    };

    const handlePayment = async () => {
        try {
            const nuevoRecibo = {
                nroRecibo: 1,
                fecha:new Date(),
                idcodreg: socio.id
            };
            const reciboResponse = await dispatch(createRecibo(nuevoRecibo));
            const idRecibox = reciboResponse.id;

            await Promise.all(selectedCuotas.map(cuota => {
                const updatedCuota = {
                    ...cuota,
                    ...cuotasEditadas[cuota.id],
                    estado: 1,
                    idrecibo: idRecibox,
                    fepago: cuotasEditadas[cuota.id]?.fepago || new Date()
                };
                return dispatch(updateCuota(cuota.id, updatedCuota));
            }));
            toast.success('Cuotas pagadas correctamente');

            const formattedCuotas = selectedCuotas.map(cuota => ({
                ...cuota,
                ...cuotasEditadas[cuota.id], // Incluimos las ediciones
                fechaEmision: cuota.feemis,
                fechaPago: cuota.fepago || new Date()  
            }));

            setReciboData({
                socio,
                cuotas: formattedCuotas,
                reciboNumero: idRecibox,
                reciboFecha: nuevoRecibo.fecha
            });

            setShowReciboModal(true);
            dispatch(fetchCuotasBySocio(socio.id));
            setSelectedCuotas([]);
        } catch (error) {
            toast.error(`Error al pagar las cuotas, intente nuevamente: ${error.message}`);
        }
    };

    const handlePrintEstadoCuenta = () => {
        const content = {
            head: [["Gest.", "F.Emisión", "Monto", "F.Pago", "M.Pagado", "Observación", "Recibo"]],
            body: cuotas.map(cuota => [
                cuota.gestion,
                cuota.feemis ? new Date(cuota.feemis).toLocaleDateString() : '',
                cuota.monto !== null && cuota.monto !== undefined ? cuota.monto.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '',
                cuota.fepago ? new Date(cuota.fepago).toLocaleDateString() : '',
                cuota.montopagado !== null && cuota.montopagado !== undefined ? cuota.montopagado.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '',
                cuota.observ || '',
                cuota.idrecibo || ''
            ])
        };
        ////console.log('Socio=',socio)
        setPrintTitle('ESTADO DE CUENTAS  - ' + socio.apPat+' '+socio.apMat+' '+socio.nombres);
        setPrintContent(content);
        setShowPrintModal(true); 
    };
    
    if (loading) return <div>Cargando cuotas...</div>;
    if (error) return <div>Error al cargar las cuotas: {error}</div>;

    // Ordenar cuotas por fecha de emisión ascendente
    const sortedCuotas = [...cuotas].sort((a, b) => new Date(a.feemis) - new Date(b.feemis));
   // //console.log('reciboFecha:', reciboData.reciboFecha);
    return (
        <>
            <Modal show={showCuotasModal} onHide={() => closeCuotasModal(false)} backdrop="static" keyboard={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Cuotas del Socio</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="socio-info">
                        <div className='row'>
                        <div className='col-auto'>
                            <p><strong>CÓDIGO DE REGISTRO:</strong></p>
                            <p><strong>APELLIDO PATERNO:</strong> </p>
                            <p><strong>APELLIDO MATERNO:</strong></p>
                            <p><strong >NOMBRES:</strong> </p>
                            <p><strong >N° CÉDULA DE IDENTIDAD:</strong> </p>
                            </div>
                            <div className='col w-auto'>
                                <p> {socio.codreg}</p>
                                <p> {socio.apPat}</p>
                                <p> {socio.apMat}</p>
                                <p> {socio.nombres}</p>
                                <p> {socio.ci}</p>
                                </div>
                        </div>
                    </div>
                    <div className="cuotas-container" style={{ overflowX: 'auto' }}>
                        <div className="cuotas-list">
                            <table className="cuotas-table">
                                <thead>
                                    <tr>
                                        <th>Gestión</th>
                                        <th>F.Emisión</th>
                                        <th>Monto</th>
                                        <th>F.Pago</th>
                                        <th>M.Pagado</th>
                                        <th>Obser.</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sortedCuotas.map(cuota => {
                                        const cuotaEditada = cuotasEditadas[cuota.id] || {};
                                        const isSelected = selectedCuotas.some(item => item.id === cuota.id);
                                        return (
                                            <tr key={cuota.id}>
                                                <td>{cuota.gestion}</td>
                                                <td>{new Date(cuota.feemis).toLocaleDateString()}</td>
                                                <td>{cuota.monto}</td>
                                                <td>{(cuotaEditada.fepago || cuota.fepago) ? new Date(cuotaEditada.fepago || cuota.fepago).toLocaleDateString() : 'N/A'}</td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        value={cuotaEditada.montopagado !== undefined ? cuotaEditada.montopagado : cuota.montopagado || cuota.monto}
                                                        onChange={(e) => handleAmountChange(cuota.id, e.target.value)}
                                                        disabled={cuota.estado !== 0 || isSelected}
                                                    />
                                                </td>
                                                <td style={{ width: '300px' }}>
                                                    <input
                                                        type="text"
                                                        value={cuotaEditada.observ || cuota.observ || ''}
                                                        onChange={(e) => handleObservationChange(cuota.id, e.target.value)}
                                                        disabled={cuota.estado !== 0 || isSelected}
                                                    />
                                                </td>
                                                <td>
                                                    {cuota.estado === 0 && (
                                                        <input
                                                            type="checkbox"
                                                            checked={isSelected}
                                                            onChange={() => handleSelectCuota(cuota)}
                                                        />
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="summary-panel">
                            <h5><strong>Resumen</strong></h5>
                            <p><strong>Cuotas seleccionadas:</strong> {selectedCuotas.length}</p>
                            <p><strong>Monto total a pagar:</strong> {selectedCuotas.reduce((sum, item) => sum + item.montopagado, 0)}</p>
                            <button className="button btn-success" onClick={handlePayment} disabled={selectedCuotas.length === 0}>Confirmar Pago</button>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="button btn-cancel" onClick={() => closeCuotasModal(false)}> <i className="bi bi-arrow-left"></i>  Volver</button>
                    <button className="button btn-accent" onClick={handlePrintEstadoCuenta}>Imprimir Estado de Cuenta</button>
                </Modal.Footer>
            </Modal>
            
            <ReciboModal
                show={showReciboModal}
                handleClose={() => setShowReciboModal(false)}
                socio={reciboData.socio}
                cuotas={reciboData.cuotas}
                reciboNumero={reciboData.reciboNumero}
                reciboFecha={reciboData.reciboFecha}
                copia={true}
            />

            <CustomPrintModal
                show={showPrintModal}
                handleClose={() => setShowPrintModal(false)}
                title={printTitle}
                content={printContent}
            />
        </>
    );
};

export default CuotasList;
