import createAxiosInstance from "../util/axiosInstance";

const api = createAxiosInstance('/universidad');

// Function to fetch all universities
const fetchUniversidades = () => {
    return api.get('/');
};

// Function to fetch a single university by ID
const fetchUniversidad = (id) => {
    return api.get(`/${id}`);
};

// Function to create a new university
const createUniversidad = (data) => {
    return api.post('/', data);
};

// Function to update an existing university
const updateUniversidad = (id, data) => {
    return api.put(`/${id}`, data);
};

// Function to delete a university
const deleteUniversidad = (id) => {
    return api.delete(`/${id}`);
};

export default {
    fetchUniversidades,
    createUniversidad,
    updateUniversidad,
    deleteUniversidad,
    fetchUniversidad
};
