import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCuotasBySocio, clearCuotas } from '../actions/cuotaActions';
import { FaExclamationTriangle } from 'react-icons/fa';
import CustomPrintModal2 from './CustomPrintModal2';
import { formatNumberToLiteral } from './utils'
import './SocioCambioEstado.css'; // Importar el archivo CSS

const SocioEstadoCuenta = ({ show, handleClose, socio }) => {
  const [fechaCorte, setFechaCorte] = useState(() => new Date().toISOString().split('T')[0]);
  const [saldo, setSaldo] = useState(0);
  const [cuotasVencidas, setCuotasVencidas] = useState([]);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [printTitle, setPrintTitle] = useState('');
  const [printContent, setPrintContent] = useState({ head: [], body: [] });
  const [total1, setTotal1] = useState('');
  const [total2, setTotal2] = useState('');
  
  const dispatch = useDispatch();
  const { cuotas, loading, error } = useSelector(state => state.cuota);

  useEffect(() => {
    if (socio && show) {
      dispatch(clearCuotas());
      dispatch(fetchCuotasBySocio(socio.id));
    }
  }, [socio, show, dispatch]);

  useEffect(() => {
    if (cuotas && fechaCorte) {
      const fecha = new Date(fechaCorte);
      const cuotasVencidasFiltradas = cuotas.filter(cuota => {
        const fechaVencimiento = new Date(cuota.feemis);
        fechaVencimiento.setMonth(fechaVencimiento.getMonth() + 1);
        return cuota.estado === 0 && new Date(cuota.feemis) <= fecha;
      });
      const totalSaldo = cuotasVencidasFiltradas.reduce((acc, cuota) => acc + cuota.monto, 0);
      
      setSaldo(totalSaldo);
      setCuotasVencidas(cuotasVencidasFiltradas);
    }
  }, [cuotas, fechaCorte]);

  const handlePrintEstadoCuenta = () => {
    const content = {
      head: [["Gestión", "Fecha Emisión", "Monto", "Fecha Vencimiento"]],
      body: cuotasVencidas.map(cuota => {
        const fechaVencimiento = new Date(cuota.feemis);
        fechaVencimiento.setMonth(fechaVencimiento.getMonth() + 1);
        return [
          cuota.gestion,
          cuota.feemis ? new Date(cuota.feemis).toLocaleDateString() : '',
          cuota.monto !== null && cuota.monto !== undefined ? cuota.monto.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '',
          fechaVencimiento.toLocaleDateString()
        ];
      })
    };

    const socioNombreCompleto = `${socio.nombres} ${socio.apPat} ${socio.apMat}`;
    const totalEnLetras = formatNumberToLiteral(saldo); // Función para convertir el total a letras

    setPrintTitle('ESTADO DE CUENTAS');
    setPrintContent(content);
    setTotal1(`Total Bs.: ${saldo.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`);
    setTotal2(`Total: ${totalEnLetras}`);
    setShowPrintModal(true);
  };

  // Función para convertir un número a palabras en español
 

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Estado de Cuenta del Socio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <FaExclamationTriangle style={{ color: 'red', marginRight: '5px', fontSize: '3em' }} />
            <span style={{ fontSize: '1.5em', color: 'darkgreen', fontWeight: 'bold', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)' }}>Alerta:</span>
          </div>
          <p style={{ marginBottom: '20px' }}>
            Por favor ingrese la fecha de corte para calcular las cuotas vencidas.
          </p>
          {loading ? (
            <div>Cargando...</div>
          ) : error ? (
            <div>Error: {error}</div>
          ) : (
            <Form>
              <Row className="mb-3">
                <Col xs={6}>
                  <strong>Registro Nro.:</strong> <span className="data-large">{socio.codreg}</span>
                </Col>
                <Col xs={6}>
                  <strong>Socio:</strong> <span className="data-large">{`${socio.apPat} ${socio.apMat} ${socio.nombres}`}</span>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={6}>
                  <strong>Saldo Deudor:</strong> <span className="data-large">{saldo}</span>
                </Col>
                <Col xs={6}>
                  <strong>Fecha de Corte:</strong>
                  <Form.Control
                    type="date"
                    value={fechaCorte}
                    onChange={(e) => setFechaCorte(e.target.value)}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={12}>
                  <strong>Cuotas Vencidas:</strong>
                  <ul>
                    {cuotasVencidas.map(cuota => {
                      const fechaVencimiento = new Date(cuota.feemis);
                      fechaVencimiento.setMonth(fechaVencimiento.getMonth() + 1);
                      return (
                        <li key={cuota.id}>
                          {cuota.gestion} { ` Bs.`} {cuota.monto.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        </li>
                      );
                    })}
                  </ul>
                </Col>
              </Row>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer className="button-group align-content-between">
          <button className="button btn-cancel" onClick={handleClose}>
          <i className="bi bi-arrow-left"></i>  Volver 
          </button>
          <button className="button btn-accent" onClick={handlePrintEstadoCuenta}>
           Imprimir estado de Cuenta
          </button>
        </Modal.Footer>
      </Modal>

      <CustomPrintModal2
        show={showPrintModal}
        handleClose={() => setShowPrintModal(false)}
        title1="ESTADO DE CUENTAS"
        title2={`Fecha de corte: ${new Date(fechaCorte).toLocaleDateString()}`}
        title3= {`Número de Registro: ${socio.codreg}`}
        title4={`Socio: ${socio.nombres} ${socio.apPat} ${socio.apMat}`}
        total1={total1}
        total2={total2}
        content={printContent}
        copia={false}  // Cambiar a true si es necesario mostrar la marca de agua
      />
    </>
  );
};

export default SocioEstadoCuenta;
