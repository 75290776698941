const menus =
  [
    {
      title: "Administración",
      sections: [

        {
          name: "Sección: Actualización de datos generales",
          path: "/datgen",
          options: [
            { name: "Generación de Cuotas", path: "/generar-cuotas" },
            { name: "Actualización Cuotas", path: "/actualizar-montos-cuotas" },
            { name: "Modificación datos del colegio", path: "/actualizar-datos-generales" },
            { name: "Gestión Noticias", path: "/noticias" },
          ]
        },
        {
          name: "Sección: Gestión Usuarios",
          path: "/usuarios",
          options: [
            { name: "Administración Usuarios", path: `/usuarios/${encodeURIComponent("Cuadro de mando de Usuarios")}/1` },
            { name: "Listado Roles", path: "/roles" },
            { name: "Asignar Roles a Usuarios", path: `/usuarios/${encodeURIComponent("Asignar Rol a Usuarios ")}/3` },
            { name: "Cambiar de Clave a Usuarios", path: `/usuarios/${encodeURIComponent("Cambiar Clave de Usuarios ")}/2` },
          ]
        },
        {
          name: "Sección: Administración del Sistema",
          path: "/sistema",
          options: [{ name: "Gestión de Ciudades", path: "/ciudades" },
          { name: "Gestión de Países", path: "/paises" },
          { name: "Gestión de Universidades", path: "/universidades" },
          { name: "Gestión de Grados", path: "/grados" },
          { name: "Gestión de Cargos", path: "/cargos" },

          ]
        },

      ]
    },
    {
      title: "Gestión Socios",
      sections: [
        {
          name: "Sección: gestión Socios",
          path: "/socios",
          options: [
          { name: "Cuadro de Mando Socio", path: "/socios/1" },
          { name: "Estado de cuenta de un socio", path: `/socio-list-gen/${encodeURIComponent("Estado de cuenta de un socio")}/5` },
          { name: "Cambiar estado a un socio", path: `/socio-list-gen/${encodeURIComponent("Cambio de Estado a un socio")}/1` },
          { name: "Certificado de registro profesional", path: `/socio-list-gen/${encodeURIComponent("Certificado de Registro")}/2` },
          { name: "Certificado de solvencia", path: `/socio-list-gen/${encodeURIComponent("Certificado de Solvencia")}/3` },
          { name: "Ficha de Registro del Socio", path: `/socio-list-gen/${encodeURIComponent("Ficha de Registro del Socio")}/4` },
          ]
        },
      ]
    },

    {
      title: "Gestión Directivas",
      sections: [
        {
          name: "Sección: Gestión Directivas",
          path: "/directivas",
          options: [{ name: "Administración Directivas", path: `/directivas/${encodeURIComponent("Administración directivas")}/1` },
          { name: "Activación de la nueva directiva", path: `/directivas/${encodeURIComponent("Activación nueva directiva")}/2` },
          ]
        },

      ]
    },

  ];

export default menus;

