import createAxiosInstance from "../util/axiosInstance";

const api = createAxiosInstance('/cargo');

const fetchCargos = () => {
    return api.get('/');
};

const fetchCargo = (id) => {
    return api.get(`/${id}`);
};

const createCargo = (data) => {
    return api.post('/', data);
};

const updateCargo = (id, data) => {
    return api.put(`/${id}`, data);
};

const deleteCargo = (id) => {
    return api.delete(`/${id}`);
};

export default {
    fetchCargos,
    createCargo,
    updateCargo,
    deleteCargo,
    fetchCargo
};
