const initialState = {
    paises: [],
    currentPais: null,  // Added state to hold the current country details for editing
    loading: false,
    error: null
};

function paisReducer(state = initialState, action) {
    switch (action.type) {
        case 'FETCH_PAISES_REQUEST':
            return { ...state, loading: true };
        case 'FETCH_PAISES_SUCCESS':
            return { ...state, loading: false, paises: action.payload, error: null };
        case 'FETCH_PAISES_FAILURE':
            return { ...state, loading: false, error: action.payload };
        case 'ADD_PAIS':
            return { ...state, paises: [...state.paises, action.payload] };
        case 'UPDATE_PAIS_SUCCESS':
            const updatedPaises = state.paises.map(pais =>
                pais.id === action.payload.id ? action.payload : pais
            );
            return { ...state, paises: updatedPaises,error: null  };
        case 'DELETE_PAIS_SUCCESS':
            const filteredPaises = state.paises.filter(pais =>
                pais.id !== action.payload
            );
            return { ...state, paises: filteredPaises,error: null };
        case 'UPDATE_PAIS_REQUEST':
        case 'DELETE_PAIS_REQUEST':
            return { ...state, loading: true };
        case 'UPDATE_PAIS_FAILURE':
        case 'DELETE_PAIS_FAILURE':
            return { ...state, loading: false, error: action.payload };
        case 'FETCH_PAIS_SUCCESS':  // Handling successful fetch of a single country
            return { ...state, currentPais: action.payload, loading: false, error: null };
        case 'FETCH_PAIS_FAILURE':  // Handling failure in fetching a single country
            return { ...state, currentPais: null, loading: false, error: action.payload };
        case 'CLEAR_ERRORS':
            return { ...state, error: null };
        default:
            return state;
    }
}

export default paisReducer;