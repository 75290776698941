// services/usuarioService.js
import { getAuthToken } from "../util/auth";
import createAxiosInstance, {API_URL_BASE} from "../util/axiosInstance";


const api = createAxiosInstance('/usuarios');

const fetchUsuarios = async () => {
    const response = await api.get('/');
    return response.data;
};

const fetchUsuarioById = async (id) => {
    const response = await api.get(`/${id}`);
    return response.data;
};



const createUsuario = async (formData) => {
    try {
        formData.forEach((value, key) => {
            //console.log(`En servicio createUsuario - ${key}: ${value}`);
        });
        const response = await api.post('/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error en createUsuario:', error);
        throw error;
    }
};


const updateUsuario = async (id, formData) => {
    try {
    formData.forEach((value, key) => {
       // //console.log(`En servicio update - ${key}: ${value}`);
    });
    const response = await api.put(`/${id}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
} catch (error) {
    console.error('Error en Modificación de usuario:', error);
    throw error;
}
};

const deleteUsuario = async (id) => {
    const response = await api.delete(`/${id}`);
    return response.data;
};

const updatePassword = async (id, data) => {
    try {
        const response = await api.put(`/${id}/password`, data);
        return response.data;
    } catch (error) {
        console.error('Error en actualización de contraseña:', error);
        throw error;
    }
};

const updateUsuarol = async (data) => {
    try {
        const response = await api.put('/usuarol', data);
        return response.data;
    } catch (error) {
        console.error('Error en actualización de rol:', error);
        throw error;
    }
};


const getFotoUrlUsu = (id) => {
    const token = getAuthToken(); // Obtener el token de autenticación
    const timestamp = new Date().getTime(); // Obtener una marca de tiempo actual
  
    // Construir la URL de la imagen con el token como parámetro de consulta
    return `${API_URL_BASE}/usuarios/photo/${id}?token=${token}&timestamp=${timestamp}`; 
};
  
 

export default {
    fetchUsuarios,
    fetchUsuarioById,
    createUsuario,
    updateUsuario,
    deleteUsuario,
    updatePassword,
    updateUsuarol,
    getFotoUrlUsu
};
