import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRoles, deleteRol } from '../actions/rolActions';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import RolForm from './RolForm';
import { Link, useNavigate } from 'react-router-dom';
//import './rolList.css';  // Importamos los estilos CSS
import { toast } from 'react-toastify';
import { Pagination, Table } from 'react-bootstrap';

const RolList = () => {
    const dispatch = useDispatch();
    const { roles, error } = useSelector(state => state.rol);
    const [showForm, setShowForm] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchRoles());
    }, [dispatch]);

    const handleDelete = (id) => {
        confirmAlert({
            title: 'Confirmar eliminación',
            message: '¿Estás seguro de que deseas eliminar este rol?',
            buttons: [
                {
                    label: 'Sí',
                    onClick: () => {
                        dispatch(deleteRol(id))
                            .then(() => {
                                toast.success('rol eliminado correctamente');
                                dispatch(fetchRoles());
                            })
                            .catch(error => {
                                toast.error(`Error al eliminar el rol: ${error.message}`);
                            });
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    };

    const handleShowForm = (id = null) => {
        setSelectedId(id);
        setIsEdit(!!id);
        setShowForm(true);
        dispatch(fetchRoles());
    };

    const handleCloseForm = () => {
        setShowForm(false);
        setSelectedId(null);
        setIsEdit(false);
        dispatch(fetchRoles());
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentroles = roles.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(1);
    };
    const totalPages = Math.ceil(roles.length / itemsPerPage);

    if (error) {
        toast.error(`Error al cargar los datos: ${error}`);
    }

    return (
        <div className="list2-container container-list">
            <h2>Listado de roles</h2>
            <div className="mt-3">
                {/* <button onClick={() => handleShowForm()} className="button btn-accent float-end">
                    <i className="bi bi-plus-lg"></i> 
                    Nuevo rol
                </button> */}
                <label htmlFor="items-per-page">Registros por página:</label>
                <select id="items-per-page" value={itemsPerPage} onChange={handleItemsPerPageChange}>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                </select>
            </div>
            <div className='table-container'>
                <Table hover striped border={1}>
                    <thead>
                        <tr>
                            <th className='th-cod'>N°</th>
                            <th>Nombre</th>
                            <th>Descripción</th>
                            {/* <th className='th-actions'>Acciones</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {currentroles.map((rol, index) => (
                            <tr key={rol.id}>
                                <td className='td-cod'>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                <td>{rol.nombre}</td>
                                <td>{rol.descripcion}</td>
                                {/* <td>
                                    <button onClick={() => handleShowForm(rol.id)} className="button btn-accent-outline"><i className="bi bi-pencil"></i></button>
                                    <button onClick={() => handleDelete(rol.id)} className="btn btn-outline-danger"><i className="bi bi-trash"></i></button>
                                </td> */}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
            
            <div className='text-center mb-3'>
                <button onClick={() => navigate('/')} className="button btn-cancel"> <i className="bi bi-arrow-left"></i>  Volver</button>
            </div>

            <div className="pagination-container">
                <Pagination className="custom-pagination">
                    <Pagination.First
                        onClick={() => paginate(1)}
                        disabled={currentPage === 1}
                    />
                    <Pagination.Prev
                        onClick={() => paginate(currentPage - 1)}
                        disabled={currentPage === 1}
                    />
                    {[...Array(totalPages)].map((_, index) => (
                        <Pagination.Item
                            key={index + 1}
                            active={index + 1 === currentPage}
                            onClick={() => paginate(index + 1)}
                        >
                            {index + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next
                        onClick={() => paginate(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    />
                    <Pagination.Last
                        onClick={() => paginate(totalPages)}
                        disabled={currentPage === totalPages}
                    />
                </Pagination>
            </div>

            {showForm && (
                <RolForm
                    show={showForm}
                    handleClose={handleCloseForm}
                    isEdit={isEdit}
                    selectedId={selectedId}
                />
            )}
        </div>
    );
};

export default RolList;
