import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { generarCuotas } from '../actions/cuotaActions';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../store/user-context';
import { FaExclamationTriangle } from 'react-icons/fa';

const GenCuotas = () => {
    const { userState } = useContext(UserContext);
    const { user, roles } = userState;
    
    const [selectedDate, setSelectedDate] = useState(new Date());
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const cuotasState = useSelector((state) => state.cuota);
    const { loading, success, error, message } = cuotasState || {};

    useEffect(() => {
        if (success) {
            toast.success("Cuotas generadas correctamente...para la gestion..",);
        } else if (error) {
            toast.error(`Error al generar cuotas: ${error}`);
        }
    }, [success, error, message]);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const formatDateToISO = (date) => {
        const timezoneOffset = date.getTimezoneOffset() * 60000;
        return new Date(date.getTime() - timezoneOffset).toISOString().split('T')[0];
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (window.confirm(`${user} ${roles} ¿Estás seguro de que deseas generar las cuotas para esta fecha?`)) {
            const formattedDate = formatDateToISO(selectedDate);
            dispatch(generarCuotas(formattedDate));
        }
    };

    return (
        <div className="list0-container container-list">
            <form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <FaExclamationTriangle style={{ color: 'red', marginRight: '5px', fontSize: '3em' }} />
                    <span style={{ fontSize: '1.5em', color: 'darkgreen', fontWeight: 'bold', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)' }}>Alerta:</span>
                </div>
                <p style={{ marginBottom: '20px' }}>
                    La generación de cuotas, es un proceso delicado, si está seguro(a) de continuar, especifique la fecha de emisión, tome en cuenta que el año de la fecha que coloque, será la gestión de la que se generará las cuotas. Tome en cuenta que se genera las cuotas de la gestión especificada, para los socios en estado inactivo(0) y para los socios activos (1). Para los socios dados de baja (2), NO se genera las cuotas.
                </p>
                <label>Fecha</label>
                <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    dateFormat="yyyy-MM-dd"
                    showYearDropdown
                    scrollableYearDropdown
                    minDate={new Date(2000, 0, 1)}
                    maxDate={new Date(2030, 0, 1)}
                    className="form-control"
                />
                <div className='button-group'>
                    <button className="button btn-cancel" onClick={() => navigate('/')} style={{ marginLeft: '10px' }}>
                  Cancelar
                    </button>
                    <button className="button btn-accent" type="submit" disabled={loading}>
                        {loading ? 'Generando...' : 'Generar Cuotas'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default GenCuotas;

