import titulosService from '../services/titulosService';

export const FETCH_TITULOS_REQUEST = 'FETCH_TITULOS_REQUEST';
export const FETCH_TITULOS_SUCCESS = 'FETCH_TITULOS_SUCCESS';
export const FETCH_TITULOS_FAILURE = 'FETCH_TITULOS_FAILURE';

export const FETCH_TITULO_REQUEST = 'FETCH_TITULO_REQUEST';
export const FETCH_TITULO_SUCCESS = 'FETCH_TITULO_SUCCESS';
export const FETCH_TITULO_FAILURE = 'FETCH_TITULO_FAILURE';

export const CREATE_TITULO_REQUEST = 'CREATE_TITULO_REQUEST';
export const CREATE_TITULO_SUCCESS = 'CREATE_TITULO_SUCCESS';
export const CREATE_TITULO_FAILURE = 'CREATE_TITULO_FAILURE';

export const UPDATE_TITULO_REQUEST = 'UPDATE_TITULO_REQUEST';
export const UPDATE_TITULO_SUCCESS = 'UPDATE_TITULO_SUCCESS';
export const UPDATE_TITULO_FAILURE = 'UPDATE_TITULO_FAILURE';

export const DELETE_TITULO_REQUEST = 'DELETE_TITULO_REQUEST';
export const DELETE_TITULO_SUCCESS = 'DELETE_TITULO_SUCCESS';
export const DELETE_TITULO_FAILURE = 'DELETE_TITULO_FAILURE';

export const fetchTitulos = (socioId) => async (dispatch) => {
    dispatch({ type: FETCH_TITULOS_REQUEST });
    try {
        const response = await titulosService.fetchTitulos(socioId);
        dispatch({ type: FETCH_TITULOS_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: FETCH_TITULOS_FAILURE, payload: error.message });
    }
};

export const fetchTitulo = (id) => async (dispatch) => {
    dispatch({ type: FETCH_TITULO_REQUEST });
    try {
        const response = await titulosService.fetchTitulo(id);
        dispatch({ type: FETCH_TITULO_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: FETCH_TITULO_FAILURE, payload: error.message });
    }
};

export const createTitulo = (data) => async (dispatch) => {
    dispatch({ type: CREATE_TITULO_REQUEST });
    try {
        const response = await titulosService.createTitulo(data);
        dispatch({ type: CREATE_TITULO_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: CREATE_TITULO_FAILURE, payload: error.message });
    }
};

export const updateTitulo = (id, data) => async (dispatch) => {
    dispatch({ type: UPDATE_TITULO_REQUEST });
    try {
        const response = await titulosService.updateTitulo(id, data);
        dispatch({ type: UPDATE_TITULO_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: UPDATE_TITULO_FAILURE, payload: error.message });
    }
};

export const deleteTitulo = (id) => async (dispatch) => {
    dispatch({ type: DELETE_TITULO_REQUEST });
    try {
        await titulosService.deleteTitulo(id);
        dispatch({ type: DELETE_TITULO_SUCCESS, payload: id });
    } catch (error) {
        dispatch({ type: DELETE_TITULO_FAILURE, payload: error.message });
    }
};
