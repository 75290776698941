import {
    FETCH_USER_ROLES_SUCCESS,
    FETCH_USER_ROLES_FAILURE,
    CREATE_USER_ROLE_SUCCESS,
    CREATE_USER_ROLE_FAILURE,
    DELETE_USER_ROLES_SUCCESS,
    DELETE_USER_ROLES_FAILURE
} from '../actions/usuarolActions';

const initialState = {
    userRoles: [],
    error: null
};

const usuarolReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USER_ROLES_SUCCESS:
            return {
                ...state,
                userRoles: action.payload,
                error: null
            };
        case FETCH_USER_ROLES_FAILURE:
        case CREATE_USER_ROLE_FAILURE:
        case DELETE_USER_ROLES_FAILURE:
            return {
                ...state,
                error: action.payload
            };
        case CREATE_USER_ROLE_SUCCESS:
            return {
                ...state,
                userRoles: [...state.userRoles, action.payload],
                error: null
            };
        case DELETE_USER_ROLES_SUCCESS:
            return {
                ...state,
                userRoles: state.userRoles.filter(role => role.idusu !== action.payload),
                error: null
            };
        default:
            return state;
    }
};

export default usuarolReducer;
