import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchCiudades, deleteCiudad } from '../actions/ciudadActions';
import { fetchPaises } from '../actions/paisActions';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import { Pagination, Table, Button, Modal } from 'react-bootstrap';
import CiudadForm from './CiudadForm';
import CustomPrintModal from './CustomPrintModal';
import './CiudadList.css';

const CiudadList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        const fromHeader = location.state?.fromHeader;
        //console.log('fromHeader =', fromHeader);
        if (!fromHeader) {
            navigate('/'); // Redirige a la página principal si no es llamado desde Header
        }
    }, [navigate]);

    const dispatch = useDispatch();
    const ciudades = useSelector(state => state.ciudad.ciudades);
    const paises = useSelector(state => state.pais.paises);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [showModal, setShowModal] = useState(false);
    const [selectedCiudad, setSelectedCiudad] = useState(null);
    const [showPrintModal, setShowPrintModal] = useState(false);
    const [content, setContent] = useState([]);

    useEffect(() => {
        dispatch(fetchCiudades());
        dispatch(fetchPaises());
    }, [dispatch]);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentCiudades = ciudades.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(1);
    };
    const totalPages = Math.ceil(ciudades.length / itemsPerPage);

    const handleDelete = (id) => {
        const ciudadToDelete = ciudades.find(c => c.id === id);
        if (!ciudadToDelete) {
            toast.error('Ciudad no encontrada.');
            return;
        }

        confirmAlert({
            title: 'Confirmar eliminación',
            message: `¿Estás seguro de que deseas eliminar la ciudad ${ciudadToDelete.nombre}?`,
            buttons: [
                {
                    label: 'Sí',
                    onClick: () => {
                        dispatch(deleteCiudad(id))
                            .then(() => {
                                toast.success(`Ciudad eliminada con éxito.`);
                                dispatch(fetchCiudades());
                            })
                            .catch(error => {
                                toast.error(`No se pudo eliminar la ciudad. ${error.message || 'Intente de nuevo más tarde.'}`);
                            });
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    };

    const handlePrintModalShow = () => {
        const content = {
            head: [["N°", "Nombre de la Ciudad", "Nombre del País"]],
            body: ciudades.map((ciudad, index) => [
                index+1,
                ciudad.nombre,
                paises.find(p => p.id === ciudad.codpais)?.nombre || 'No Asignado'
            ])
        };
        setContent(content);
        setShowPrintModal(true);
    };

    const handleModalClose = () => setShowModal(false);
    const handleModalShow = (ciudad) => {
        setSelectedCiudad(ciudad);
        setShowModal(true);
        dispatch(fetchCiudades());
    };

    const handlePrintModalClose = () => {
        setShowPrintModal(false);
    };

    return (
        <div className="list2-container container-list">
            <h2>Listado de Ciudades</h2>
            <div className="row mt-3">
            <div className='col-lg-8'>
            <label htmlFor="items-per-page">Registros por página:</label>
                <select id="items-per-page" value={itemsPerPage} onChange={handleItemsPerPageChange}>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                </select>
                </div>

            <div className='col-lg-4 float-lg-end'>
            <button onClick={() => handleModalShow(null)} className="button btn-accent">
                    <i className="bi bi-plus" /> Nueva Ciudad
                </button>
            </div>
    
    </div>
            <div className='table-container'>
                <Table hover striped border={1}>
                    <thead>
                        <tr>
                            <th className='th-cod'>N°</th>
                            <th>Nombre</th>
                            <th>País</th>
                            <th className='th-actions'>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentCiudades.map((ciudad, index) => (
                            <tr key={ciudad.id}>
                                <td className='td-cod'>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                <td>{ciudad.nombre}</td>
                                <td>{paises.find(p => p.id === ciudad.codpais)?.nombre || 'No Asignado'}</td>
                                <td>
                                    <button onClick={() => handleModalShow(ciudad)} className="button btn-accent-outline me-3"><i className="bi bi-pencil"></i></button>
                                    <button onClick={() => handleDelete(ciudad.id)} className="button btn-accent-outline"><i className="bi bi-trash"></i></button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
            <div className='text-center mb-3 button-group'>
                <button onClick={() => navigate('/')} className="button btn-cancel"><i className="bi bi-arrow-left"></i>  Volver</button>
                <button onClick={handlePrintModalShow} className="button btn-accent"><i className="bi bi-printer"></i> Imprimir</button>
            </div>
            <div className="pagination-container">
                <Pagination className="custom-pagination">
                    <Pagination.First
                        onClick={() => paginate(1)}
                        disabled={currentPage === 1}
                    />
                    <Pagination.Prev
                        onClick={() => paginate(currentPage - 1)}
                        disabled={currentPage === 1}
                    />
                    {[...Array(totalPages)].map((_, index) => (
                        <Pagination.Item
                            key={index + 1}
                            active={index + 1 === currentPage}
                            onClick={() => paginate(index + 1)}
                        >
                            {index + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next
                        onClick={() => paginate(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    />
                    <Pagination.Last
                        onClick={() => paginate(totalPages)}
                        disabled={currentPage === totalPages}
                    />
                </Pagination>
            </div>

            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedCiudad ? 'Editar Ciudad' : 'Nueva Ciudad'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CiudadForm ciudad={selectedCiudad} onClose={handleModalClose} />
                </Modal.Body>
            </Modal>
            <CustomPrintModal show={showPrintModal} handleClose={handlePrintModalClose} title="LISTA GENERAL DE CIUDADES" content={content} />
        </div>
    );
};

export default CiudadList;
