import gradoService from '../services/gradoService';

export const FETCH_GRADOS_REQUEST = 'FETCH_GRADOS_REQUEST';
export const FETCH_GRADOS_SUCCESS = 'FETCH_GRADOS_SUCCESS';
export const FETCH_GRADOS_FAILURE = 'FETCH_GRADOS_FAILURE';

export const FETCH_GRADO_REQUEST = 'FETCH_GRADO_REQUEST';
export const FETCH_GRADO_SUCCESS = 'FETCH_GRADO_SUCCESS';
export const FETCH_GRADO_FAILURE = 'FETCH_GRADO_FAILURE';

export const CREATE_GRADO_REQUEST = 'CREATE_GRADO_REQUEST';
export const CREATE_GRADO_SUCCESS = 'CREATE_GRADO_SUCCESS';
export const CREATE_GRADO_FAILURE = 'CREATE_GRADO_FAILURE';

export const UPDATE_GRADO_REQUEST = 'UPDATE_GRADO_REQUEST';
export const UPDATE_GRADO_SUCCESS = 'UPDATE_GRADO_SUCCESS';
export const UPDATE_GRADO_FAILURE = 'UPDATE_GRADO_FAILURE';

export const DELETE_GRADO_REQUEST = 'DELETE_GRADO_REQUEST';
export const DELETE_GRADO_SUCCESS = 'DELETE_GRADO_SUCCESS';
export const DELETE_GRADO_FAILURE = 'DELETE_GRADO_FAILURE';

export const fetchGrados = () => async (dispatch) => {
  dispatch({ type: FETCH_GRADOS_REQUEST });
  try {
    const response = await gradoService.fetchGrados();
    dispatch({ type: FETCH_GRADOS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_GRADOS_FAILURE, payload: error.message });
  }
};

export const fetchGrado = (id) => async (dispatch) => {
  dispatch({ type: FETCH_GRADO_REQUEST });
  try {
     //console.log("Antes de despachar...",id)
    const response = await gradoService.fetchGrado(id);
    dispatch({ type: FETCH_GRADO_SUCCESS, payload: response.data });
    //console.log("despues de service...",response.data)
  } catch (error) {
    dispatch({ type: FETCH_GRADO_FAILURE, payload: error.message });
  }
};

export const createGrado = (gradoData) => async (dispatch) => {
  dispatch({ type: CREATE_GRADO_REQUEST });
  try {
    const response = await gradoService.createGrado(gradoData);
    dispatch({ type: CREATE_GRADO_SUCCESS, payload: response.data });
    return response.data;
  } catch (error) {
    dispatch({ type: CREATE_GRADO_FAILURE, payload: error.message });
    return Promise.reject(error);
  }
};

export const updateGrado = (id, gradoData) => async (dispatch) => {
  dispatch({ type: UPDATE_GRADO_REQUEST });
  try {
    const response = await gradoService.updateGrado(id, gradoData);
    dispatch({ type: UPDATE_GRADO_SUCCESS, payload: response.data });
    return response.data;
  } catch (error) {
    dispatch({ type: UPDATE_GRADO_FAILURE, payload: error.message });
    return Promise.reject(error);
  }
};

export const deleteGrado = (id) => async (dispatch) => {
  dispatch({ type: DELETE_GRADO_REQUEST });
  try {
    await gradoService.deleteGrado(id);
    dispatch({ type: DELETE_GRADO_SUCCESS, payload: id });
  } catch (error) {
    dispatch({ type: DELETE_GRADO_FAILURE, payload: error.message });
    return Promise.reject(error);
  }
};
