// src/actions/directivaActions.js
import directivaService from '../services/directivaService';

export const FETCH_DIRECTIVAS_REQUEST = 'FETCH_DIRECTIVAS_REQUEST';
export const FETCH_DIRECTIVAS_SUCCESS = 'FETCH_DIRECTIVAS_SUCCESS';
export const FETCH_DIRECTIVAS_FAILURE = 'FETCH_DIRECTIVAS_FAILURE';

export const FETCH_DIRECTIVA_REQUEST = 'FETCH_DIRECTIVA_REQUEST';
export const FETCH_DIRECTIVA_SUCCESS = 'FETCH_DIRECTIVA_SUCCESS';
export const FETCH_DIRECTIVA_FAILURE = 'FETCH_DIRECTIVA_FAILURE';

export const CREATE_DIRECTIVA_REQUEST = 'CREATE_DIRECTIVA_REQUEST';
export const CREATE_DIRECTIVA_SUCCESS = 'CREATE_DIRECTIVA_SUCCESS';
export const CREATE_DIRECTIVA_FAILURE = 'CREATE_DIRECTIVA_FAILURE';

export const UPDATE_DIRECTIVA_REQUEST = 'UPDATE_DIRECTIVA_REQUEST';
export const UPDATE_DIRECTIVA_SUCCESS = 'UPDATE_DIRECTIVA_SUCCESS';
export const UPDATE_DIRECTIVA_FAILURE = 'UPDATE_DIRECTIVA_FAILURE';

export const DELETE_DIRECTIVA_REQUEST = 'DELETE_DIRECTIVA_REQUEST';
export const DELETE_DIRECTIVA_SUCCESS = 'DELETE_DIRECTIVA_SUCCESS';
export const DELETE_DIRECTIVA_FAILURE = 'DELETE_DIRECTIVA_FAILURE';

export const fetchDirectivas = () => async (dispatch) => {
    dispatch({ type: FETCH_DIRECTIVAS_REQUEST });
    try {
        const response = await directivaService.fetchDirectivas();
        dispatch({ type: FETCH_DIRECTIVAS_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: FETCH_DIRECTIVAS_FAILURE, payload: error.message });
    }
};

export const fetchDirectiva = (id) => async (dispatch) => {
    dispatch({ type: FETCH_DIRECTIVA_REQUEST });
    try {
        const response = await directivaService.fetchDirectiva(id);
        dispatch({ type: FETCH_DIRECTIVA_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: FETCH_DIRECTIVA_FAILURE, payload: error.message });
    }
};

export const createDirectiva = (data) => async (dispatch) => {
    dispatch({ type: CREATE_DIRECTIVA_REQUEST });
    try {
        const response = await directivaService.createDirectiva(data);
        dispatch({ type: CREATE_DIRECTIVA_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: CREATE_DIRECTIVA_FAILURE, payload: error.message });
    }
};

export const updateDirectiva = (id, data) => async (dispatch) => {
    dispatch({ type: UPDATE_DIRECTIVA_REQUEST });
    try {
        const response = await directivaService.updateDirectiva(id, data);
        dispatch({ type: UPDATE_DIRECTIVA_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: UPDATE_DIRECTIVA_FAILURE, payload: error.message });
    }
};


export const deleteDirectiva = (id) => async (dispatch) => {
    dispatch({ type: DELETE_DIRECTIVA_REQUEST });

    try {
        await directivaService.deleteDirectiva(id);
        dispatch({ type: DELETE_DIRECTIVA_SUCCESS, payload: id });
    } catch (error) {
        dispatch({ type: DELETE_DIRECTIVA_FAILURE, payload: error.response?.data?.message || error.message });
        throw error; // Asegúrate de lanzar el error para que pueda ser capturado en el componente
    }
};
