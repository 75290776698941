// socioReducer.js

import {
    FETCH_SOCIOS_REQUEST,
    FETCH_SOCIOS_SUCCESS,
    FETCH_SOCIOS_FAILURE,
    FETCH_SOCIO_REQUEST,
    FETCH_SOCIO_SUCCESS,
    FETCH_SOCIO_FAILURE,
    CREATE_SOCIO_REQUEST,
    CREATE_SOCIO_SUCCESS,
    CREATE_SOCIO_FAILURE,
    UPDATE_SOCIO_REQUEST,
    UPDATE_SOCIO_SUCCESS,
    UPDATE_SOCIO_FAILURE,
    DELETE_SOCIO_REQUEST,
    DELETE_SOCIO_SUCCESS,
    DELETE_SOCIO_FAILURE,
    FETCH_SOCIO_PHOTO_REQUEST, 
    FETCH_SOCIO_PHOTO_SUCCESS, 
    FETCH_SOCIO_PHOTO_FAILURE,
    FETCH_MAX_CODREG_REQUEST,
    FETCH_MAX_CODREG_SUCCESS,
    FETCH_MAX_CODREG_FAILURE


  } from '../actions/socioActions';
  
  const initialState = {
    socios: [],
    currentSocio: null,
    loading: false,
    error: null,
    lastCodreg: null,
  };
  


  function socioReducer(state = initialState, action) {
    switch (action.type) {
      case FETCH_SOCIOS_REQUEST:
      case FETCH_SOCIO_REQUEST:
      case CREATE_SOCIO_REQUEST:
      case UPDATE_SOCIO_REQUEST:
      case DELETE_SOCIO_REQUEST:
      case FETCH_MAX_CODREG_REQUEST:
      case FETCH_SOCIO_PHOTO_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };

       
     case FETCH_SOCIO_PHOTO_SUCCESS:
        return {
            ...state,
            loading: false,
            fotoUrl: action.payload,
            error:null
        };

      case FETCH_MAX_CODREG_SUCCESS:
          return {
              ...state,
              loading: false,
              lastCodreg: action.payload,
              error:null
          };

      case FETCH_SOCIOS_SUCCESS:
        return {
          ...state,
          socios: action.payload,
          loading: false,
          error: null,
        };
      case FETCH_SOCIO_SUCCESS:
        return {
          ...state,
          currentSocio: action.payload,
          loading: false,
          error: null,
        };
      case CREATE_SOCIO_SUCCESS:
        return {
          ...state,
          socios: [...state.socios, action.payload],
          loading: false,
          error: null,
        };
      case UPDATE_SOCIO_SUCCESS:
        return {
          ...state,
          socios: state.socios.map(socio => socio.id === action.payload.id ? action.payload : socio),
          loading: false,
          error: null,
        };
      case DELETE_SOCIO_SUCCESS:
        return {
          ...state,
          socios: state.socios.filter(socio => socio.id !== action.payload),
          loading: false,
          error: null,
        };
      case FETCH_SOCIOS_FAILURE:
      case FETCH_SOCIO_FAILURE:
      case CREATE_SOCIO_FAILURE:
      case UPDATE_SOCIO_FAILURE:
      case DELETE_SOCIO_FAILURE:
      case FETCH_MAX_CODREG_FAILURE:
      case FETCH_SOCIO_PHOTO_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  }
  
  export default socioReducer;
  