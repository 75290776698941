
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import { createTitulo, updateTitulo } from '../actions/titulosActions';
import { fetchUniversidades } from '../actions/universidadActions';
import { fetchGrados } from '../actions/gradoActions';
import { Modal, Button } from 'react-bootstrap';

const TitulosForm = ({ showFormModal, closeFormModal, titulo, socio }) => {
  const dispatch = useDispatch();
  const universidades = useSelector(state => state.universidad.universidades);
  const grados = useSelector(state => state.grado.grados);

  const [tituloData, setTituloData] = useState({
    nombre: titulo.nombre || '',
    descrip: titulo.descrip || '',
    grado: titulo.grado || '',
    univor: titulo.univor || '',
    fechor: titulo.fechor ? new Date(titulo.fechor) : null,
    univrev: titulo.univrev || '',
    fechrev: titulo.fechrev ? new Date(titulo.fechrev) : null,
    fechapos: titulo.fechapos ? new Date(titulo.fechapos) : null,
    documpdf: titulo.documpdf || null,
    idcodreg: titulo.idcodreg || null
  });

  const [submittedData, setSubmittedData] = useState(null);

  useEffect(() => {
    dispatch(fetchUniversidades());
    dispatch(fetchGrados());
  }, [dispatch]);

  useEffect(() => {
    setTituloData({
      nombre: titulo.nombre || '',
      descrip: titulo.descrip || '',
      grado: titulo.grado || '',
      univor: titulo.univor || '',
      fechor: titulo.fechor ? new Date(titulo.fechor) : null,
      univrev: titulo.univrev || '',
      fechrev: titulo.fechrev ? new Date(titulo.fechrev) : null,
      fechapos: titulo.fechapos ? new Date(titulo.fechapos) : null,
      documpdf: titulo.documpdf || null,
      idcodreg: titulo.idcodreg || null
    });
  }, [titulo]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTituloData(prev => ({ ...prev, [name]: value.toUpperCase() }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setTituloData(prev => ({ ...prev, documpdf: file }));
  };

  const handleDateChange = (name, date) => {
    setTituloData(prev => ({ ...prev, [name]: date }));
  };

  const handlePreview = (e) => {
    e.preventDefault();
    setSubmittedData(tituloData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!tituloData.nombre || !tituloData.grado || !tituloData.univor || !tituloData.fechor) {
      toast.error("Por favor complete los campos requeridos: Titulo, grado, universidad de origen, fecha de expedición del título");
      return;
    }

    const formData = new FormData();
    Object.keys(tituloData).forEach(key => {
      
      if(key==='idcodreg'){formData.append(key,socio.id); }
      else{
        if (tituloData[key] !== undefined && tituloData[key] !== null && tituloData[key] !== '') {
          if (key === 'fechor' || key === 'fechrev' || key === 'fechapos') {
            if (tituloData[key] !== null) {
             const newDate = new Date(tituloData[key]);
             formData.append(key, newDate.toISOString());
            };
          }          
          else{formData.append(key, tituloData[key]);}
        }else{formData.append(key, null);}
      }
  });
    //console.log("Mandando a actualizar...", titulo.id)
    if (titulo.id) {
      await dispatch(updateTitulo(titulo.id, formData));
      toast.success('Título actualizado exitosamente');
      closeFormModal(true);
    } else {
      await dispatch(createTitulo(formData));
      toast.success('Título creado exitosamente');
      closeFormModal(true);
    }
  };
  const getFileName = (path) => {
    return path ? path.split('\\').pop().split('/').pop() : '';
  };
  return (
    <Modal
      show={showFormModal}
      onHide={() => closeFormModal(false)}
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Formulario de Títulos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {socio && (
          <div className="socio-info">
            <p><strong>Código de Registro:</strong> {socio.codreg}</p>
            <p><strong>Socio:</strong> {socio.apPat} {socio.apMat} {socio.nombres}</p>
          </div>
        )}
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Título:</label>
            <input type="text" name="nombre" value={tituloData.nombre} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label>Descripción:</label>
            <input type="text" name="descrip" value={tituloData.descrip} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label>Grado:</label>
            <select name="grado" value={tituloData.grado} onChange={handleChange} required>
              <option value="">Seleccione un grado</option>
              {grados.map(grado => (
                <option key={grado.id} value={grado.id}>{grado.nombre}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Universidad Origen:</label>
            <select name="univor" value={tituloData.univor} onChange={handleChange}>
              <option value="">Seleccione una universidad</option>
              {universidades.map(universidad => (
                <option key={universidad.id} value={universidad.id}>{universidad.nombre}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Fecha Origen:</label>
            <DatePicker
              selected={tituloData.fechor}
              showYearDropdown
              showMonthDropdown
              yearDropdownItemNumber={120} // Mostrar un rango más amplio de años
              scrollableYearDropdown // Hacer que el menú de años sea desplazable
              minDate={new Date(1900, 0, 1)} // Establecer la fecha mínima
              maxDate={new Date()} // Establecer la fecha máxima
              onChange={(date) => handleDateChange('fechor', date)}
              isClearable
              placeholderText="Seleccione una fecha"
            />
          </div>
          <div className="form-group">
            <label>Universidad Revalida:</label>
            <select name="univrev" value={tituloData.univrev} onChange={handleChange}>
              <option value="">Seleccione una universidad</option>
              {universidades.map(universidad => (
                <option key={universidad.id} value={universidad.id}>{universidad.nombre}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Fecha Revalida:</label>
            <DatePicker
              selected={tituloData.fechrev}
              showYearDropdown
              showMonthDropdown
              yearDropdownItemNumber={120} // Mostrar un rango más amplio de años
              scrollableYearDropdown // Hacer que el menú de años sea desplazable
              minDate={new Date(1900, 0, 1)} // Establecer la fecha mínima
              maxDate={new Date()} // Establecer la fecha máxima
              onChange={(date) => handleDateChange('fechrev', date)}
              isClearable
              placeholderText="Seleccione una fecha"
            />
          </div>
          <div className="form-group">
            <label>Fecha Postgrado:</label>
            
            <DatePicker
              selected={tituloData.fechapos}
              showYearDropdown
              showMonthDropdown
              yearDropdownItemNumber={120} // Mostrar un rango más amplio de años
              scrollableYearDropdown // Hacer que el menú de años sea desplazable
              minDate={new Date(1900, 0, 1)} // Establecer la fecha mínima
              maxDate={new Date()} // Establecer la fecha máxima
              onChange={(date) => handleDateChange('fechapos', date)}
              isClearable
              placeholderText="Seleccione una fecha"
            />
          </div>
          <div className="form-group">
            <label>Documento PDF:</label>
            <input type="file" name="documpdf" accept="application/pdf" onChange={handleFileChange} />
            {tituloData.documpdf && !(tituloData.documpdf instanceof File) && (
              <div>
                 <p>Documento actual: {getFileName(tituloData.documpdf)}</p>
              </div>
            )}


          </div>
          <div className="button-group">
            <button className="button btn-cancel" onClick={() => closeFormModal(false)}>
              Cancelar
            </button>
            <Button variant="dark" onClick={handlePreview}>
              Previsualizar
            </Button>
            <button type="submit" className="button btn-accent">Guardar</button>
          </div>
        </form>
        {submittedData && (
          <div>
            <h3>Datos a Enviar:</h3>
            <p><strong>ID:</strong> {submittedData.id || 'N/A'}</p>
            <p><strong>Título:</strong> {submittedData.nombre}</p>
            <p><strong>Descripción:</strong> {submittedData.descrip}</p>
            <p><strong>Grado:</strong> {grados.find(grado => grado.id === submittedData.grado)?.nombre}</p>
            <p><strong>Universidad Origen:</strong> {universidades.find(universidad => universidad.id === submittedData.univor)?.nombre}</p>
            <p><strong>Fecha Origen:</strong> {submittedData.fechor ? submittedData.fechor.toLocaleDateString() : 'N/A'}</p>
            <p><strong>Universidad Revalida:</strong> {universidades.find(universidad => universidad.id === submittedData.univrev)?.nombre}</p>
            <p><strong>Fecha Revalida:</strong> {submittedData.fechrev ? submittedData.fechrev.toLocaleDateString() : 'N/A'}</p>
            <p><strong>Fecha Postgrado:</strong> {submittedData.fechapos ? submittedData.fechapos.toLocaleDateString() : 'N/A'}</p>
            <p><strong>Documento PDF:</strong> {submittedData.documpdf ? submittedData.documpdf.name : 'N/A'}</p>
            <p><strong>idCodreg:</strong> {submittedData.idcodreg}</p>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default TitulosForm;
