import { useContext } from "react";
import { UserContext } from "../store/user-context";
import { actionLogout } from "../util/auth";
import { useNavigate } from "react-router-dom";

const Logout = () => {
    const { setUser } = useContext(UserContext);
    const navigate = useNavigate()
    function handleLogout() {
        actionLogout();
        setUser(null);
        navigate("/")
    }
    return(
        <button onClick={handleLogout} className="button btn-accent-outline">Cerrar sesión</button>
    )
}

export default Logout;