import socioService from '../services/socioService';
import axios from 'axios';

export const FETCH_SOCIOS_REQUEST = 'FETCH_SOCIOS_REQUEST';
export const FETCH_SOCIOS_SUCCESS = 'FETCH_SOCIOS_SUCCESS';
export const FETCH_SOCIOS_FAILURE = 'FETCH_SOCIOS_FAILURE';

export const FETCH_SOCIO_REQUEST = 'FETCH_SOCIO_REQUEST';
export const FETCH_SOCIO_SUCCESS = 'FETCH_SOCIO_SUCCESS';
export const FETCH_SOCIO_FAILURE = 'FETCH_SOCIO_FAILURE';

export const CREATE_SOCIO_REQUEST = 'CREATE_SOCIO_REQUEST';
export const CREATE_SOCIO_SUCCESS = 'CREATE_SOCIO_SUCCESS';
export const CREATE_SOCIO_FAILURE = 'CREATE_SOCIO_FAILURE';

export const UPDATE_SOCIO_REQUEST = 'UPDATE_SOCIO_REQUEST';
export const UPDATE_SOCIO_SUCCESS = 'UPDATE_SOCIO_SUCCESS';
export const UPDATE_SOCIO_FAILURE = 'UPDATE_SOCIO_FAILURE';

export const DELETE_SOCIO_REQUEST = 'DELETE_SOCIO_REQUEST';
export const DELETE_SOCIO_SUCCESS = 'DELETE_SOCIO_SUCCESS';
export const DELETE_SOCIO_FAILURE = 'DELETE_SOCIO_FAILURE';

export const FETCH_MAX_CODREG_REQUEST = 'FETCH_MAX_CODREG_REQUEST';
export const FETCH_MAX_CODREG_SUCCESS = 'FETCH_MAX_CODREG_SUCCESS';
export const FETCH_MAX_CODREG_FAILURE = 'FETCH_MAX_CODREG_FAILURE';

export const RESET_CURRENT_SOCIO = 'RESET_CURRENT_SOCIO';


export const FETCH_FOTO_URL_REQUEST = 'FETCH_FOTO_URL_REQUEST';
export const FETCH_FOTO_URL_SUCCESS = 'FETCH_FOTO_URL_SUCCESS';
export const FETCH_FOTO_URL_FAILURE = 'FETCH_FOTO_URL_FAILURE';


export const FETCH_SOCIO_PHOTO_REQUEST = 'FETCH_SOCIO_PHOTO_REQUEST';
export const FETCH_SOCIO_PHOTO_SUCCESS = 'FETCH_SOCIO_PHOTO_SUCCESS';
export const FETCH_SOCIO_PHOTO_FAILURE = 'FETCH_SOCIO_PHOTO_FAILURE';




export const fetchSocioPhoto = (id) => async (dispatch) => {
  dispatch({ type: FETCH_SOCIO_PHOTO_REQUEST });
  try {
    const photoUrl = await socioService.getSocioPhoto(id);
    dispatch({ type: FETCH_SOCIO_PHOTO_SUCCESS, payload: photoUrl });
    return photoUrl;
  } catch (error) {
    dispatch({ type: FETCH_SOCIO_PHOTO_FAILURE, payload: error.message });
  }
};


export const fetchFotoUrl = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: FETCH_FOTO_URL_REQUEST });
    try {
      const fotoUrl = socioService.getFotoUrl(id); // Asegúrate de que esta función síncrona no lance excepciones inesperadas
      dispatch({ type: FETCH_FOTO_URL_SUCCESS, payload: fotoUrl });
      resolve(fotoUrl); // Resuelve la promesa con el resultado
    } catch (error) {
      dispatch({ type: FETCH_FOTO_URL_FAILURE, payload: error.message });
      reject(error.message); // Rechaza la promesa con el mensaje de error
    }
  });
};


export const resetCurrentSocio = () => {
  return {
    type: RESET_CURRENT_SOCIO
  };
};

export const fetchMaxCodreg = () => async (dispatch) => {
  dispatch({ type: FETCH_MAX_CODREG_REQUEST });
  try {
    const response = await socioService.getMaxCodreg();
    dispatch({ type: FETCH_MAX_CODREG_SUCCESS, payload: response });
    //console.log("EN action de max:", response );
    return response;
  } catch (error) {
    dispatch({ type: FETCH_MAX_CODREG_FAILURE, payload: error.message });
  }
};

export const fetchSocios = () => async (dispatch) => {
  dispatch({ type: FETCH_SOCIOS_REQUEST });
  try {
    const response = await socioService.fetchSocios();
    dispatch({ type: FETCH_SOCIOS_SUCCESS, payload: response.data });
    return response;
  } catch (error) {
    dispatch({ type: FETCH_SOCIOS_FAILURE, payload: error.message });
  }
};

export const fetchSocio = (id) => async (dispatch) => {
  dispatch({ type: FETCH_SOCIO_REQUEST });
  try {
    const response = await socioService.fetchSocio(id);
    //console.log("After fetching socio..", response.data)
    dispatch({ type: FETCH_SOCIO_SUCCESS, payload: response.data });
    return response;
  } catch (error) {
    dispatch({ type: FETCH_SOCIO_FAILURE, payload: error.message });
  }
};

export const createSocio = (formData) => async (dispatch) => {
  dispatch({ type: CREATE_SOCIO_REQUEST });
  try {
    const response = await socioService.createSocio(formData);
    dispatch({ type: CREATE_SOCIO_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: CREATE_SOCIO_FAILURE, payload: error.message });
  }
};

export const updateSocio = (id, formData) => async (dispatch) => {
  dispatch({ type: UPDATE_SOCIO_REQUEST });
  try {
    const response = await socioService.updateSocio(id, formData);
    dispatch({ type: UPDATE_SOCIO_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: UPDATE_SOCIO_FAILURE, payload: error.message });
  }
};

export const deleteSocio = (id) => async (dispatch) => {
  dispatch({ type: DELETE_SOCIO_REQUEST });
  try {
    await socioService.deleteSocio(id);
    dispatch({ type: DELETE_SOCIO_SUCCESS, payload: id });
  } catch (error) {
    dispatch({ type: DELETE_SOCIO_FAILURE, payload: error.message });
  }
};
