import createAxiosInstance from "../util/axiosInstance";

const api = createAxiosInstance('/direcsoc');

const fetchDirectivaSocios = (directivaId) => {
    return api.get(`/directiva/${directivaId}`);
};

const deleteDirectivaSocios = (directivaId) => {
    return api.delete(`/directiva/${directivaId}`);
};

const saveDirectivaSocios = (socioData) => {
    return api.post('/', socioData);
};

const getAllDirecsoc = () => {
    return api.get('/');
};

const getDirecsoc = (id) => {
    return api.get(`/${id}`);
};

const updateDirecsoc = (id, socioData) => {
    return api.put(`/${id}`, socioData);
};

const deleteDirecsoc = (id) => {
    return api.delete(`/${id}`);
};

export default {
    fetchDirectivaSocios,
    deleteDirectivaSocios,
    saveDirectivaSocios,
    getAllDirecsoc,
    getDirecsoc,
    updateDirecsoc,
    deleteDirecsoc,
};
