import createAxiosInstance from "../util/axiosInstance";

const api = createAxiosInstance('/titulos');

  // Definir las funciones de API usando la instancia de Axios configurada
  
const fetchTitulos = (socioId) => api.get(`/socio/${socioId}`);
const fetchTitulo = (id) => api.get(`/${id}`);
const createTitulo = (data) => api.post('/', data, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
});
const updateTitulo = (id, data) => api.put(`/${id}`, data, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
});
const deleteTitulo = (id) => api.delete(`/${id}`);

export default {
    fetchTitulos,
    fetchTitulo,
    createTitulo,
    updateTitulo,
    deleteTitulo
};
