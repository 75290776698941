import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGrados, deleteGrado, fetchGrado } from '../actions/gradoActions';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import GradoForm from './GradoForm';
import { useNavigate } from 'react-router-dom';
import { Pagination, Table, Button } from 'react-bootstrap';
import CustomPrintModal from './CustomPrintModal';
import './PaisList.css';

const GradoList = () => {
    const dispatch = useDispatch();
    const grados = useSelector(state => state.grado.grados);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedGradoId, setSelectedGradoId] = useState(null);
    const [showPrintModal, setShowPrintModal] = useState(false);
    const [content, setContent] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchGrados());
    }, [dispatch]);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentGrados = grados.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(1);
    };

    const totalPages = Math.ceil(grados.length / itemsPerPage);

    const handleDelete = (id) => {
        const gradoToDelete = grados.find(g => g.id === id);
        if (!gradoToDelete) {
            toast.error('Grado no encontrado.');
            return;
        }

        confirmAlert({
            title: 'Confirmar eliminación',
            message: `¿Estás seguro de que deseas eliminar el grado ${gradoToDelete.nombre} ?`,
            buttons: [
                {
                    label: 'Sí',
                    onClick: () => {
                        dispatch(deleteGrado(id))
                            .then(() => {
                                toast.success(`Grado eliminado con éxito.`);
                                dispatch(fetchGrados());
                            })
                            .catch(error => {
                                toast.error(`No se pudo eliminar el grado. ${error.message || 'Intente de nuevo más tarde.'}`);
                            });
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    };

    const handlePrintModalShow = () => {
        const content = {
            head: [["N°", "Nombre del Grado", "Referencia"]],
            body: grados.map((grado, index) => [
                index+1,
                grado.nombre,
                grado.refer
            ])
        };
        setContent(content);
        setShowPrintModal(true);
    };

    const handlePrintModalClose = () => {
        setShowPrintModal(false);
    };

    const openModal = (gradoId = null) => {
        if (gradoId) {
            dispatch(fetchGrado(gradoId)).then(() => {
                setSelectedGradoId(gradoId);
                setModalIsOpen(true);
            });
        } else {
            setSelectedGradoId(null);
            setModalIsOpen(true);
        }
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSelectedGradoId(null);
        dispatch(fetchGrados());
    };

    return (
        <div className="list2-container container-list">
            <h2>Listado de Grados</h2>
            <div className="mt-3">
                <button onClick={() => openModal()} className="button btn-accent float-end">
                    <i className="bi bi-plus-lg"></i>
                    Nuevo Grado
                </button>
                <label htmlFor="items-per-page">Registros por página:</label>
                <select id="items-per-page" value={itemsPerPage} onChange={handleItemsPerPageChange}>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                </select>
            </div>
            <div className='table-container'>
                <Table hover striped border={1}>
                    <thead>
                        <tr>
                            <th className='th-cod'>N°</th>
                            <th>Nombre</th>
                            <th>Referencia</th>
                            <th className='th-actions'>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentGrados.map((grado, index) => {
                            return(
                            <tr key={grado.id}>
                                 <td className='td-cod'>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                 <td>{grado.nombre}</td>
                                <td>{grado.refer}</td>
                                <td>
                                    <button onClick={() => openModal(grado.id)} className="button btn-accent-outline"><i className="bi bi-pencil"></i></button>
                                    <button onClick={() => handleDelete(grado.id)} className="button btn-accent-outline"><i className="bi bi-trash"></i></button>
                                </td>
                            </tr>
                        )})}
                    </tbody>
                </Table>
            </div>
            <div className='text-center mb-3 button-group'>
                <button onClick={() => navigate('/')} className="button btn-cancel"> <i className="bi bi-arrow-left"></i>Volver</button>
                <button onClick={handlePrintModalShow} className="button btn-accent"><i className="bi bi-printer"></i> Imprimir</button>
            </div>
            <div className="pagination-container">
                <Pagination className="custom-pagination">
                    <Pagination.First
                        onClick={() => paginate(1)}
                        disabled={currentPage === 1}
                    />
                    <Pagination.Prev
                        onClick={() => paginate(currentPage - 1)}
                        disabled={currentPage === 1}
                    />
                    {[...Array(totalPages)].map((_, index) => (
                        <Pagination.Item
                            key={index + 1}
                            active={index + 1 === currentPage}
                            onClick={() => paginate(index + 1)}
                        >
                            {index + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next
                        onClick={() => paginate(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    />
                    <Pagination.Last
                        onClick={() => paginate(totalPages)}
                        disabled={currentPage === totalPages}
                    />
                </Pagination>
            </div>
           {modalIsOpen && <GradoForm isOpen={modalIsOpen} onRequestClose={closeModal} gradoId={selectedGradoId} />
            }
            <CustomPrintModal show={showPrintModal} handleClose={handlePrintModalClose} title="Listado de Grados" content={content} />
        </div>
    );
};

export default GradoList;
