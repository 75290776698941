import React, { useState } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import PaisList from './components/PaisList';
import CiudadList from './components/CiudadList';
import UniversidadList from './components/UniversidadList';
import DirectivaList from './components/DirectivaList';
import CargoList from './components/CargoList';
import SocioList from './components/SocioList'; // Import SocioList
import './App.css';
import * as bootstrap from 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css'; // This imports the default styling
import GradoList from './components/GradoList';
//import Home from './components/Home';
import '@fortawesome/fontawesome-free/css/all.min.css';
import LoginForm from './components/LoginForm';
import { UserProvider } from './store/user-context';
import {  checkAdminLoader, checkAuthLoader, tokenLoader } from './util/auth';
import { RootLayout } from './pages/Root';
import ErrorPage from './pages/Error';
//import PaisForm from './components/PaisForm';
//import CiudadForm from './components/CiudadForm';
//import UniversidadForm from './components/UniversidadForm';
//import SocioForm from './components/SocioForm'; // Import SocioForm
//import GradoForm from './components/GradoForm';
import InicioPage from './pages/Inicio';
import GenCuotas from './components/GenCuotas';
import UsuarioList from './components/UsuarioList';
import SocioListGen from './components/SocioListGen';
import ActualizarMontosCuotas from './components/ActualizarMontosCuotas';
import RolList from './components/RolList';
import ActualizarDatosGenerales from './components/ActualizarDatosGenerales';
import NoticiaList from './components/NoticiaList';
function App() {
  console.log("Entro a APP del frontend");

  const [currentId, setCurrentId] = useState(null);

  const router = createBrowserRouter([
    {
      path: '/',
      element: <RootLayout />,
      errorElement: <ErrorPage />,
      id: 'root',
      loader: tokenLoader,
      children: [
        { path: '/', element: <InicioPage /> },
        {
          path: '/login',
          element: <LoginForm />
        },
        { path: '/paises', element: <PaisList setCurrentId={setCurrentId} />, loader: checkAuthLoader },
        { path: '/ciudades', element: <CiudadList />, loader: checkAuthLoader },
        { path: '/universidades', element: <UniversidadList />, loader: checkAuthLoader },
        { path: '/socios/:drol', element: <SocioList />, loader: checkAdminLoader },
        { path: '/grados', element: <GradoList />, loader: checkAuthLoader },
        { path: '/directivas/:titulo/:funcionalidad', element: <DirectivaList currentId={currentId} />, loader: checkAuthLoader },
        { path: '/cargos', element: <CargoList currentId={currentId} />, loader: checkAuthLoader },
        { path: '/roles', element: <RolList currentId={currentId} />, loader: checkAuthLoader },
        { path: '/generar-cuotas', element: <GenCuotas currentId={currentId} />, loader: checkAuthLoader },
        { path: '/usuarios/:titulo/:funcionalidad', element: <UsuarioList currentId={currentId} />, loader: checkAuthLoader },
        { path: '/socio-list-gen/:titulo/:funcionalidad', element: <SocioListGen currentId={currentId} />, loader: checkAuthLoader },
        { path: '/actualizar-montos-cuotas', element: <ActualizarMontosCuotas currentId={currentId} />, loader: checkAuthLoader },
        { path: '/actualizar-datos-generales', element: <ActualizarDatosGenerales currentId={currentId} />, loader: checkAuthLoader },
        { path: '/noticias', element: <NoticiaList currentId={currentId} />, loader: checkAuthLoader },
      ]
    }
  ]);

  console.log("EL rooter ha sido creado exitosamente...");

  return (
    <UserProvider>
      <RouterProvider router={router} />
    </UserProvider>
  );
}

export default App;
