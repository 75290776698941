import createAxiosInstance from "../util/axiosInstance";

const api = createAxiosInstance('/pais');

// Definir las funciones de API usando la instancia de Axios configurada
const fetchPaises = () => api.get('/');
const fetchPais = (id) => api.get(`/${id}`);
const createPais = (data) => api.post('/', data);
const updatePais = (id, data) => api.put(`/${id}`, data);
const deletePais = (id) => api.delete(`/${id}`);

export default {
  fetchPaises,
  fetchPais,
  createPais,
  updatePais,
  deletePais
};