// src/reducers/cargoReducer.js
import {
    FETCH_CARGOS_SUCCESS,
    FETCH_CARGOS_FAILURE,
    FETCH_CARGO_SUCCESS,
    FETCH_CARGO_FAILURE,
    CREATE_CARGO_SUCCESS,
    CREATE_CARGO_FAILURE,
    UPDATE_CARGO_SUCCESS,
    UPDATE_CARGO_FAILURE,
    DELETE_CARGO_SUCCESS,
    DELETE_CARGO_FAILURE
} from '../actions/cargoActions';

const initialState = {
    cargos: [],
    currentCargo: null,
    error: null
};

const cargoReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CARGOS_SUCCESS:
            return {
                ...state,
                cargos: action.payload,
                error: null
            };
        case FETCH_CARGOS_FAILURE:
        case FETCH_CARGO_FAILURE:
        case CREATE_CARGO_FAILURE:
        case UPDATE_CARGO_FAILURE:
        case DELETE_CARGO_FAILURE:
            return {
                ...state,
                error: action.payload
            };
        case FETCH_CARGO_SUCCESS:
            return {
                ...state,
                currentCargo: action.payload,
                error: null
            };
        case CREATE_CARGO_SUCCESS:
            return {
                ...state,
                cargos: [...state.cargos, action.payload],
                error: null
            };
        case UPDATE_CARGO_SUCCESS:
            return {
                ...state,
                cargos: state.cargos.map(cargo =>
                    cargo.id === action.payload.id ? action.payload : cargo
                ),
                currentCargo: action.payload,
                error: null
            };
        case DELETE_CARGO_SUCCESS:
            return {
                ...state,
                cargos: state.cargos.filter(cargo => cargo.id !== action.payload),
                error: null
            };
        default:
            return state;
    }
};

export default cargoReducer;

