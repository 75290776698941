import paisService from '../services/paisService';
import axios from 'axios';
import { toast } from 'react-toastify';

export const fetchPaises = () => async (dispatch) => {
  dispatch({ type: 'FETCH_PAISES_REQUEST' });
  try {
    const response = await paisService.fetchPaises();
    dispatch({ type: 'FETCH_PAISES_SUCCESS', payload: response.data });
  } catch (error) {
    dispatch({ type: 'FETCH_PAISES_FAILURE', payload: error.message });
  }
};


// Acción fetchPais en actions/paisActions.js
export const fetchPais = (id) => async (dispatch) => {
    dispatch({ type: 'FETCH_PAIS_REQUEST' });
    try {
      const response = await paisService.fetchPais(id);
      //console.log('AQUI ESTOY fetching country:',response);  
      dispatch({ type: 'FETCH_PAIS_SUCCESS', payload: response.data });
      return response;
    } catch (error) {
      dispatch({ type: 'FETCH_PAIS_FAILURE', error: error.toString() });
      console.error('Error fetching country:', error);
    }
  };

/* Acción para obtener detalles de un país específico
export const fetchPais = (id) => async (dispatch) => {
    dispatch({ type: 'FETCH_PAIS_REQUEST' });
    try {
      const response = await paisService.fetchPais(id);
      dispatch({ type: 'FETCH_PAIS_SUCCESS', payload: response.data });
    } catch (error) {
      dispatch({ type: 'FETCH_PAIS_FAILURE', payload: error.message });
    }
  };
*/
// Acción para crear un nuevo país
export const createPais = (paisData) => async (dispatch) => {
    dispatch({ type: 'CREATE_PAIS_REQUEST' });
    try {
      const response = await paisService.createPais(paisData);
      dispatch({ type: 'CREATE_PAIS_SUCCESS', payload: response.data });
      return response.data;  // Opcionalmente retorna los datos para uso después de la llamada
    } catch (error) {
      dispatch({ type: 'CREATE_PAIS_FAILURE', payload: error.message });
      return Promise.reject(error.message);  // Para manejo de error en componente
    }
  };
  
  // Acción para actualizar un país existente
  export const updatePais = (id, paisData) => async (dispatch) => {
    dispatch({ type: 'UPDATE_PAIS_REQUEST' });
    try {
      const response = await paisService.updatePais(id, paisData);
      dispatch({ type: 'UPDATE_PAIS_SUCCESS', payload: response.data });
      return response.data;  // Opcionalmente retorna los datos para uso después de la llamada
    } catch (error) {
      dispatch({ type: 'UPDATE_PAIS_FAILURE', payload: error.message });
      return Promise.reject(error.message);  // Para manejo de error en componente
    }
  };
  
  // Acción para eliminar un país
 /* export const deletePais = (id) => async (dispatch) => {
    dispatch({ type: 'DELETE_PAIS_REQUEST' });
    try {
      await paisService.deletePais(id);
      dispatch({ type: 'DELETE_PAIS_SUCCESS', payload: id });
    } catch (error) {
      dispatch({ type: 'DELETE_PAIS_FAILURE', payload: error.message });
      return Promise.reject(error.message);  // Para manejo de error en componente
    }
  };*/

// src/actions/paisActions.js


export const deletePais = (id) => async (dispatch) => {
  try {
    // Usa deletePais desde paisService en lugar de axios directamente
    const response = await paisService.deletePais(id);
    dispatch({ type: 'DELETE_PAIS_SUCCESS', payload: id });
    toast.success('País eliminado con éxito.');
  } catch (error) {
    // Asegúrate de manejar correctamente la respuesta del error
    const errorMessage = error.response ? error.response.data.message : error.message;
    dispatch({ type: 'DELETE_PAIS_FAILURE', payload: errorMessage });
    toast.error(`Error al eliminar el país `);
    return Promise.reject(errorMessage); // Propaga el mensaje de error
  }
};

export const clearErrors = () => {
  return {
    type: 'CLEAR_ERRORS'
  };
};
