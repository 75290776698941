import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { UserContext } from "../store/user-context";

const LoginForm = () => {
  const { setUser } = useContext(UserContext);
  const [formData, setFormData] = useState({ usuario: '', clave: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

const handleSubmit = async (e) => {
  e.preventDefault();
  setIsSubmitting(true);
  setError(null);

  //console.log('Datos enviados:', formData);

  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    });

    //console.log('Respuesta del servidor:', response);

    let resData;
    try {
      resData = await response.json();
    } catch (error) {
      // Handle non-JSON response
      const text = await response.text();
      throw new Error(text || 'Error desconocido');
    }

    if (response.status === 422 || response.status === 401 || response.status === 400) {
      setError(resData.message || 'Usuario o contraseña inválidos, intente de nuevo');
      setIsSubmitting(false);
      return;
    }

    if (!response.ok) {
      setError('Algo salió mal...');
      setIsSubmitting(false);
      return;
    }

    const { token, roles, user, id, foto } = resData;

    localStorage.setItem('token', token);

    const expiration = new Date();
    expiration.setHours(expiration.getHours() + 1);
    localStorage.setItem('expiration', expiration.toISOString());

    // Actualizar el contexto del usuario
    const timestamps = new Date().getTime();
    const UPLOADS_BASE_URL = process.env.REACT_APP_UPLOADS_BASE_URL;

    const fotoUrl = foto ? `${UPLOADS_BASE_URL}/${foto}?token=${token}&timestamp=${timestamps}` : null;
    setUser(id, user, roles, fotoUrl);

    navigate('/');
  } catch (error) {
    setError('!!!!!  Usuario o clave inválidas, intente de nuevo');
    setIsSubmitting(false);
  }
};
  return (
 <div className="list0-container container-list">
    <Form method="post" onSubmit={handleSubmit}>
        <h1>Inicio de sesión</h1>
        {error && <p>{error}</p>}
        <div className="mb-3">
          <label htmlFor="usuario" className="form-label">
            Usuario
          </label>
          <input
            type="text"
            className="form-control"
            id="usuario"
            name="usuario"
            value={formData.usuario}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="clave" className="form-label">
            Clave
          </label>
          <input
            type="password"
            className="form-control"
            id="clave"
            name="clave"
            value={formData.clave}
            onChange={handleChange}
            required
          />
        </div>
        <button disabled={isSubmitting} className="button btn-accent float-end">
          {isSubmitting ? "Iniciando..." : "Iniciar sesión"}
        </button>
      </Form>
    </div>
  );
};

export default LoginForm;
