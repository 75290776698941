
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers'; // Asegúrate de que esto importa todos tus reducers combinados correctamente
import App from './App';
import './index.css'
// Crear el store usando Redux Toolkit
const store = configureStore({
  reducer: rootReducer
  // Redux Toolkit incluye Redux Thunk por defecto, por lo que no necesitas añadirlo aquí
});

const container = document.getElementById('root');
const root = createRoot(container); // Crea una raíz.
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
