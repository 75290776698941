// src/components/CargoForm.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCargo, updateCargo, fetchCargo } from '../actions/cargoActions';
import { Modal, Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

const CargoForm = ({ show, handleClose, isEdit, selectedId }) => {
    const dispatch = useDispatch();
    const { currentCargo, error } = useSelector(state => state.cargo);
    const [cargoData, setCargoData] = useState({ cargo: '', presidente: false, estado: 'ACTIVO' });

    useEffect(() => {
        if (isEdit && selectedId) {
            dispatch(fetchCargo(selectedId));
        }
    }, [dispatch, isEdit, selectedId]);

    useEffect(() => {
        if (isEdit && currentCargo && currentCargo.id === selectedId) {
            setCargoData({
                cargo: currentCargo.cargo,
                presidente: currentCargo.presidente,
                estado: currentCargo.estado === 1 ? 'ACTIVO' : 'INACTIVO'
            });
        }
    }, [isEdit, currentCargo, selectedId]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setCargoData({
            ...cargoData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const dataToSubmit = {
            ...cargoData,
            estado: cargoData.estado === 'ACTIVO' ? 1 : 0
        };
        if (isEdit) {
            if (!cargoData.cargo || !cargoData.estado ) {
                toast.error("Por favor complete los campos requeridos: cargo, estado");
                return;
            }
            dispatch(updateCargo(selectedId, dataToSubmit))
                .then(() => {
                    toast.success('Cargo actualizado correctamente');
                    handleClose();
                })
                .catch(error => {
                    toast.error(`Error al actualizar el cargo: ${error.message || error}`);
                });
        } else {
            if (!cargoData.cargo || !cargoData.estado ) {
                toast.error("Por favor complete los campos requeridos: cargo, estado");
                return;
            }
            dispatch(createCargo(dataToSubmit))
                .then(() => {
                    toast.success('Cargo creado correctamente');
                    handleClose();
                })
                .catch(error => {
                    toast.error(`Error al crear el cargo: ${error.message || error}`);
                });
        }
    };

    const handleNameChange = (e) => {
        const newNombre = e.target.value.toUpperCase(); // Convertir a mayúsculas
        setCargoData({ ...cargoData, cargo: newNombre });
    };

    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="md">
            <Modal.Header closeButton>
                <Modal.Title>{isEdit ? 'Editar Cargo' : 'Crear Cargo'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Form.Group controlId="cargo">
                            <Form.Label>Nombre del Cargo</Form.Label>
                            <Form.Control
                                type="text"
                                name="cargo"
                                value={cargoData.cargo}
                                onChange={handleNameChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="presidente">
                            <Form.Check
                                type="checkbox"
                                name="presidente"
                                label="Presidente"
                                checked={cargoData.presidente}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="estado">
                            <Form.Label>Estado</Form.Label>
                            <Form.Control
                                as="select"
                                name="estado"
                                value={cargoData.estado}
                                onChange={handleChange}
                                required
                            >
                                <option value="ACTIVO">ACTIVO</option>
                                <option value="INACTIVO">INACTIVO</option>
                            </Form.Control>
                        </Form.Group>
                        <div className='button-group'>
                            <button className="button btn-cancel" type="button" onClick={handleClose}>
                            Cancelar
                            </button>
                            <button className="button btn-accent"  type="submit">
                                <i className="bi bi-save"></i> Guardar
                            </button>
                        </div>
                    </form>
            </Modal.Body>
        </Modal>
    );
};

export default CargoForm;
