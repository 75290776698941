import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSocios } from '../actions/socioActions';
import { fetchCargos } from '../actions/cargoActions';
import { fetchDirectivaSocios, deleteDirectivaSocios, saveDirectivaSocios } from '../actions/direcsocActions';
import { Modal, Button, ListGroup, Form, InputGroup, FormControl } from 'react-bootstrap';
import { toast } from 'react-toastify';

const DirectivaSocio = ({ show, handleClose, directiva }) => {
    const dispatch = useDispatch();
    const { socios, loading, error } = useSelector(state => state.socio);
    const { cargos } = useSelector(state => state.cargo);
    const { directivaSocios } = useSelector(state => state.direcsoc);
    const [selectedSocios, setSelectedSocios] = useState([]);
    const [availableSocios, setAvailableSocios] = useState([]);
    const [socioCargos, setSocioCargos] = useState({});
    const [visibleCount, setVisibleCount] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        dispatch(fetchSocios());
        dispatch(fetchCargos());
        dispatch(fetchDirectivaSocios(directiva.id));
    }, [dispatch, directiva.id]);

    useEffect(() => {
        if (socios) {
            setAvailableSocios(socios);
        }
    }, [socios]);

    useEffect(() => {
        if (Array.isArray(directivaSocios) && Array.isArray(socios)) {
            const selected = directivaSocios.map(direcSoc => {
                const socio = socios.find(s => s.id === direcSoc.idcodreg);
                return {
                    ...direcSoc,
                    ...socio,
                    selected: true
                };
            });
            setSelectedSocios(selected);

            const available = socios.filter(socio => !directivaSocios.some(ds => ds.idcodreg === socio.id));
            setAvailableSocios(available);

            const cargos = {};
            directivaSocios.forEach(socio => {
                cargos[socio.idcodreg] = socio.cargo;
            });
            setSocioCargos(cargos);
        }
    }, [directivaSocios, socios]);

    const handleAddSocios = () => {
        const selected = availableSocios.filter(socio => socio.selected);
        setSelectedSocios([...selectedSocios, ...selected]);
        setAvailableSocios(availableSocios.filter(socio => !socio.selected));
    };

    const handleRemoveSocios = () => {
        const removed = selectedSocios.filter(socio => socio.selected);
        setAvailableSocios([...availableSocios, ...removed]);
        setSelectedSocios(selectedSocios.filter(socio => !socio.selected));
    };

    const handleCheckboxChange = (e, socio, type) => {
        const updatedList = type === 'available'
            ? availableSocios.map(s => s.id === socio.id ? { ...s, selected: e.target.checked } : s)
            : selectedSocios.map(s => s.id === socio.id ? { ...s, selected: e.target.checked } : s);

        type === 'available' ? setAvailableSocios(updatedList) : setSelectedSocios(updatedList);
    };

    const handleCargoChange = (socioId, cargoId) => {
        setSocioCargos({
            ...socioCargos,
            [socioId]: cargoId
        });
    };

    const handleSave = async () => {
        try {
            await dispatch(deleteDirectivaSocios(directiva.id));
            for (const socio of selectedSocios) {
                const socioData = {
                    directiva: directiva.id,
                    idcodreg: socio.id,
                    feini: directiva.feposes,
                    fefin: directiva.fefinal,
                    cargo: socioCargos[socio.id]
                };
                await dispatch(saveDirectivaSocios(socioData));
            }
            toast.success('Socios actualizados correctamente');
            handleClose();
        } catch (error) {
            toast.error(`Error al guardar los socios: ${error.message}`);
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredSocios = availableSocios.filter(socio =>
        socio.apPat.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (loading) return <p>Cargando socios...</p>;
    if (error) return <p>Error al cargar los datos: {error}</p>;

    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Gestionar Socios de la Directiva</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row d-flex">
                    
                    <div className="col-md-6 mt-2">
                        <h5>Socios Disponibles</h5>
                        <InputGroup className="mb-3">
                            <FormControl
                                placeholder="Buscar por apellido"
                                aria-label="Buscar por apellido"
                                aria-describedby="basic-addon2"
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                        </InputGroup>
                        <Form.Group controlId="visibleCount">
                            <Form.Label>Número de elementos visibles</Form.Label>
                            <Form.Control
                                type="number"
                                value={visibleCount}
                                onChange={(e) => setVisibleCount(Number(e.target.value))}
                                min={1}
                            />
                        </Form.Group>
                        <ListGroup style={{ maxHeight: '400px', overflowY: 'auto' }}>
                            {filteredSocios.slice(0, visibleCount).map(socio => (
                                <ListGroup.Item key={socio.id}>
                                    <Form.Check
                                        type="checkbox"
                                        label={`${socio.codreg} - ${socio.nombres} ${socio.apPat} ${socio.apMat}`}
                                        checked={socio.selected || false}
                                        onChange={(e) => handleCheckboxChange(e, socio, 'available')}
                                    />
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                        <button className="btn btn-outline-primary mt-2" onClick={handleAddSocios}><i className="bi bi-plus"></i> Agregar</button>
                    </div>
                    <div className="col-md-6">
                        <h5>Socios en la Directiva</h5>
                        <ListGroup style={{ maxHeight: '400px', overflowY: 'auto' }}>
                            {selectedSocios.map(socio => (
                                <ListGroup.Item key={socio.id}>
                                    <Form.Check
                                        type="checkbox"
                                        label={`${socio.codreg} - ${socio.nombres} ${socio.apPat} ${socio.apMat}`}
                                        checked={socio.selected || false}
                                        onChange={(e) => handleCheckboxChange(e, socio, 'selected')}
                                    />
                                    <Form.Control
                                        as="select"
                                        value={socioCargos[socio.id] || ''}
                                        onChange={(e) => handleCargoChange(socio.id, e.target.value)}
                                    >
                                        <option value="">Seleccionar Cargo</option>
                                        {cargos && cargos.map(cargo => (
                                            <option key={cargo.id} value={cargo.id}>{cargo.cargo}</option>
                                        ))}
                                    </Form.Control>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                        <Button variant="outline-danger" className="mt-2 mb-2" onClick={handleRemoveSocios}><i className="bi bi-dash"></i> Quitar</Button>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className='button-group'>
                <button className="button btn-cancel" onClick={handleClose}>Cancelar</button>
                <button className="button btn-accent" onClick={handleSave}>Grabar</button>
            </Modal.Footer>
        </Modal>
    );
};

export default DirectivaSocio;
