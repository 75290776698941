import {
    FETCH_NOTICIAS_REQUEST,
    FETCH_NOTICIAS_SUCCESS,
    FETCH_NOTICIAS_FAILURE,
    FETCH_NOTICIA_REQUEST,
    FETCH_NOTICIA_SUCCESS,
    FETCH_NOTICIA_FAILURE,
    CREATE_NOTICIA_REQUEST,
    CREATE_NOTICIA_SUCCESS,
    CREATE_NOTICIA_FAILURE,
    UPDATE_NOTICIA_REQUEST,
    UPDATE_NOTICIA_SUCCESS,
    UPDATE_NOTICIA_FAILURE,
    DELETE_NOTICIA_REQUEST,
    DELETE_NOTICIA_SUCCESS,
    DELETE_NOTICIA_FAILURE
} from '../actions/noticiaActions';

const initialState = {
    noticias: [],
    currentNoticia: null,
    loading: false,
    error: null,
    success: false
};

const noticiaReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_NOTICIAS_REQUEST:
        case FETCH_NOTICIA_REQUEST:
        case CREATE_NOTICIA_REQUEST:
        case UPDATE_NOTICIA_REQUEST:
        case DELETE_NOTICIA_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                success: false
            };
        case FETCH_NOTICIAS_SUCCESS:
            return {
                ...state,
                loading: false,
                noticias: action.payload,
                success: true
            };
        case FETCH_NOTICIA_SUCCESS:
            return {
                ...state,
                loading: false,
                currentNoticia: action.payload,
                success: true
            };
        case CREATE_NOTICIA_SUCCESS:
            return {
                ...state,
                loading: false,
                noticias: [...state.noticias, action.payload],
                success: true,
                error: null
            };
        case UPDATE_NOTICIA_SUCCESS:
            return {
                ...state,
                loading: false,
                noticias: state.noticias.map(noticia =>
                    noticia.id === action.payload.id ? action.payload : noticia
                ),
                success: true,
                error: null
            };
        case DELETE_NOTICIA_SUCCESS:
            return {
                ...state,
                loading: false,
                noticias: state.noticias.filter(noticia => noticia.id !== action.payload),
                success: true,
                error: null
            };
        case FETCH_NOTICIAS_FAILURE:
        case FETCH_NOTICIA_FAILURE:
        case CREATE_NOTICIA_FAILURE:
        case UPDATE_NOTICIA_FAILURE:
        case DELETE_NOTICIA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false
            };
        default:
            return state;
    }
};

export default noticiaReducer;
