import { combineReducers } from 'redux';
import paisReducer from './paisReducer';
import ciudadReducer from './ciudadReducer';
import universidadReducer from './universidadReducer';
import socioReducer from './socioReducer';
import titulosReducer from './titulosReducer';
import gradoReducer from './gradoReducer';
import cuotaReducer from './cuotaReducer';
import reciboReducer from './reciboReducer';
import directivaReducer from './directivaReducer';
import cargoReducer from './cargoReducer';
import direcsocReducer from './direcsocReducer';
import datgenReducer from './datgenReducer';
import usuarioReducer from './usuarioReducer';
import rolReducer from './rolReducer';
import usuarolReducer from './usuarolReducer';
import noticiaReducer from './noticiaReducer';

export default combineReducers({
  pais: paisReducer,
  ciudad: ciudadReducer,
  universidad: universidadReducer,
  socio:socioReducer,
  titulo:titulosReducer,
  grado:gradoReducer,
  cuota: cuotaReducer,
  recibo:reciboReducer,
  directiva:directivaReducer,
  cargo:cargoReducer,
  direcsoc:direcsocReducer,
  datgen:datgenReducer,
  usuario:usuarioReducer,
  rol:rolReducer,
  usuarol:usuarolReducer,
  noticia:noticiaReducer
});