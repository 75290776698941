import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./SocioForm.css";
import { toast } from "react-toastify";
import {
  createSocio,
  fetchFotoUrl,
  updateSocio,
  deleteSocio
} from "../actions/socioActions";
import { fetchCiudades } from "../actions/ciudadActions";
import { Modal, Button, Form, Image } from "react-bootstrap";
import placeholder from "../assets/user-profile-photo.png";
import { useInputFile } from "../hooks/useInputImage";
import { getUploadUrl } from "../util/utilFunctions";

const SocioForm = ({ showModal, closeModal, socio, newCodreg }) => {
  const dispatch = useDispatch();
  const ciudades = useSelector((state) => state.ciudad.ciudades);
  const deleteStatus = useSelector((state) => state.socio.status);

  const [socioData, setSocioData] = useState({
    ...socio,
    fenac: socio.fenac ? new Date(socio.fenac) : new Date(),
    fereg: socio.fereg ? new Date(socio.fereg) : new Date(),
    estado: socio.estado !== undefined ? socio.estado : 1,
    foto: socio.foto || "",
    suniv: socio.suniv || ""
  });
  const {
    file: foto1,
    handleFileChange: handleFoto1Change,
    resetInputFile: resetFoto1,
    isValid: isFoto1Valid
  } = useInputFile();
  const [isSending, setIsSending] = useState(false)

  useEffect(() => {
    dispatch(fetchCiudades());
  }, [dispatch]);


  useEffect(() => {
    if (socio) {
      setSocioData({
        ...socio,
        fenac: socio.fenac ? new Date(socio.fenac) : new Date(),
        fereg: socio.fereg ? new Date(socio.fereg) : new Date(),
        foto: socio.foto || null,
        suniv: socio.suniv || ""
      });
    }
  }, [socio, dispatch]);
  //console.log("Socio data", socioData)
  useEffect(() => {
    if (!showModal) {
      setSocioData({
        codreg: "",
        apPat: "",
        apMat: "",
        nombres: "",
        fenac: new Date(),
        fereg: new Date(),
        ci: "",
        genero: "",
        estado: 1,
        codciu: "1",
        email: "",
        nrotit: "",
        celular: "",
        foto: null,
        suniv: ""
      });
      resetFoto1()
    }
  }, [showModal]);

  useEffect(() => {
    if (deleteStatus === 'success') {
      toast.success("Socio eliminado correctamente.");
      closeModal(true);
    } else if (deleteStatus === 'failure') {
      toast.error("No se puede dar de baja a un socio, cuando tiene dependencias... puede ponerle su estado en inactivo...");
    }
  }, [deleteStatus, closeModal]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSocioData((prev) => ({
      ...prev,
      [name]: name === "email" ? value : value.toUpperCase(),
    }));
  };

  const handleDateChange = (name, date) => {
    setSocioData((prev) => ({ ...prev, [name]: date }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!socioData.codreg || !socioData.apPat || !socioData.nombres) {
      toast.error(
        "Por favor complete los campos requeridos: Código de Registro, Apellido Paterno, y Nombres."
      );
      return;
    }

    const formData = new FormData();
    Object.keys(socioData).forEach((key) => {
      if (key !== "createdAt" && key !== "updatedAt") {
        if (key === "foto" && socioData[key]) {
          formData.append(key, socioData[key]);
        } else if (key === "fenac" || key === "fereg") {
          const newDate = new Date(socioData[key]);
          formData.append(key, newDate.toISOString());
        } else {
          formData.append(key, socioData[key]);
        }
      }
    });
    const codregValue = (parseInt(socioData.codreg))
    .toString()
    .padStart(5, "0");
    formData.set('codreg', codregValue)
    if(foto1){
      formData.set("foto", foto1);
    }else if(socio.foto) {formData.set("foto", socio.foto);}
  
    setIsSending(true)
    if (socio.id) {
      dispatch(updateSocio(socio.id, formData)).then(data => {
        setIsSending(false)
        closeModal(true);
      });
    } else {
      dispatch(createSocio(formData)).then(data => {
        setIsSending(false)
        resetFoto1()
        closeModal(true);
      });
    
    }
  };

  const actualFoto1Url = socio && socio.foto ? getUploadUrl(socio.foto) : null;
   
  return (
    <Modal
      show={showModal}
      onHide={() => closeModal(false)}
      backdrop="static"
      keyboard={false}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>Formulario de Socios</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          autoComplete="off"
          className="formulario"
          noValidate
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <div className="row align-items-start">
            <div className="col-md-4">
                <label htmlFor="codreg" >
                  Nro. de Registro
                </label>
                {"  "}
                  <input
                    id="codreg"
                    type="text"
                    name="codreg"
                    value={socioData.codreg || ""}
                    onChange={handleChange}
                    disabled={!!socio.id}
                  />
              </div>
              <div className="col-md-4">
                <label htmlFor="fecharegistro">
                  Fecha de Registro
                </label>
                <br/>
                  <DatePicker
                    id="fecharegistro"
                    selected={socioData.fereg}
                    onChange={(date) => handleDateChange("fereg", date)}
                    showYearDropdown
                    scrollableYearDropdown
                    minDate={new Date(1900, 0, 1)}
                    maxDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/MM/yyyy"
                    customInput={<input type="text" />}
                  />
            </div>
            <div className="col-md-4">
            {actualFoto1Url && (
                      <>
                      <Form.Label>Foto actual</Form.Label>
                      <br/>
                      <Image 
                          style={{ width: "170px", height: "170px" }}
                          src={actualFoto1Url}
                      />
                      </>
                  )}
            </div>
            </div>
          
          <div className="row g-3">
            <div className="col-md-4">
              <label className="form-label">Apellido Paterno:</label>
              <input
                type="text"
                name="apPat"
                value={socioData.apPat}
                onChange={handleChange}
                className="w-100 form-control"
                required
              />
            </div>
            <div className="col-md-4">
              <label className="form-label">Apellido Materno:</label>
              <input
                type="text"
                name="apMat"
                value={socioData.apMat}
                onChange={handleChange}
                className="w-100 form-control"
                required
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="nombres" className="form-label">
                Nombres:
              </label>
              <input
                id="nombres"
                type="text"
                name="nombres"
                value={socioData.nombres}
                onChange={handleChange}
                className="w-100 form-control"
                required
              />
            </div>
          </div>
          <div className="row g-3">
            <div className="col-md-4">
              <label htmlFor="fechanacimiento" className="form-label">
                Fecha de Nacimiento:
              </label>
              <DatePicker
                id="fechanacimiento"
                selected={socioData.fenac ? socioData.fenac : ""}
                onChange={(date) => handleDateChange("fenac", date)}
                className="w-100 form-control"
                showYearDropdown
                scrollableYearDropdown
                minDate={new Date(1900, 0, 1)}
                maxDate={new Date()}
                dateFormat="dd/MM/yyyy"
                placeholderText="dd/MM/yyyy"
                customInput={<input type="text" />}
              />
            </div>
            <div className="col-md-4">
              <label>N° Cédula de identidad:</label>
              <input
                type="text"
                name="ci"
                value={socioData.ci || ""}
                onChange={handleChange}
                className="w-100 form-control"
                required
              />
            </div>
            <div className="col-md-4">
              <label>Género:</label>
              <select
                name="genero"
                value={socioData.genero || ""}
                onChange={handleChange}
                className="w-100 form-control"
              >
                <option value="" disabled>Seleccionar género</option>
                <option value="M">Masculino</option>
                <option value="F">Femenino</option>
              </select>
            </div>
          </div>
          <div className="row g-3">
            <div className="col-md-4">
              <label>Email:</label>
              <input
                type="email"
                name="email"
                value={socioData.email || ""}
                onChange={handleChange}
                className="w-100 form-control"
              />
            </div>
            <div className="col-md-4">
              <label>CodTit:</label>
              <input
                type="text"
                name="nrotit"
                value={socioData.nrotit || ""}
                onChange={handleChange}
                className="w-100 form-control"
              />
            </div>
            <div className="col-md-4">
              <label>Celular:</label>
              <input
                type="text"
                name="celular"
                value={socioData.celular || ""}
                onChange={handleChange}
                className="w-100 form-control"
              />
            </div>
            <div className="col-md-4">
              <label>Ciudad de origen:</label>
              <select
                name="codciu"
                value={socioData.codciu || ""}
                onChange={handleChange}
                className="w-100 form-control"
              >
                <option key="default" value="" disabled>
                   Seleccionar ciudad
                  </option>
                {ciudades.map((ciudad) => (
                  <option key={ciudad.id} value={ciudad.id}>
                    {ciudad.nombre}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-4">
              <label>Universidad (sigla):</label>
              <input
                type="text"
                name="suniv"
                value={socioData.suniv || ""}
                onChange={handleChange}
                className="w-100 form-control"
              />
            </div>
          </div>
          <div className="row align-items-start">
            <div className="col-md-8 mb-3">
                <label htmlFor="foto">
                  Foto:
                </label>
                   <Form.Control
                    type="file"
                    accept="image/*"
                    className="mt-3"
                    isInvalid={!isFoto1Valid}
                    onChange={(e) => handleFoto1Change(e.target.files[0])}
                    disabled={isSending}
                  />
                   <Form.Control.Feedback type="invalid">
                            Por favor selecciona una imagen válida.
                    </Form.Control.Feedback>
                    
                    </div>
                    
                {(foto1 && isFoto1Valid) && (
                  <div className="col-md-4">
                  <label htmlFor="foto">
                        Nueva foto
                      </label>
                      <br/>
                              <img
                              style={{ width: "170px", height: "180px" }}
                              src={URL.createObjectURL(foto1)}
                              />
                        
                </div>
                 )}
              </div>
          <div className="button-container">
            <button
              type="button"
              className="button btn-cancel m-2"
              onClick={() => closeModal(false)}
            >
              Cancelar
            </button>
            <button type="submit" className="button btn-accent m-2">
              Guardar
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default SocioForm;
