import {
    FETCH_CIUDADES_SUCCESS,
    FETCH_CIUDADES_FAILURE,
    CREATE_CIUDAD_SUCCESS,
    CREATE_CIUDAD_FAILURE,
    UPDATE_CIUDAD_SUCCESS,
    UPDATE_CIUDAD_FAILURE,
    DELETE_CIUDAD_SUCCESS,
    DELETE_CIUDAD_FAILURE
} from '../actions/ciudadActions';

const initialState = {
    ciudades: [],
    error: null
};

const ciudadReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CIUDADES_SUCCESS:
            return {
                ...state,
                ciudades: action.payload,
                error: null
            };
        case FETCH_CIUDADES_FAILURE:
        case CREATE_CIUDAD_FAILURE:
        case UPDATE_CIUDAD_FAILURE:

        case DELETE_CIUDAD_FAILURE:
            return {
                ...state,
                error: action.payload
            };

            
        case CREATE_CIUDAD_SUCCESS:
            return {
                ...state,
                ciudades: [...state.ciudades, action.payload],
                error: null
            };
        case UPDATE_CIUDAD_SUCCESS:
            return {
                ...state,
                ciudades: state.ciudades.map(ciudad =>
                    ciudad.id === action.payload.id ? action.payload : ciudad
                ),
                error: null
            };
        case DELETE_CIUDAD_SUCCESS:
            return {
                ...state,
                ciudades: state.ciudades.filter(ciudad => ciudad.id !== action.payload),
                error: null
            };
        default:
            return state;
    }
};

export default ciudadReducer;