import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../store/user-context';
import { FaExclamationTriangle } from 'react-icons/fa';
import { fetchDatgens, updateDatgen } from '../actions/datgenActions';

const ActualizarMontosCuotas = () => {
    const { userState } = useContext(UserContext);
    const { user, roles } = userState;

    const [montoInicial, setMontoInicial] = useState('');
    const [montoAnual, setMontoAnual] = useState('');
    const [observaciones, setObservaciones] = useState('');
    const [idg, setIdg] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const datgenState = useSelector((state) => state.datgen);
    const { loading, success, error, datgens } = datgenState || {};

    useEffect(() => {
        dispatch(fetchDatgens());
    }, [dispatch]);

    useEffect(() => {
        if (datgens && datgens.length > 0) {
            setMontoInicial(datgens[0].dato11);
            setMontoAnual(datgens[0].dato12);
            setObservaciones(datgens[0].dato10);
            setIdg(datgens[0].id);
        }
    }, [datgens]);

    useEffect(() => {
        if (success) {
            toast.success("La actualización ha sido un éxito...");
        } else if (error) {
            toast.error(`Error al actualizar montos: ${error}`);
        }
    }, [success, error]);

    const handleMontoInicialChange = (e) => {
        setMontoInicial(e.target.value);
    };

    const handleMontoAnualChange = (e) => {
        setMontoAnual(e.target.value);
    };

    const handleObservacionesChange = (e) => {
        setObservaciones(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const initial = parseFloat(montoInicial);
        const annual = parseFloat(montoAnual);

        if (isNaN(initial) || isNaN(annual) || initial < 0 || annual < 0) {
            toast.error("Completa los datos por favor: cuota Inicial y Cuota Mensual, vuelve a intentarlo..");
            return;
        }
        const fechaActual = new Date().toLocaleDateString(); // Obtén la fecha actual
        if (window.confirm(`${user} ${roles} ¿Estás seguro de que deseas actualizar los montos de las cuotas?`)) {
            const data = {
                dato11: initial,
                dato12: annual,
                dato10: observaciones+"  Usuario: "+user+"  Fecha: "+fechaActual
            };
            const formData = new FormData();
            for (const key in data) {
                formData.append(key, data[key]);}
            dispatch(updateDatgen(idg, formData));
        }
    };

    return (
        <div className="list4-container container-list">
            <form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <FaExclamationTriangle style={{ color: 'red', marginRight: '5px', fontSize: '3em' }} />
                    <span style={{ fontSize: '1.5em', color: 'darkgreen', fontWeight: 'bold', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)' }}>Alerta:</span>
                </div>
                <p style={{ marginBottom: '20px' }}>
                    La actualización de los montos de las cuotas es un proceso importante. Asegúrese de que los valores sean correctos antes de proceder.
                </p>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                    <div>
                        <label>Montos Actuales:</label>
                        <p>Monto Inicial: {montoInicial}</p>
                        <p>Monto Anual: {montoAnual}</p>
                    </div>
                    <div>
                        <label>Montos Nuevos:</label>
                        <input
                            type="number"
                            placeholder="Nuevo Monto Inicial"
                            value={montoInicial}
                            onChange={handleMontoInicialChange}
                            className="form-control"
                            required
                        />
                        <input
                            type="number"
                            placeholder="Nuevo Monto Mensual"
                            value={montoAnual}
                            onChange={handleMontoAnualChange}
                            className="form-control"
                            required
                        />
                    </div>
                </div>
                <label>Observaciones</label>
                <textarea
                    value={observaciones}
                    onChange={handleObservacionesChange}
                    className="form-control"
                    required
                />
                <div className='button-group'>
                    <button className="button btn-cancel" onClick={() => navigate('/')} style={{ marginLeft: '10px' }}>
                    Cancelar
                    </button>
                    <button className="button btn-accent" type="submit" disabled={loading}>
                        {loading ? 'Actualizando...' : 'Actualizar Montos'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ActualizarMontosCuotas;
