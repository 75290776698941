import { redirect } from "react-router-dom";

export function getTokenDuration() {
    const storedExpirationDate = localStorage.getItem('expiration');
    const expirationDate = new Date(storedExpirationDate);
    const now = new Date();
    const duration = expirationDate.getTime() - now.getTime();
    return duration;
}
export function getAuthToken() {
    const token = localStorage.getItem('token');
    const tokenDuration = getTokenDuration();
    
    if(!token) {
        return null;
    }

    if(tokenDuration < 0 ) {
        return 'EXPIRED';
    }
    return token;
}

export function tokenLoader() {
    return getAuthToken();
}

export function checkAuthLoader() {
    const token = getAuthToken();
  
    if(!token) {
        return redirect('/login')
    }
    return null;
}


export function checkAdminLoader() {
    const storedRoles = localStorage.getItem('roles');
    const roles = storedRoles.split(',');
    
    if(!roles) {
        return redirect('/login')
    }
    if(roles[0] !== 'gerente' && roles[0] !== 'admin' && roles[0] !== 'ADMIN2' && roles[0] !== 'ADMIN3' && roles[0] !== 'ADMIN4' ){
        return redirect('/')
    }

   
    return null;
}
export function actionLogout() {
    localStorage.removeItem('token');
    localStorage.removeItem('roles');
    localStorage.removeItem('expiration');
    localStorage.removeItem('user');
    localStorage.removeItem('id');
    localStorage.removeItem('foto');
    
    return redirect('/');
}
