import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import CustomPrintModal2 from './CustomPrintModal2.js';
import { formatNumberToLiteral } from './utils';
import './print.css';

const ReciboModal = ({ show, handleClose, socio, cuotas, reciboNumero, reciboFecha, copia }) => {


    // Define state hooks unconditionally
  ////console.log("Cuotas en modal=",cuotas)
  ////console.log("ReciboFecha=",reciboFecha)
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });

  if (!socio) return null;

  const totalMontoPagado = cuotas.reduce((total, cuota) => total + cuota.montopagado, 0);
  const totalMontoPagadoLiteral = formatNumberToLiteral(totalMontoPagado);

  const handlePrint = () => {
    const content = {
      head: [["Gestión", "Fecha de Emisión", "Monto", "Fecha de Pago", "Monto Pagado", "Observaciones"]],
      body: cuotas.map(cuota => [
        cuota.gestion,
        cuota.feemis ? new Date(cuota.feemis).toLocaleDateString() : '',
        cuota.monto.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        cuota.fepago ? new Date(cuota.fepago).toLocaleDateString() : '',
        cuota.montopagado.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        cuota.observ || ''
      ])
    };
    setPrintContent(content);
    setShowPrintModal(true);
  };

  const formatCurrency = (number) => {
    return number.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true });
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg" backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Recibo de Pago</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="printableArea">
            <div className="recibo-header">
              <h5>COLEGIO DE INGENIEROS AGRONOMOS TARIJA</h5>
              <p>RECIBO NRO. {reciboNumero}</p>
              <p>Fecha: {new Date(reciboFecha).toLocaleDateString()}</p>
              <p>Registro Nro.: {socio.codreg} - Socio: {socio.nombres} {socio.apPat} {socio.apMat}</p>
            </div>
            <div className="recibo-detalle" style={{ width: '100%' }}>
              <table className="print-table">
                <thead>
                  <tr>
                    <th className="print-th">Gestión</th>
                    <th className="print-th">Fecha de Emisión</th>
                    <th className="print-th">Monto</th>
                    <th className="print-th">Fecha de Pago</th>
                    <th className="print-th">Monto Pagado</th>
                    <th className="print-th observaciones-col">Observaciones</th>
                  </tr>
                </thead>
                <tbody>
                  {cuotas.map((cuota, index) => (
                    <tr key={index}>
                      <td className="print-td">{cuota.gestion}</td>
                      <td className="print-td">{cuota.feemis ? new Date(cuota.feemis).toLocaleDateString() : ''}</td>
                      <td className="print-td print-td-right">{formatCurrency(cuota.monto)}</td>
                      <td className="print-td">{cuota.fepago ? new Date(cuota.fepago).toLocaleDateString() : ''}</td>
                      <td className="print-td print-td-right">{formatCurrency(cuota.montopagado)}</td>
                      <td className="print-td">{cuota.observ}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="recibo-pie">
              <p>Total Monto Pagado: {formatCurrency(totalMontoPagado)} Bs.</p>
              <p>Total en Literal: {totalMontoPagadoLiteral}</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Volver
          </Button>
          <Button variant="primary" onClick={handlePrint}>
            Imprimir
          </Button>
        </Modal.Footer>
      </Modal>

      <CustomPrintModal2
        show={showPrintModal}
        handleClose={() => setShowPrintModal(false)}
        title1={`RECIBO NRO. ${reciboNumero}`}
        title2={`Fecha: ${new Date(reciboFecha).toLocaleDateString()}`}
        title3={`Registro Nro.: ${socio.codreg}`}
        title4={`Socio: ${socio.nombres} ${socio.apPat} ${socio.apMat}`}
        total1={`Total Monto Pagado: ${formatCurrency(totalMontoPagado)} Bs.`}
        total2={`Total en Literal: ${totalMontoPagadoLiteral}`}
        content={printContent}
      />
    </>
  );
};

export default ReciboModal;
