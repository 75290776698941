import createAxiosInstance from "../util/axiosInstance";



const api = createAxiosInstance('/cuota');


const fetchCuotasPendientes = async (ci, fecha) => {
    console.log("En service de pendientes...",ci,fecha)
    const response = await api.get(`/pendientes/${ci}/${fecha}`);
    return response.data;
};


const fetchCuotasBySocio = (socioId) => {
    return api.get(`/socio/${socioId}`);
};

const updateCuota = (id, cuotaData) => {
    return api.put(`/${id}`, cuotaData);
};

const fetchCuotasByRecibo = (idRecibo) => {
    return api.get(`/recibo/${idRecibo}`);
};

const generarCuotas = (fecha) => {
    return api.post(`/generar/${fecha}`);
};
export default {
    fetchCuotasBySocio,
    updateCuota,
    fetchCuotasByRecibo,
    generarCuotas,
    fetchCuotasPendientes
};
