
import { getAuthToken } from "../util/auth";
import createAxiosInstance, {API_URL_BASE} from "../util/axiosInstance";

const api = createAxiosInstance('/noticias');

const fetchNoticias = async () => {
    //console.log("Entro a service...")
    const response = await api.get('/');
    //console.log("Service responde...",response.data)
    return response.data;
};

const fetchNoticiaById = async (id) => {
    const response = await api.get(`/${id}`);
    return response.data;
};

const createNoticia = async (formData) => {
    const response = await api.post('/', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
};

const updateNoticia = async (id, formData) => {
    const response = await api.put(`/${id}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
};

const deleteNoticia = async (id) => {
    const response = await api.delete(`/${id}`);
    return response.data;
};

export default {
    fetchNoticias,
    fetchNoticiaById,
    createNoticia,
    updateNoticia,
    deleteNoticia
};
