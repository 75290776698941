// reducers/usuarioReducer.js
import {
    FETCH_USUARIOS_REQUEST,
    FETCH_USUARIOS_SUCCESS,
    FETCH_USUARIOS_FAILURE,
    FETCH_USUARIO_REQUEST,
    FETCH_USUARIO_SUCCESS,
    FETCH_USUARIO_FAILURE,
    CREATE_USUARIO_REQUEST,
    CREATE_USUARIO_SUCCESS,
    CREATE_USUARIO_FAILURE,
    UPDATE_USUARIO_REQUEST,
    UPDATE_USUARIO_SUCCESS,
    UPDATE_USUARIO_FAILURE,
    DELETE_USUARIO_REQUEST,
    DELETE_USUARIO_SUCCESS,
    DELETE_USUARIO_FAILURE,
    UPDATE_PASSWORD_REQUEST,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_FAILURE,
    FETCH_FOTO_URLUSU_REQUEST,
    FETCH_FOTO_URLUSU_SUCCESS,
    FETCH_FOTO_URLUSU_FAILURE
   
} from '../actions/usuarioActions';

const initialState = {
    usuarios: [],
    currentUsuario: null,
    loading: false,
    error: null,
    success: false // Añadido para rastrear el éxito de las acciones
};

const usuarioReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USUARIOS_REQUEST:
        case FETCH_USUARIO_REQUEST:
        case CREATE_USUARIO_REQUEST:
        case UPDATE_USUARIO_REQUEST:
        case UPDATE_PASSWORD_REQUEST: 
        case FETCH_FOTO_URLUSU_REQUEST:
        case DELETE_USUARIO_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                success: false // Resetear el éxito cuando se inicie una acción
            };
        case FETCH_FOTO_URLUSU_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    fotoUrl: action.payload
                };
        case FETCH_USUARIOS_SUCCESS:
            return {
                ...state,
                loading: false,
                usuarios: action.payload,
                success: true // Acción exitosa
            };
        case FETCH_USUARIO_SUCCESS:
            return {
                ...state,
                loading: false,
                currentUsuario: action.payload,
                success: true // Acción exitosa
            };
        case CREATE_USUARIO_SUCCESS:
            return {
                ...state,
                loading: false,
                usuarios: [...state.usuarios, action.payload],
                success: true, // Acción exitosa
                error:null
            };
        case UPDATE_USUARIO_SUCCESS:
            return {
                ...state,
                loading: false,
                usuarios: state.usuarios.map(usuario =>
                    usuario.id === action.payload.id ? action.payload : usuario
                ),
                success: true,
                error: null // Acción exitosa
            };
        case UPDATE_PASSWORD_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    currentUsuario: action.payload,
                    success: true,
                    error:null
                };
       
        case DELETE_USUARIO_SUCCESS:
            return {
                ...state,
                loading: false,
                usuarios: state.usuarios.filter(usuario => usuario.id !== action.payload),
                success: true, // Acción exitosa
                error:null
            };
        case FETCH_USUARIOS_FAILURE:
        case FETCH_USUARIO_FAILURE:
        case CREATE_USUARIO_FAILURE:
        case UPDATE_USUARIO_FAILURE:
        case UPDATE_PASSWORD_FAILURE:
        case FETCH_FOTO_URLUSU_FAILURE:
        case DELETE_USUARIO_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false // Acción fallida
            };
        
               
        default:
            return state;
    }
};

export default usuarioReducer;
