// src/actions/datgenActions.js
import datgenService from '../services/datgenService';

export const FETCH_DATGENS_REQUEST = 'FETCH_DATGENS_REQUEST';
export const FETCH_DATGENS_SUCCESS = 'FETCH_DATGENS_SUCCESS';
export const FETCH_DATGENS_FAILURE = 'FETCH_DATGENS_FAILURE';

export const FETCH_DATGEN_REQUEST = 'FETCH_DATGEN_REQUEST';
export const FETCH_DATGEN_SUCCESS = 'FETCH_DATGEN_SUCCESS';
export const FETCH_DATGEN_FAILURE = 'FETCH_DATGEN_FAILURE';

export const UPDATE_DATGEN_REQUEST = 'UPDATE_DATGEN_REQUEST';
export const UPDATE_DATGEN_SUCCESS = 'UPDATE_DATGEN_SUCCESS';
export const UPDATE_DATGEN_FAILURE = 'UPDATE_DATGEN_FAILURE';

export const fetchDatgens = () => async (dispatch) => {
  dispatch({ type: FETCH_DATGENS_REQUEST });
  try {
    //console.log("en el action...")
    const response = await datgenService.fetchDatgens();
    dispatch({ type: FETCH_DATGENS_SUCCESS, payload: response.data });
    
  } catch (error) {
    dispatch({ type: FETCH_DATGENS_FAILURE, payload: error.message });
  }
};

export const fetchDatgen = (id) => async (dispatch) => {
  dispatch({ type: FETCH_DATGEN_REQUEST });
  try {
    const response = await datgenService.fetchDatgen(id);
    dispatch({ type: FETCH_DATGEN_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_DATGEN_FAILURE, payload: error.message });
  }
};


export const updateDatgen = (id, datgenData) => async (dispatch) => {
    dispatch({ type: UPDATE_DATGEN_REQUEST });
  
    // Convertir datgenData a FormData
   /* const formData = new FormData();
    for (const key in datgenData) {
      formData.append(key, datgenData[key]);
    }
  */
   // //console.log("Entro al action con: id=", id, "genData=", Array.from(formData.entries()));
  
    try {
      const response = await datgenService.updateDatgen(id, datgenData);
      dispatch({ type: UPDATE_DATGEN_SUCCESS, payload: response.data });
      return response.data;
    } catch (error) {
      dispatch({ type: UPDATE_DATGEN_FAILURE, payload: error.message });
      return Promise.reject(error);
    }
  };