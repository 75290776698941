import usuarolService from '../services/usuarolService';

export const FETCH_USER_ROLES_REQUEST = 'FETCH_USER_ROLES_REQUEST';
export const FETCH_USER_ROLES_SUCCESS = 'FETCH_USER_ROLES_SUCCESS';
export const FETCH_USER_ROLES_FAILURE = 'FETCH_USER_ROLES_FAILURE';

export const CREATE_USER_ROLE_REQUEST = 'CREATE_USER_ROLE_REQUEST';
export const CREATE_USER_ROLE_SUCCESS = 'CREATE_USER_ROLE_SUCCESS';
export const CREATE_USER_ROLE_FAILURE = 'CREATE_USER_ROLE_FAILURE';

export const DELETE_USER_ROLES_REQUEST = 'DELETE_USER_ROLES_REQUEST';
export const DELETE_USER_ROLES_SUCCESS = 'DELETE_USER_ROLES_SUCCESS';
export const DELETE_USER_ROLES_FAILURE = 'DELETE_USER_ROLES_FAILURE';

export const fetchUserRoles = (userId) => async (dispatch) => {
   // //console.log("Entro a action fetchUserRoles")
  dispatch({ type: FETCH_USER_ROLES_REQUEST });
 // try {
    const response = await usuarolService.fetchUserRoles(userId);
    ////console.log("Saliendo del action fetchuserRoles con ",response.data)
    dispatch({ type: FETCH_USER_ROLES_SUCCESS, payload: response.data });
   // return response;
 // }catch (error) {
   // dispatch({ type: FETCH_USER_ROLES_FAILURE, payload: error.message });
  //}
};

export const createUserRole = (userRoleData) => async (dispatch) => {
  dispatch({ type: CREATE_USER_ROLE_REQUEST });
 // //console.log("Entro a action create..",userRoleData)
  try {
    const response = await usuarolService.createUserRole(userRoleData);
    dispatch({ type: CREATE_USER_ROLE_SUCCESS, payload: response.data });
    return response.data;
  } catch (error) {
    dispatch({ type: CREATE_USER_ROLE_FAILURE, payload: error.message });
    return Promise.reject(error);
  }
};
export const updateUserRole = (userRoleData) => async (dispatch) => {
   try {
    const response = await usuarolService.updateUserRole(userRoleData);
   return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const deleteUserRoles = (userId) => async (dispatch) => {
  dispatch({ type: DELETE_USER_ROLES_REQUEST });
  //console.log("Entro a action delete..",userId)
  try {
    const response = await usuarolService.deleteUserRoles(userId);
    dispatch({ type: DELETE_USER_ROLES_SUCCESS, payload: userId });
    return response.data;
  } catch (error) {
    dispatch({ type: DELETE_USER_ROLES_FAILURE, payload: error.message });
    return Promise.reject(error);
  }
};
