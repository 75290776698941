import noticiaService from '../services/noticiaService';

export const FETCH_NOTICIAS_REQUEST = 'FETCH_NOTICIAS_REQUEST';
export const FETCH_NOTICIAS_SUCCESS = 'FETCH_NOTICIAS_SUCCESS';
export const FETCH_NOTICIAS_FAILURE = 'FETCH_NOTICIAS_FAILURE';

export const FETCH_NOTICIA_REQUEST = 'FETCH_NOTICIA_REQUEST';
export const FETCH_NOTICIA_SUCCESS = 'FETCH_NOTICIA_SUCCESS';
export const FETCH_NOTICIA_FAILURE = 'FETCH_NOTICIA_FAILURE';

export const CREATE_NOTICIA_REQUEST = 'CREATE_NOTICIA_REQUEST';
export const CREATE_NOTICIA_SUCCESS = 'CREATE_NOTICIA_SUCCESS';
export const CREATE_NOTICIA_FAILURE = 'CREATE_NOTICIA_FAILURE';

export const UPDATE_NOTICIA_REQUEST = 'UPDATE_NOTICIA_REQUEST';
export const UPDATE_NOTICIA_SUCCESS = 'UPDATE_NOTICIA_SUCCESS';
export const UPDATE_NOTICIA_FAILURE = 'UPDATE_NOTICIA_FAILURE';

export const DELETE_NOTICIA_REQUEST = 'DELETE_NOTICIA_REQUEST';
export const DELETE_NOTICIA_SUCCESS = 'DELETE_NOTICIA_SUCCESS';
export const DELETE_NOTICIA_FAILURE = 'DELETE_NOTICIA_FAILURE';

export const fetchNoticias = () => async (dispatch) => {
    //console.log("Entro a action fetch..");
    dispatch({ type: FETCH_NOTICIAS_REQUEST });
  
    try {
        const data = await noticiaService.fetchNoticias();
        dispatch({ type: FETCH_NOTICIAS_SUCCESS, payload: data });
    } catch (error) {
        //console.log("LLego de back.. con error..",error)
        dispatch({ type: FETCH_NOTICIAS_FAILURE, payload: error.message });
    }
};

export const fetchNoticiaById = (id) => async (dispatch) => {
    dispatch({ type: FETCH_NOTICIA_REQUEST });
    try {
        const data = await noticiaService.fetchNoticiaById(id);
        dispatch({ type: FETCH_NOTICIA_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: FETCH_NOTICIA_FAILURE, payload: error.message });
    }
};

export const createNoticia = (formData) => async (dispatch) => {
    dispatch({ type: CREATE_NOTICIA_REQUEST });
    try {
        const newNoticia = await noticiaService.createNoticia(formData);
        dispatch({ type: CREATE_NOTICIA_SUCCESS, payload: newNoticia });
    } catch (error) {
        dispatch({ type: CREATE_NOTICIA_FAILURE, payload: error.message });
    }
};

export const updateNoticia = (id, formData) => async (dispatch) => {
    dispatch({ type: UPDATE_NOTICIA_REQUEST });
    try {
        const data = await noticiaService.updateNoticia(id, formData);
        dispatch({ type: UPDATE_NOTICIA_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: UPDATE_NOTICIA_FAILURE, payload: error.message });
    }
};

export const deleteNoticia = (id) => async (dispatch) => {
    dispatch({ type: DELETE_NOTICIA_REQUEST });
    try {
        await noticiaService.deleteNoticia(id);
        dispatch({ type: DELETE_NOTICIA_SUCCESS, payload: id });
    } catch (error) {
        dispatch({ type: DELETE_NOTICIA_FAILURE, payload: error.message });
    }
};
