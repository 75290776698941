import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCiudad, updateCiudad, fetchCiudad, fetchCiudades } from '../actions/ciudadActions';
import { fetchPaises } from '../actions/paisActions';
import './CiudadForm.css';
import { toast } from 'react-toastify';

const CiudadForm = ({ ciudad, onClose }) => {
  const dispatch = useDispatch();
  const paises = useSelector(state => state.pais.paises);
  const [ciudadData, setCiudadData] = useState({ nombre: '', codpais: '' });

  useEffect(() => {
    dispatch(fetchPaises());
    if (ciudad) {
      setCiudadData({ nombre: ciudad.nombre, codpais: ciudad.codpais });
    } else {
      setCiudadData({ nombre: '', codpais: '' }); // Reset form for new entry
    }
  }, [ciudad, dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newValue = name === 'nombre' ? value.toUpperCase() : value; // Solo convertir el nombre a mayúsculas
    setCiudadData({ ...ciudadData, [name]: newValue });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ciudad) {
      // Update existing city
      if (!ciudadData.nombre || !ciudadData.codpais) {
        toast.error("Por favor complete los campos requeridos: nombre y pais");
        return;
      }
      dispatch(updateCiudad(ciudad.id, ciudadData))
        .then(() => {
          toast.success('Ciudad actualizada correctamente');
          dispatch(fetchCiudades());
          onClose();
        })
        .catch(error => {
          toast.error(`Error updating city: ${error.message || error}`);
        });
    } else {
      // Create new city
      if (!ciudadData.nombre || !ciudadData.codpais) {
        toast.error("Por favor complete los campos requeridos: nombre y pais");
        return;
      }
      dispatch(createCiudad(ciudadData))
        .then(() => {
          toast.success('Ciudad creada correctamente');
          dispatch(fetchCiudades()); // Reset form after successful creation
          onClose();
        })
        .catch(error => {
          toast.error(`Error creating city: ${error.message || error}`);
        });
    }
  };

  return (
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <label>Nombre de la Ciudad</label>
        <input
          type="text"
          name="nombre"
          value={ciudadData.nombre}
          onChange={handleChange}
        />
        <label>País</label>
        <select
          name="codpais"
          value={ciudadData.codpais}
          onChange={handleChange}
        >
          <option value="">Seleccione un país</option>
          {paises.map(pais => (
            <option key={pais.id} value={pais.id}>{pais.nombre}</option>
          ))}
        </select>
        <div className='button-group'>
          <button className="button btn-cancel" type="button" onClick={onClose} style={{ marginLeft: '10px' }}>
          Cancelar
          </button>
          <button className="button btn-accent" type="submit">
            Guardar
          </button>
        </div>
      </form>
  );
};

export default CiudadForm;
