// Footer.jsx
import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa'; // Importar íconos de FontAwesome
import './Footer.css';

function Footer() {
  return (
    <div className="footer">
      <div className="social-media">
        <button><FaFacebook /></button>
        <button><FaTwitter /></button>
        <button><FaInstagram /></button>
      </div>
    </div>
  );
}

export default Footer;