import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createUsuario, updateUsuario, fetchUsuarioById } from '../actions/usuarioActions';
import { fetchRoles } from '../actions/rolActions';
import { Modal, Button, Form, Image } from 'react-bootstrap';
import { toast } from 'react-toastify';
import placeholder from '../assets/user-profile-photo.png';
import { getAuthToken } from '../util/auth';
import { createUserRole, fetchUserRoles, updateUserRole } from '../actions/usuarolActions';

const UsuarioForm = ({ show, handleClose, isEdit, selectedId }) => {
    const dispatch = useDispatch();
    const { currentUsuario } = useSelector(state => state.usuario);
    const { roles } = useSelector(state => state.rol);
    const { userRoles } = useSelector(state => state.usuarol); // Verifica el estado del reducer
    const [usuarioData, setUsuarioData] = useState({ usuario: '', habilitado: false, foto: null, clave: '', confirmarClave: '', rolId: '' });
    const [originalData, setOriginalData] = useState(null);
    const [fotoPreview, setFotoPreview] = useState(placeholder);
    const [newPhotoPreview, setNewPhotoPreview] = useState(null);
    const [showPreview, setShowPreview] = useState(false);
    const [currentRoleName, setCurrentRoleName] = useState('');
    const UPLOADS_BASE_URL = process.env.REACT_APP_UPLOADS_BASE_URL;

    useEffect(() => {
        if (isEdit && selectedId) {
            dispatch(fetchUsuarioById(selectedId));
            dispatch(fetchUserRoles(selectedId));
        }
        dispatch(fetchRoles());
    }, [dispatch, isEdit, selectedId]);

    useEffect(() => {
        if (isEdit && currentUsuario && currentUsuario.id === selectedId) {
            const initialData = {
                usuario: currentUsuario.usuario,
                habilitado: currentUsuario.habilitado,
                foto: currentUsuario.foto,
                rolId: ''
            };
            setUsuarioData(initialData);
            setOriginalData(initialData);
            const token = getAuthToken();
            const timestamp = new Date().getTime();
          
            if (currentUsuario.foto) {
                const fotoUrl = `${UPLOADS_BASE_URL}/${currentUsuario.foto}?token=${token}&timestamp=${timestamp}`;
                setFotoPreview(fotoUrl);
            }
        }
    }, [isEdit, currentUsuario, selectedId, UPLOADS_BASE_URL]);

    useEffect(() => {
        if (isEdit && userRoles.length > 0 && roles.length > 0) {
            //console.log("Roles fetched: ", roles);
            //console.log("User roles fetched: ", userRoles);

            const userRole = userRoles.find(ur => ur.idusu === selectedId);
            if (userRole) {
                const currentRole = roles.find(rol => rol.id === userRole.idrol);
                setCurrentRoleName(currentRole ? currentRole.nombre : '');
                setUsuarioData(prevData => ({ ...prevData, rolId: userRole.idrol }));
                setOriginalData(prevData => ({ ...prevData, rolId: userRole.idrol }));
            }
        }
    }, [isEdit, userRoles, roles, selectedId]);

    const handleChange = (e) => {
        const { name, value, type, checked, files } = e.target;
        if (type === 'file') {
            const file = files[0];
            const newPhotoUrl = URL.createObjectURL(file);
            setUsuarioData({ ...usuarioData, [name]: file });
            setNewPhotoPreview(newPhotoUrl);
        } else {
            setUsuarioData({
                ...usuarioData,
                [name]: type === 'checkbox' ? checked : value
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!isEdit && usuarioData.clave !== usuarioData.confirmarClave) {
            toast.error('Confirme adecuadamente la clave o contraseña');
            return;
        }
    
        const formData = new FormData();
        for (const key in usuarioData) {
            if (key !== 'confirmarClave') {
                formData.append(key, usuarioData[key]);
            }
        }
    
        if (JSON.stringify(usuarioData) === JSON.stringify(originalData) && !newPhotoPreview) {
            toast.info('No se han realizado cambios.');
            handleClose();
            return;
        }
    
        try {
            if (isEdit) {
                await dispatch(updateUsuario(selectedId, formData));
                
                if (usuarioData.rolId !== originalData.rolId) {
            
                      dispatch(updateUserRole({ idusu: selectedId, idrol: usuarioData.rolId })).then(response => {
                        if(response.status === 202 && response.data.message){
                          toast.info(response.data.message)
                        }else if(response.status === 200)
                            {toast.success('Rol asignado correctamente');}
                       handleClose();
                      })
                      .catch(error =>{
                        toast.error(`Error al asignar el rol`);
                      });
                }
                toast.success('Usuario actualizado correctamente');
            } else {
                const response = await dispatch(createUsuario(formData));
                const newUserId = response.id;
                await dispatch(createUserRole({ idusu: newUserId, idrol: usuarioData.rolId }));
                toast.success('Usuario creado correctamente');
            }
            
            handleClose();
        } catch (error) {
            toast.error(`Error al procesar el usuario: ${error.message || error}`);
        }
        
    };
    
    const togglePreview = () => {
        setShowPreview(!showPreview);
    };

    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{isEdit ? 'Editar Usuario' : 'Crear Usuario'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="d-flex justify-content-between">
                        <div style={{ flex: 1 }}>
                            <Form.Group controlId="usuario">
                                <Form.Label>Usuario:</Form.Label>
                                {isEdit ? (
                                    <span style={{ fontSize: '1.5rem', color: 'darkgreen', fontWeight: 'bold' }}>
                                        {usuarioData.usuario}
                                    </span>
                                ) : (
                                    <Form.Control
                                        type="text"
                                        name="usuario"
                                        value={usuarioData.usuario}
                                        onChange={handleChange}
                                        required
                                    />
                                )}
                            </Form.Group>
                            {!isEdit && (
                                <>
                                    <Form.Group controlId="clave">
                                        <Form.Label>Clave:</Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="clave"
                                            value={usuarioData.clave}
                                            onChange={handleChange}
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="confirmarClave">
                                        <Form.Label>Confirmar Clave:</Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="confirmarClave"
                                            value={usuarioData.confirmarClave}
                                            onChange={handleChange}
                                            required
                                        />
                                    </Form.Group>
                                </>
                            )}
                            <Form.Group controlId="rolId">
                                <Form.Label>Rol:</Form.Label>
                                {isEdit && currentRoleName && (
                                    <div>
                                        <span style={{ fontSize: '1.1rem', color: 'darkblue', fontWeight: 'bold' }}>
                                            Rol Actual: {currentRoleName}
                                        </span>
                                    </div>
                                )}
                                <Form.Control
                                    as="select"
                                    name="rolId"
                                    value={usuarioData.rolId}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Seleccione un rol</option>
                                    {roles.map(rol => (
                                        <option key={rol.id} value={rol.id}>{rol.nombre}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="habilitado">
                                <Form.Check
                                    type="checkbox"
                                    name="habilitado"
                                    label="Habilitado"
                                    checked={usuarioData.habilitado}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="foto">
                                <Form.Label>Foto</Form.Label>
                                <Form.Control
                                    type="file"
                                    name="foto"
                                    onChange={handleChange}
                                />
                                {isEdit && (
                                    <Button variant="info" onClick={togglePreview} className="mt-2">
                                        {showPreview ? 'Ocultar Fotos' : 'Preview Fotos'}
                                    </Button>
                                )}
                            </Form.Group>
                        </div>
                        {showPreview && (
                            <div style={{ flex: 1, textAlign: 'center' }}>
                                {fotoPreview && typeof fotoPreview === 'string' && (
                                    <div>
                                        <Image 
                                            src={fotoPreview} 
                                            alt="Foto previa" 
                                            style={{ width: '100px', height: '100px', border: '1px solid #ccc', padding: '10px' }}
                                        />
                                        <div>Foto Anterior</div>
                                    </div>
                                )}
                                {newPhotoPreview && typeof newPhotoPreview === 'string' && (
                                    <div>
                                        <Image 
                                            src={newPhotoPreview} 
                                            alt="Foto nueva" 
                                            style={{ width: '100px', height: '100px', border: '1px solid #ccc', padding: '10px' }}
                                        />
                                        <div>Foto Nueva</div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="button-group">
                        <button onClick={handleClose} className="button btn-cancel me-5">
                           
                            Cancelar
                        </button>
                        <button type="submit" className="button btn-accent">
                            <i className="bi bi-save"></i> 
                            Guardar
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default UsuarioForm;
