import React, { useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CertificadoSolvencia = ({ show, handleClose, socio, textoCertificado }) => {

  useEffect(() => {
    if (show && socio) {
      generatePDF();
    }
  }, [show, socio]);

  const loadImage = async (url) => {
    const response = await fetch(url);
    const arrayBuffer = await response.arrayBuffer();
    return arrayBuffer;
  };

  const generatePDF = async () => {
    try {
      const pdfDoc = await PDFDocument.create();
      const page = pdfDoc.addPage([612, 792]); // Tamaño carta

      const logoBytes = await loadImage('/assets/cabeceraciab.png');
      const pieBytes = await loadImage('/assets/pieciab.png');

      const logoImage = await pdfDoc.embedPng(logoBytes);
      const pieImage = await pdfDoc.embedPng(pieBytes);

      // Ajustar el tamaño de las imágenes
      const logoScale = 0.4; // Escala de la imagen del logo
      const pieScale = 0.4; // Escala de la imagen del pie

      const logoDims = logoImage.scale(logoScale);
      const pieDims = pieImage.scale(pieScale);

      // Centrar imágenes en la página
      page.drawImage(logoImage, {
        x: (page.getWidth() - logoDims.width) / 2,
        y: page.getHeight() - logoDims.height - 40,
        width: logoDims.width,
        height: logoDims.height,
      });

      page.drawImage(pieImage, {
        x: (page.getWidth() - pieDims.width) / 2,
        y: 20,
        width: pieDims.width,
        height: pieDims.height,
      });

      // Incrustar una fuente estándar
      const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
      const fontBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

      const today = new Date();
      const day = today.getDate();
      const month = today.toLocaleString('default', { month: 'long' });
      const year = today.getFullYear();
      const ella = socio.genero === 'F' ? 'la' : 'el';

      // Título centrado
      page.drawText('CERTIFICADO DE SOLVENCIA', {
        x: page.getWidth() / 2 - 100,
        y: page.getHeight() - logoDims.height - 80,
        size: 16,
        font: fontBold,
        color: rgb(0, 0, 0),
      });

      // Texto del cuerpo
      const texts = [
        `El Colegio de Ingenieros Agrónomos y Profesionales de Ciencias Agropecuarias de Bolivia - CIAB Filial Tarija.`,
        "Certifica:",
        `Que, ${ella} ingeniero: ${socio.apPat} ${socio.apMat} ${socio.nombres}, con C.I. ${socio.ci}, Titulo en Provisión Nacional N° ${socio.nrotit}, ${socio.suniv}, se encuentra legalmente inscrito y activo en el CIAB - FILIAL TARIJA, con el registro ${socio.codreg}, encontrándose habilitado para ejercer la profesión (Ley N° 37014).`,
        `Este certificado es válido para:`,
        `${textoCertificado}`,
        "Es cuanto certificamos en honor a la verdad y para los fine consiguientes del interesado.",
        `Tarija, ${day} de ${month} de ${year}`
      ];

      const margin = 40;
      const maxWidth = page.getWidth() - 2 * margin;
      let y = page.getHeight() - logoDims.height - 120;

      const addText = (text, y, font, alignRight = false) => {
        const lines = text.split(' ').reduce((lines, word) => {
          let line = lines[lines.length - 1] + ' ' + word;
          if (font.widthOfTextAtSize(line, 12) > maxWidth) {
            lines[lines.length - 1] = lines[lines.length - 1].trim();
            lines.push(word);
          } else {
            lines[lines.length - 1] = line;
          }
          return lines;
        }, ['']);
        lines.forEach(line => {
          page.drawText(line, {
            x: alignRight ? page.getWidth() - margin - font.widthOfTextAtSize(line, 12) : margin,
            y,
            size: 12,
            font,
            color: rgb(0, 0, 0),
          });
          y -= 14;
        });
        return y;
      };

      texts.slice(0, -1).forEach(text => {
        // Divide el texto en líneas por los saltos de línea
        text.split('\n').forEach(line => {
          y = addText(line, y, font);
          y -= 20;
        });
      });

      // Agregar la fecha alineada a la derecha
      addText(texts[texts.length - 1], y, font, true);

      const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
      const iframe = `<iframe width='100%' height='600px' src='${pdfDataUri}'></iframe>`;
      document.getElementById('pdf-preview').innerHTML = iframe;
    } catch (error) {
      //console.error("Error generating PDF:", error);
      toast.error("Error al generar el PDF. Por favor intente de nuevo, reescriba las mayusculas con acentos y evite otros caractereres especiales.");
      handleClose();
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title>Certificado de Solvencia</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="pdf-preview"></div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CertificadoSolvencia;
