import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTitulos, deleteTitulo } from '../actions/titulosActions';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import TitulosForm from './TitulosForm';
import { Modal, Button, Table } from 'react-bootstrap';

const TitulosList = ({ showTitulosModal, closeTitulosModal, socio }) => {
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const { titulos, loading, error } = useSelector(state => state.titulo);
  const [showFormModal, setShowFormModal] = useState(false);
  const [titulo, setTitulo] = useState({
    nombre: '',
    descrip: '',
    grado: '',
    univor: '',
    fechor: new Date(),
    univrev: '',
    fechrev: new Date(),
    fechapos: new Date(),
    documpdf: '',
    idcodreg: socio ? socio.id : ''
  });
  const [tituloToDelete, setTituloToDelete] = useState(null);
  useEffect(() => {
    //console.log("Entro a List de titulos con ",socio.id);
    if (socio && socio.id) {
      dispatch(fetchTitulos(socio.id));
    }
  }, [dispatch, socio]);

  const closeFormModal = (updated) => {
    setShowFormModal(false);
    if (updated && socio && socio.id) {
      dispatch(fetchTitulos(socio.id));
    }
  };

  const createNew = () => {
    setTitulo({
      nombre: '',
      descrip: '',
      grado: '',
      univor: '',
      fechor: null,
      univrev: '',
      fechrev: null,
      fechapos: null,
      documpdf: '',
      idcodreg: socio ? socio.id : ''
    });
    setShowFormModal(true);
  };

  const confirmDeleteHandler = (id) => {
    setTituloToDelete(id);
    setConfirmDeleteModal(true);
  };

  const handleDelete = async () => {
    setConfirmDeleteModal(false);
    if (tituloToDelete) {
      try {
        await dispatch(deleteTitulo(tituloToDelete));
        toast.success('Título eliminado correctamente');
        if (socio && socio.id) {
          dispatch(fetchTitulos(socio.id));
        }
      } catch (error) {
        toast.error(`Error al eliminar título: ${error.message}`);
      }
    }
  };

  const modificar = (data) => {
    setTitulo(data);
    setShowFormModal(true);
  };

  if (loading) return <div>Cargando títulos...</div>;
  if (error) return <div>Error al cargar los datos: {error}</div>;

  return (
    <>
      <Modal show={showTitulosModal} onHide={closeTitulosModal} backdrop="static" keyboard={false} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Listado de Títulos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {socio && (
            <div className="row">
              <div className='col-auto'>
              <p><strong>Código de Registro:</strong></p>
              <p><strong>Apellido Paterno:</strong> </p>
              <p><strong>Apellido Materno:</strong></p>
              <p><strong >Nombres:</strong> </p>
              </div>
              <div className='col w-auto'>
                <p> {socio.codreg}</p>
                <p> {socio.apPat}</p>
                <p> {socio.apMat}</p>
                <p> {socio.nombres}</p>
                </div>
                <div className='col-auto'>
                <button onClick={() => createNew()} className="button btn-accent float-end">
           <i className="bi bi-plus-lg"></i>
            Agregar Título</button>

                </div>
            </div>
          )}
        
            <div className='table-container mt-3'>
          <Table     
            hover
            striped
            border={1}>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Descripción</th>
                <th>Grado</th>
                <th>Universidad Origen</th>
                <th>Fecha Origen</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {titulos.map(titulo => (
                <tr key={titulo.id}>
                  <td>{titulo.nombre}</td>
                  <td>{titulo.descrip}</td>
                  <td>{titulo.grado}</td>
                  <td>{titulo.univor}</td>
                  <td>{new Date(titulo.fechor).toLocaleDateString()}</td>
                  <td className="actions-column">
                    <div className="action-buttons">
                      <button onClick={() => modificar(titulo)}  className="button btn-accent-outline pt-1"
                    >
                      <i className="bi bi-pencil"></i></button>
                      <button onClick={() => confirmDeleteHandler(titulo.id)}  className="btn btn-outline-danger pt-1"
                    >
                      <i className="bi bi-trash"></i></button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          </div>
          <button className="button btn-cancel" onClick={closeTitulosModal}>
            <i className="bi bi-arrow-left"></i>  Volver
          </button>
          </Modal.Body>
      </Modal>
      {socio && (
        <TitulosForm showFormModal={showFormModal} closeFormModal={closeFormModal} titulo={titulo} socio={socio} />
      )}
       <Modal show={confirmDeleteModal} onHide={() => setConfirmDeleteModal(false)} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar eliminación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¿Estás seguro de que deseas eliminar este título?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setConfirmDeleteModal(false)}>No</Button>
          <Button variant="danger" onClick={handleDelete}>Sí</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TitulosList;

