const menus= 
[
   
    { title: "Gestión Socios",
      sections: [
        { name:"Gestión Socios",
          path:"/socios",
          options:  [ {name:"Cuadro de Mando Socio",  path:"/socios/2"},
                      {name:"Estado de cuenta de un socio",  path:`/socio-list-gen/${encodeURIComponent("Estado de cuenta de un socio")}/5`},
                    ]
        },
      ]
    },
   
  ];
  
export default menus;