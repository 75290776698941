import createAxiosInstance from "../util/axiosInstance";

const api = createAxiosInstance('/recibo');

// Función para obtener todos los recibos
const fetchRecibos = () => {
    return api.get('/');
};

// Función para crear un nuevo recibo
const createRecibo = (reciboData) => {
    return api.post('/', reciboData);
};


const fetchRecibosBySocio = (socioId) => api.get(`/socio/${socioId}`);




export default {
    fetchRecibos,
    createRecibo,
    fetchRecibosBySocio
};
