import { Outlet, useLoaderData, useNavigate } from 'react-router-dom';
import Header from '../components/Header'
import Footer from '../components/Footer'
import { toast, ToastContainer } from 'react-toastify';
import { useContext, useEffect } from 'react';
import { actionLogout, getTokenDuration } from '../util/auth';
import { UserContext } from '../store/user-context';
export const RootLayout = () => {

    const token = useLoaderData();
    const { setUser } = useContext(UserContext);
    const navigate=useNavigate()
    useEffect(() => {
        if(!token) {
            navigate('/')
            return;
        }
        if(token === 'EXPIRED') {
            actionLogout();
            setUser(null);
            navigate('/login')
            toast.info("La sesión expiró. Inicie sesión nuevamente.")
            return;
        }

        const tokenDuration = getTokenDuration();

        setTimeout(() => {
            actionLogout();
            setUser(null);
            navigate('/login')
            toast.info("La sesión expiró. Inicie sesión nuevamente.")
        }, tokenDuration)

    }, [token]);

    return(
        <div className="app-container">
            <Header />
            <div className="content">
              <Outlet />
            </div>
            <Footer />
          <ToastContainer position="top-right" autoClose={5000} hideProgressBar newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </div>
    )
}
