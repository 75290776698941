// src/components/CargoForm.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createRol, updateRol, fetchRol } from '../actions/rolActions';
import { Modal, Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

const RolForm = ({ show, handleClose, isEdit, selectedId }) => {
    const dispatch = useDispatch();
    const { currentRol, error } = useSelector(state => state.rol);
    const [rolData, setRolData] = useState({nombre:'',descripcion:'' });

    useEffect(() => {
        if (isEdit && selectedId) {
            dispatch(fetchRol(selectedId));
        }
    }, [dispatch, isEdit, selectedId]);

    useEffect(() => {
        if (isEdit && currentRol && currentRol.id === selectedId) {
            setRolData({
                nombre: currentRol.nombre,
                descripcion: currentRol.descripcion,
            });
        }
    }, [isEdit, currentRol, selectedId]);

    const handleChange = (e) => {
        const newNombre = e.target.value; // Convertir a mayúsculas
        setRolData({ ...rolData, descripcion: newNombre });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        //const dataToSubmit = rolData;
        if (isEdit) {
            if (!rolData.nombre || !rolData.descripcion ) {
                toast.error("Por favor complete los campos requeridos: nombre, descripcion");
                return;
            }
            dispatch(updateRol(selectedId, rolData))
                .then(() => {
                    toast.success('Rol actualizado correctamente');
                    handleClose();
                })
                .catch(error => {
                    toast.error(`Error al actualizar el rol: ${error.message || error}`);
                });
        } else {
            if (!rolData.nombre || !rolData.descripcion ) {
                toast.error("Por favor complete los campos requeridos: nombre, descripcion");
                return;
            }
            dispatch(createRol(rolData))
                .then(() => {
                    toast.success('Rol creado correctamente');
                    handleClose();
                })
                .catch(error => {
                    toast.error(`Error al crear el rol: ${error.message || error}`);
                });
        }
    };

    const handleNameChange = (e) => {
        const newNombre = e.target.value.toUpperCase(); // Convertir a mayúsculas
        setRolData({ ...rolData, nombre: newNombre });
    };
   
    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="md">
            <Modal.Header closeButton>
                <Modal.Title>{isEdit ? 'Editar Rol' : 'Crear Rol'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <div className="row align-items-center">
                            <div className="col-md-12">
                                <label className="form-label">Nombre:</label>
                                <input
                                    type="text"
                                    name="nombre"
                                    value={rolData.nombre}
                                    onChange={handleNameChange}
                                    className="w-100 form-control"
                                />
                            </div>
                            </div>
                            <div className="row">
                            <div className="col-md-12">
                                <label className="form-label">Descripción:</label>
                                    <input
                                        type="text"
                                        name="descripcion"
                                        value={rolData.descripcion}
                                        onChange={handleChange}
                                        className="w-100 form-control"
                                    />
                                </div>
                                </div>
                                <div className="button-group">
                                    <button onClick={handleClose} className="button btn-accent me-5">
                                        <i className="bi bi-arrow-left"></i> 
                                        Volver
                                    </button>
                                    <button type="submit" className="button btn-accent">
                                        <i className="bi bi-save"></i> 
                                        Guardar
                                    </button>
                                </div>
                    </form>
            </Modal.Body>
        </Modal>
    );
};

export default RolForm;

