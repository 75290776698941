import usuarioService from '../services/usuarioService';

export const FETCH_USUARIOS_REQUEST = 'FETCH_USUARIOS_REQUEST';
export const FETCH_USUARIOS_SUCCESS = 'FETCH_USUARIOS_SUCCESS';
export const FETCH_USUARIOS_FAILURE = 'FETCH_USUARIOS_FAILURE';

export const FETCH_USUARIO_REQUEST = 'FETCH_USUARIO_REQUEST';
export const FETCH_USUARIO_SUCCESS = 'FETCH_USUARIO_SUCCESS';
export const FETCH_USUARIO_FAILURE = 'FETCH_USUARIO_FAILURE';

export const CREATE_USUARIO_REQUEST = 'CREATE_USUARIO_REQUEST';
export const CREATE_USUARIO_SUCCESS = 'CREATE_USUARIO_SUCCESS';
export const CREATE_USUARIO_FAILURE = 'CREATE_USUARIO_FAILURE';

export const UPDATE_USUARIO_REQUEST = 'UPDATE_USUARIO_REQUEST';
export const UPDATE_USUARIO_SUCCESS = 'UPDATE_USUARIO_SUCCESS';
export const UPDATE_USUARIO_FAILURE = 'UPDATE_USUARIO_FAILURE';

export const DELETE_USUARIO_REQUEST = 'DELETE_USUARIO_REQUEST';
export const DELETE_USUARIO_SUCCESS = 'DELETE_USUARIO_SUCCESS';
export const DELETE_USUARIO_FAILURE = 'DELETE_USUARIO_FAILURE';

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE';

export const UPDATE_USUARIOROL_REQUEST = 'UPDATE_USUARIOROL_REQUEST';
export const UPDATE_USUARIOROL_SUCCESS = 'UPDATE_USUARIOROL_SUCCESS';
export const UPDATE_USUARIOROL_FAILURE = 'UPDATE_USUARIOROL_FAILURE';

export const FETCH_FOTO_URLUSU_REQUEST = 'FETCH_FOTO_URLUSU_REQUEST';
export const FETCH_FOTO_URLUSU_SUCCESS = 'FETCH_FOTO_URLUSU_SUCCESS';
export const FETCH_FOTO_URLUSU_FAILURE = 'FETCH_FOTO_URLUSU_FAILURE';





export const fetchFotoUrlUsu = (id) => (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: FETCH_FOTO_URLUSU_REQUEST });
      try {
        const fotoUrlusu = usuarioService.getFotoUrlUsu(id); // Asegúrate de que esta función síncrona no lance excepciones inesperadas
        dispatch({ type: FETCH_FOTO_URLUSU_SUCCESS, payload: fotoUrlusu });
        resolve(fotoUrlusu); // Resuelve la promesa con el resultado
      } catch (error) {
        dispatch({ type: FETCH_FOTO_URLUSU_FAILURE, payload: error.message });
        reject(error.message); // Rechaza la promesa con el mensaje de error
      }
    });
  };


export const fetchUsuarios = () => async (dispatch) => {
    dispatch({ type: FETCH_USUARIOS_REQUEST });
    try {
        const data = await usuarioService.fetchUsuarios();
        dispatch({ type: FETCH_USUARIOS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: FETCH_USUARIOS_FAILURE, payload: error.message });
    }
};

export const fetchUsuarioById = (id) => async (dispatch) => {
    dispatch({ type: FETCH_USUARIO_REQUEST });
    try {
        const data = await usuarioService.fetchUsuarioById(id);
        dispatch({ type: FETCH_USUARIO_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: FETCH_USUARIO_FAILURE, payload: error.message });
    }
};

export const createUsuario = (formData) => async (dispatch) => {
  dispatch({ type: CREATE_USUARIO_REQUEST });
  try {
     

      const newUsuario = await usuarioService.createUsuario(formData);
      dispatch({ type: CREATE_USUARIO_SUCCESS, payload: newUsuario });
      //console.log("En action de createUsuario..",newUsuario)
      return newUsuario
  } catch (error) {
      dispatch({ type: CREATE_USUARIO_FAILURE, payload: "Error en action..." });
  }
};



export const updateUsuario = (id,formData) => async (dispatch) => {
    dispatch({ type: UPDATE_USUARIO_REQUEST });
    try {
      //console.log('Datos a enviar en upDate:');
      formData.forEach((value, key) => {
          //console.log(`${key}: ${value}`);
      });
        const data = await usuarioService.updateUsuario(id,formData);
        dispatch({ type: UPDATE_USUARIO_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: UPDATE_USUARIO_FAILURE, payload: error.message });
    }
};

export const deleteUsuario = (id) => async (dispatch) => {
    dispatch({ type: DELETE_USUARIO_REQUEST });
    try {
        await usuarioService.deleteUsuario(id);
        dispatch({ type: DELETE_USUARIO_SUCCESS, payload: id });
    } catch (error) {
        dispatch({ type: DELETE_USUARIO_FAILURE, payload: error.message });
    }
};

export const updatePassword = (id, data) => async (dispatch) => {
  dispatch({ type: UPDATE_PASSWORD_REQUEST });
  try {
      const response = await usuarioService.updatePassword(id, data);
      dispatch({ type: UPDATE_PASSWORD_SUCCESS, payload: response });
  } catch (error) {
      dispatch({ type: UPDATE_PASSWORD_FAILURE, payload: error.message });
  }
};




export const assignUsuarioRol  = (data) => async (dispatch) => {
  dispatch({ type: UPDATE_USUARIOROL_REQUEST });
  try {
      const response = await usuarioService.updateUsuarol(data);
      dispatch({ type: UPDATE_USUARIOROL_SUCCESS, payload: response });
  } catch (error) {
      dispatch({ type: UPDATE_USUARIOROL_FAILURE, payload: error.message });
  }
};