// Import statements and initial setup remain unchanged
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  fetchSocios,
  deleteSocio,
  fetchFotoUrl,
  fetchMaxCodreg,
} from "../actions/socioActions";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import { Pagination, Table, Button, Modal } from "react-bootstrap";
import SocioForm from "./SocioForm";
import SocioFicha from "./SocioFicha";
import TitulosList from "./TitulosList";
import CuotasList from "./CuotaList";
import GestionRecibos from "./GestionRecibos";
import CustomPrintModal from './CustomPrintModal';
import * as XLSX from 'xlsx';
import "./SocioList.css";

const SocioList = () => {
  const { drol } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const fromHeader = location.state?.fromHeader;
    //console.log('fromHeader =', fromHeader);
    if (!fromHeader) {
      navigate('/'); // Redirige a la página principal si no es llamado desde Header
    }
  }, [navigate]);

  const dispatch = useDispatch();
  const store = useStore();
  const { socios, loading, error } = useSelector((state) => state.socio);
  const lastCodreg = useSelector((state) => state.socio.lastCodreg);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortKey, setSortKey] = useState("codreg");
  const [sortOrder, setSortOrder] = useState("asc");
  const [showModal, setShowModal] = useState(false);
  const [showFichaModal, setShowFichaModal] = useState(false);
  const [showTitulosModal, setShowTitulosModal] = useState(false);
  const [showCuotasModal, setShowCuotasModal] = useState(false);
  const [showGestionRecibosModal, setShowGestionRecibosModal] = useState(false);
  const [fotoUrl, setFotoUrl] = useState(null);
  const { funcionalidad } = useParams();
  const [socio, setSocio] = useState({
    codreg: "",
    apPat: "",
    apMat: "",
    nombres: "",
    fenac: new Date(),
    fereg: new Date(),
    ci: "",
    genero: "O",
    estado: 1,
    codciu: "",
    email: "",
    celular: "",
    foto: null,
  });

  const [newCodreg, setNewCodreg] = useState("");
  const [filterStatus, setFilterStatus] = useState("1");
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [content, setContent] = useState([]);
  const [printTitle, setPrintTitle] = useState("LISTA GENERAL DE SOCIOS");
  const [showConfirmExportModal, setShowConfirmExportModal] = useState(false); // Nuevo estado para el modal de confirmación

  const closeModal = (updated) => {
    setShowModal(false);
    if (updated) {
      dispatch(fetchSocios());
    }
  };

  const closeFichaModal = () => {
    setShowFichaModal(false);
  };
  const closeTitulosModal = () => {
    setShowTitulosModal(false);
  };

  const closeCuotasModal = () => {
    setShowCuotasModal(false);
  };

  const closeGestionRecibosModal = () => {
    setShowGestionRecibosModal(false);
  };

  const createNew = async () => {
    await dispatch(fetchMaxCodreg());
    const updatedLastCodreg = store.getState().socio.lastCodreg;
    //console.log("Despues del last: ", updatedLastCodreg);
    const newCodregValue = (parseInt(updatedLastCodreg, 10) + 1)
      .toString()
      .padStart(5, "0");
    setNewCodreg(newCodregValue);
    setSocio({
      codreg: newCodregValue,
      apPat: "",
      apMat: "",
      nombres: "",
      fenac: new Date(),
      fereg: new Date(),
      ci: "",
      genero: "O",
      estado: 1,
      codciu: "",
      email: "",
      celular: "",
      foto: null,
    });
    setShowModal(true);
  };

  useEffect(() => {
    dispatch(fetchSocios());
  }, [dispatch]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSortChange = (event) => {
    setSortKey(event.target.value);
  };

  const handleSortOrderChange = (event) => {
    setSortOrder(event.target.value);
  };

  const handleFilterStatusChange = (event) => {
    setFilterStatus(event.target.value);
  };

  const filteredSocios = (socios || [])
    .filter((socio) => socio && socio.codreg && socio.apPat)
    .filter(
      (socio) =>
        (filterStatus === "todos" ||
          (socio.estado != null && socio.estado.toString() === filterStatus)) &&
        (socio.codreg.includes(searchTerm) ||
          socio.apPat.toLowerCase().includes(searchTerm.toLowerCase()))
    )
    .sort((a, b) => {
      if (a[sortKey] < b[sortKey]) return sortOrder === "asc" ? -1 : 1;
      if (a[sortKey] > b[sortKey]) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });

  const indexOfLastSocio = currentPage * itemsPerPage;
  const indexOfFirstSocio = indexOfLastSocio - itemsPerPage;
  const currentSocios = filteredSocios.slice(
    indexOfFirstSocio,
    indexOfLastSocio
  );

  const totalPages = Math.ceil(filteredSocios.length / itemsPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleDelete = (id) => {
    confirmAlert({
      title: "Confirmar eliminación",
      message: "¿Estás seguro de que deseas eliminar este socio?",
      buttons: [
        {
          label: "Sí",
          onClick: () => {
            dispatch(deleteSocio(id))
              .then((response) => {
                if (response.status === 200) {
                  toast.success("Socio eliminado correctamente");
                  dispatch(fetchSocios());
                } else {
                  toast.error(
                    `No se puede dar de baja a un socio con dependencias...`
                  );
                }
              })
              .catch((error) => {
                toast.error(
                  `No se puede dar de baja a un socio con dependencias...`
                );
              });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handlePrintModalShow = () => {
    const sortedFilteredSocios = [...filteredSocios];
    const content = {
      head: [["NroReg", "Apellido Paterno", "Apellido Materno", "Nombres", "CI"]],
      body: sortedFilteredSocios.map(socio => [
        socio.codreg,
        socio.apPat,
        socio.apMat,
        socio.nombres,
        socio.ci
      ])
    };

    let titleSuffix;
    switch (filterStatus) {
      case "1":
        titleSuffix = " - ACTIVOS";
        break;
      case "0":
        titleSuffix = " - INACTIVOS";
        break;
      case "2":
        titleSuffix = " - DADOS DE BAJA";
        break;
      default:
        titleSuffix = " - TODOS";
    }

    setPrintTitle(`LISTA GENERAL DE SOCIOS${titleSuffix}`);
    setContent(content);
    setShowPrintModal(true);
  };

  const closeModalPrint = () => {
    setShowPrintModal(false);
  };

  const modificar = (data) => {
    setSocio({
      ...data,
      fenac: data.fenac ? new Date(data.fenac) : new Date(),
      fereg: data.fereg ? new Date(data.fereg) : new Date(),
    });

    dispatch(fetchFotoUrl(data.id)).then((url) => {
      setFotoUrl(url);
    });
    setShowFichaModal(false);
    setShowModal(true);
  };

  const verTitulos = (data) => {
    setSocio({
      ...data,
      fenac: data.fenac ? new Date(data.fenac) : new Date(),
      fereg: data.fereg ? new Date(data.fereg) : new Date(),
    });
    setShowTitulosModal(true);
  };

  const verCuotas = (data) => {
    setSocio({
      ...data,
      fenac: data.fenac ? new Date(data.fenac) : new Date(),
      fereg: data.fereg ? new Date(data.fereg) : new Date(),
    });
    setShowCuotasModal(true);
  };

  const verFicha = (data) => {
    setSocio({
      ...data,
      fenac: data.fenac ? new Date(data.fenac) : new Date(),
      fereg: data.fereg ? new Date(data.fereg) : new Date(),
    });

    dispatch(fetchFotoUrl(data.id)).then((url) => {
      setFotoUrl(url);
    });
    setShowModal(false);
    setShowFichaModal(true);
  };

  const verGestionRecibos = (data) => {
    setSocio({
      ...data,
      fenac: data.fenac ? new Date(data.fenac) : new Date(),
      fereg: data.fereg ? new Date(data.fereg) : new Date(),
    });
    setShowGestionRecibosModal(true);
  };

  const getTitle = () => {
    switch (funcionalidad) {
      case "funcionalidad1":
        return "CUADRO DE MANDO SOCIOS - Funcionalidad 1";
      case "funcionalidad2":
        return "CUADRO DE MANDO SOCIOS - Funcionalidad 2";
      default:
        return "CUADRO DE MANDO SOCIOS";
    }
  };

  const handleExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredSocios.map(socio => ({
      CodReg: socio.codreg,
      "Apellido Paterno": socio.apPat,
      "Apellido Materno": socio.apMat,
      Nombres: socio.nombres,
      CI: socio.ci,
      Estado: socio.estado === 1 ? "ACTIVO" : "INACTIVO"
    })));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Socios");

    XLSX.writeFile(workbook, "Listado_Socios.xlsx");
  };

  const handleShowConfirmExportModal = () => {
    setShowConfirmExportModal(true);
  };

  const handleConfirmExport = () => {
    handleExportToExcel();
    setShowConfirmExportModal(false);
  };

  const handleCancelExport = () => {
    setShowConfirmExportModal(false);
  };

  if (loading) return <div>Cargando socios...</div>;
  if (error) return <div className='error-message'>Error al cargar los datos: {error}</div>;

  return (
    <>
      <div className="list1-container container-list">
        <h2>{getTitle()}</h2>
        <div className="row w-100 mb-2">
          <div className="col-auto mt-2">
            <label htmlFor="items-per-page">Registros por página:</label>
            <select
              id="items-per-page"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
            >
              <option value="10">10</option>
              <option value="30">30</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <div className="col-auto mt-2">
            <label htmlFor="status-filter">Mostrar:</label>
            <select
              id="status-filter"
              value={filterStatus}
              onChange={handleFilterStatusChange}
            >
              <option value="todos">TODOS</option>
              <option value="1">ACTIVOS</option>
              <option value="0">INACTIVOS</option>
              <option value="2">DADOS DE BAJA</option>
            </select>
          </div>
          <div className="col-auto mt-2">
            <label htmlFor="sort">Ordenar por:</label>
            <select id="sort" value={sortKey} onChange={handleSortChange}>
              <option value="codreg">NroReg</option>
              <option value="apPat">Apellido Paterno</option>
            </select>
            <select id="order" value={sortOrder} onChange={handleSortOrderChange}>
              <option value="asc">Ascendente</option>
              <option value="desc">Descendente</option>
            </select>
          </div>
        </div>
        <div className="row w-100 mb-2">
          <div className="col-md-6 mt-2">
            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon1">
                <i className="bi bi-search"></i>
              </span>
              <input
                id="search"
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Buscar por NroReg o Apellido Paterno"
                className="form-control"
              />
            </div>
          </div>
          {drol === '1' && (
            <div className="col-md-6 mt-2 d-flex justify-content-end">
              <button onClick={createNew} className="button btn-accent float-end">
                <i className="bi bi-plus-lg"></i>
                Nuevo Socio
              </button>
            </div>
          )}
        </div>
        <div className="table-container">
          <Table hover striped size="sm" border={1} bordered>
            <thead>
              <tr>
                <th className="th-cod">NroReg</th>
                <th className="narrow-columnap">Apellido Paterno</th>
                <th className="narrow-columnap">Apellido Materno</th>
                <th className="narrow-columnap">Nombres</th>
                <th className="narrow-columnci">C.I.</th>
                <th style={{ width: "300px" }}>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {currentSocios.map((socio) => (
                <tr key={socio.id}>
                  <td className="narrow-column">{socio.codreg}</td>
                  <td className="narrow-columnap">{socio.apPat}</td>
                  <td className="narrow-columnap">{socio.apMat}</td>
                  <td className="narrow-columnap">{socio.nombres}</td>
                  <td className="narrow-columnci">{socio.ci}</td>
                  <td className="d-flex justify-content-between">
                    {drol === '1' && (
                      <>
                        <button
                          onClick={() => modificar(socio)}
                          className="btn btn-outline-primary btn-sm"
                        >
                          <i className="bi bi-pencil"></i>
                        </button>
                        <button
                          onClick={() => handleDelete(socio.id)}
                          className="btn btn-outline-danger btn-sm"
                        >
                          <i className="bi bi-trash"></i>
                        </button>
                        <button
                          onClick={() => verTitulos(socio)}
                          className="btn btn-outline-secondary btn-sm"
                        >
                          Títulos
                        </button>
                      </>
                    )}
                    <button
                      onClick={() => verCuotas(socio)}
                      className="btn btn-outline-secondary btn-sm"
                    >
                      Cuotas
                    </button>
                    <button
                      onClick={() => verFicha(socio)}
                      className="btn btn-outline-secondary btn-sm"
                    >
                      Ficha
                    </button>
                    <button
                      onClick={() => verGestionRecibos(socio)}
                      className="btn btn-outline-secondary btn-sm"
                    >
                      Gestión Recibos
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <div className="text-center mb-3 button-group">
          <button
            onClick={() => navigate("/")}
            className="button btn-cancel"
          >
            <i className="bi bi-arrow-left"></i>  Volver
          </button>

          {drol === '1' && (
                      <>
                       <button onClick={handlePrintModalShow} className="button btn-accent">
                       <i className="bi bi-printer"></i>  Imprimir Lista
                      </button>
                      <button onClick={handleShowConfirmExportModal} className="button btn-accent">
                      <i className="bi bi-filetype-xls"></i>  Exportar a Excel
                      </button>
                      </>
                    )}        
        </div>

        <div className="pagination-controls">
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className="btn btn-outline-primary"
          >
            Anterior
          </button>
          <span className="mx-2">
            Página {currentPage} de {totalPages}
          </span>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="btn btn-outline-primary"
          >
            Siguiente
          </button>
        </div>

        <SocioForm
          showModal={showModal}
          closeModal={closeModal}
          socio={socio}
          newCodreg={newCodreg}
        />
        <SocioFicha
          showModalFicha={showFichaModal}
          closeModalFicha={closeFichaModal}
          sociof={socio}
          fotoUrlFicha={fotoUrl}
        />
        {showTitulosModal && (
          <TitulosList
            showTitulosModal={showTitulosModal}
            closeTitulosModal={closeTitulosModal}
            socio={socio}
          />
        )}
        {showCuotasModal && (
          <CuotasList
            showCuotasModal={showCuotasModal}
            closeCuotasModal={closeCuotasModal}
            socio={socio}
          />
        )}
        {showGestionRecibosModal && (
          <GestionRecibos
            showGestionRecibosModal={showGestionRecibosModal}
            closeGestionRecibosModal={closeGestionRecibosModal}
            socio={socio}
          />
        )}
        <CustomPrintModal show={showPrintModal} handleClose={closeModalPrint} title={printTitle} content={content} />
        
        <Modal show={showConfirmExportModal} onHide={handleCancelExport}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmar Exportación</Modal.Title>
          </Modal.Header>
          <Modal.Body>¿Estás seguro de que deseas exportar el listado a Excel?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCancelExport}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={handleConfirmExport}>
              Confirmar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default SocioList;
