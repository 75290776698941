// UserContext.js
import React, { createContext, useEffect, useState } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [userState, setUserState] = useState({ id: null, user: null, roles: [], foto: null });
    
    useEffect(() => {
        const storedId = localStorage.getItem('id');
        const storedUser = localStorage.getItem('user');
        const storedRoles = localStorage.getItem('roles');
        const storedFoto = localStorage.getItem('foto');

        /* const storedLogo = localStorage.getItem('logo');
        const storedCole = localStorage.getItem('cole');*/

       
        try {
            setUserState({
                id: storedId ? JSON.parse(storedId) : null,
                user: storedUser ? storedUser : null,
                roles: storedRoles ? storedRoles.split(',') : [],
                foto: storedFoto ? storedFoto : null
            });
        } catch (error) {
            console.error('Error parsing user data from localStorage:', error);
        }
    }, []);

    const setUser = (id, user, roles, foto) => {
        if (id && user) {
            localStorage.setItem('user', user);
            localStorage.setItem('id', id);
        }
        if (roles) {
            localStorage.setItem('roles', roles.join(','));
        }
        if (foto) {
            localStorage.setItem('foto', foto);
        }
        //console.log("Roles en context", roles)
        setUserState({ id, user, roles, foto });
    };

    return (
        <UserContext.Provider value={{ userState, setUser }}>
            {children}
        </UserContext.Provider>
    );
};