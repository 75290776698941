import {
    FETCH_DIRECSOC_REQUEST,
    FETCH_DIRECSOC_SUCCESS,
    FETCH_DIRECSOC_FAILURE,
    DELETE_DIRECSOC_REQUEST,
    DELETE_DIRECSOC_SUCCESS,
    DELETE_DIRECSOC_FAILURE,
    SAVE_DIRECSOC_REQUEST,
    SAVE_DIRECSOC_SUCCESS,
    SAVE_DIRECSOC_FAILURE,
    GET_ALL_DIRECSOC_REQUEST,
    GET_ALL_DIRECSOC_SUCCESS,
    GET_ALL_DIRECSOC_FAILURE,
    GET_DIRECSOC_REQUEST,
    GET_DIRECSOC_SUCCESS,
    GET_DIRECSOC_FAILURE,
    UPDATE_DIRECSOC_REQUEST,
    UPDATE_DIRECSOC_SUCCESS,
    UPDATE_DIRECSOC_FAILURE,
    CLEAR_DIRECSOC,
    RESET_DIRECSOC
} from '../actions/direcsocActions';

const initialState = {
    directivaSocios: [],
    direcsoc: [],
    loading: false,
    error: null
};

const direcsocReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DIRECSOC_REQUEST:
        case DELETE_DIRECSOC_REQUEST:
        case SAVE_DIRECSOC_REQUEST:
        case GET_ALL_DIRECSOC_REQUEST:
        case GET_DIRECSOC_REQUEST:
        case UPDATE_DIRECSOC_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_DIRECSOC_SUCCESS:
            return {
                ...state,
                loading: false,
                directivaSocios: action.payload,
                direcsoc: action.payload
            };
        case GET_ALL_DIRECSOC_SUCCESS:
            return {
                ...state,
                loading: false,
                direcsoc: action.payload
            };
        case GET_DIRECSOC_SUCCESS:
            return {
                ...state,
                loading: false,
                direcsoc: action.payload
            };
        case UPDATE_DIRECSOC_SUCCESS:
            return {
                ...state,
                loading: false,
                direcsoc: state.direcsoc.map(socio => 
                    socio.id === action.payload.id ? action.payload : socio
                )
            };
        case DELETE_DIRECSOC_SUCCESS:
            return {
                ...state,
                loading: false,
                direcsoc: state.direcsoc.filter(socio => socio.id !== action.payload)
            };
        case SAVE_DIRECSOC_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case FETCH_DIRECSOC_FAILURE:
        case DELETE_DIRECSOC_FAILURE:
        case SAVE_DIRECSOC_FAILURE:
        case GET_ALL_DIRECSOC_FAILURE:
        case GET_DIRECSOC_FAILURE:
        case UPDATE_DIRECSOC_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case CLEAR_DIRECSOC:
            return {
                ...state,
                directivaSocios: [],
                direcsoc: [],
                loading: false,
                error: null
            };
        case RESET_DIRECSOC:
            return initialState;
        default:
            return state;
    }
};

export default direcsocReducer;
