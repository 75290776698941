import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchSocios, fetchFotoUrl } from "../actions/socioActions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SocioCambioEstado from './SocioCambioEstado'; 
import CertificadoRegistro from './CertificadoRegistro'; 
import CertificadoSolvencia from './CertificadoSolvencia';
import SocioFicha from './SocioFicha';  // Import the SocioFicha component
import SocioEstadoCuenta from './SocioEstadoCuenta';  // Import the SocioEstadoCuenta component

const SocioListGen = () => {
  const { titulo, funcionalidad } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { socios, loading, error } = useSelector((state) => state.socio);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSocio, setSelectedSocio] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [textoCertificado, setTextoCertificado] = useState("");
  const [fotoUrl, setFotoUrl] = useState(null);

  useEffect(() => {
    dispatch(fetchSocios());
  }, [dispatch]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredSocios = (socios || [])
    .filter((socio) => socio && socio.codreg && socio.apPat)
    .filter(
      (socio) =>
        socio.codreg.includes(searchTerm) ||
        socio.apPat.toLowerCase().includes(searchTerm.toLowerCase())
    );

  const normalizeText = (text) => {
    return text
      .replace(/Á/g, 'A')
      .replace(/É/g, 'E')
      .replace(/Í/g, 'I')
      .replace(/Ó/g, 'O')
      .replace(/Ú/g, 'U');
  };

  const handleSelect = (socio) => {
    if (funcionalidad === "1") {
      setSelectedSocio(socio);
      setShowModal(true);
    } else if (funcionalidad === "2") {
      if (socio.estado === 1) {
        setSelectedSocio(socio);
        setShowModal(true);
      } else {
        toast.error(`El socio ${socio.nombres} ${socio.apPat} ${socio.apMat} no es un socio activo.... consulte con la directiva...`);
      }
    } else if (funcionalidad === "3") {
      if (!textoCertificado.trim()) {
        toast.error("Por favor ingrese el texto requerido para el certificado.");
        return;
      }

      const textoCertificadoNormalized = normalizeText(textoCertificado);

      if (socio.estado === 1) {
        setSelectedSocio(socio);
        setShowModal(true);
      } else {
        toast.error(`El socio ${socio.nombres} ${socio.apPat} ${socio.apMat} no es un socio activo.... consulte con la directiva...`);
      }
    } else if (funcionalidad === "4") {
      dispatch(fetchFotoUrl(socio.id)).then((url) => {
        setFotoUrl(url);
      });
      setSelectedSocio(socio);
      setShowModal(true);
    } else if (funcionalidad === "5") {
      setSelectedSocio(socio);
      setShowModal(true);
    } else {
      toast.info("Funcionalidad no implementada.");
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedSocio(null);
    dispatch(fetchSocios());
  };

  if (loading) return <div>Cargando socios...</div>;
  if (error) return <div>Error al cargar los datos: {error}</div>;

  return (
    <>
      <div className="list1-container container-list">
        <h2>{titulo}</h2>
        <div className="row w-100 mb-2" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div className="col-auto mt-2">
            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon1">
                <i className="bi bi-search"></i>
              </span>
              <input
                id="search"
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Buscar por NroReg o Apellido Paterno"
                className="form-control"
              />
            </div>
          </div>
          {funcionalidad === "3" && (
            <div className="col-auto mt-2" style={{ flex: 3 }}>
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon2">
                  <i className="bi bi-pencil"></i>
                </span>
                <textarea
                  id="textoCertificado"
                  value={textoCertificado}
                  onChange={(e) => setTextoCertificado(e.target.value)}
                  placeholder="El certificado es válido para:"
                  className="form-control"
                  rows="3"
                />
              </div>
            </div>
          )}
        </div>
        <div className="table-container">
          <table className="table table-hover table-striped table-sm border">
            <thead>
              <tr>
                <th>NroReg</th>
                <th>Apellido Paterno</th>
                <th>Apellido Materno</th>
                <th>Nombres</th>
                <th>C.I.</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {filteredSocios.map((socio) => (
                <tr key={socio.id}>
                  <td>{socio.codreg}</td>
                  <td>{socio.apPat}</td>
                  <td>{socio.apMat}</td>
                  <td>{socio.nombres}</td>
                  <td>{socio.ci}</td>
                  <td>
                    <button
                      onClick={() => handleSelect(socio)}
                      className="button btn-accent-outline"
                    >
                      Seleccionar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
         
        </div>
         
        <div className="button-group mb-4">
            <button onClick={() => navigate("/")} className="button btn-cancel">
            <i className="bi bi-arrow-left"></i>  Volver
            </button>
          </div>
      </div>
      {selectedSocio && funcionalidad === "1" && (
        <SocioCambioEstado
          show={showModal}
          handleClose={handleCloseModal}
          socio={selectedSocio}
          onSocioUpdated={() => dispatch(fetchSocios())}
        />
      )}
      {selectedSocio && funcionalidad === "2" && (
        <CertificadoRegistro
          show={showModal}
          handleClose={handleCloseModal}
          socio={selectedSocio}
          textoCertificado={normalizeText(textoCertificado)}
          onSocioUpdated={() => dispatch(fetchSocios())}
        />
      )}
      {selectedSocio && funcionalidad === "3" && (
        <CertificadoSolvencia
          show={showModal}
          handleClose={handleCloseModal}
          socio={selectedSocio}
          textoCertificado={normalizeText(textoCertificado)}
          onSocioUpdated={() => dispatch(fetchSocios())}
        />
      )}
      {selectedSocio && funcionalidad === "4" && (
        <SocioFicha
          showModalFicha={showModal}
          closeModalFicha={handleCloseModal}
          sociof={selectedSocio}
          fotoUrlFicha={selectedSocio.foto ? fotoUrl : null}
        />
      )}
      {selectedSocio && funcionalidad === "5" && (
        <SocioEstadoCuenta
          show={showModal}
          handleClose={handleCloseModal}
          socio={selectedSocio}
        />
      )}
    </>
  );
};

export default SocioListGen;
