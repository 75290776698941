import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../store/user-context';
import { FaExclamationTriangle } from 'react-icons/fa';
import { fetchDatgens, updateDatgen } from '../actions/datgenActions';
import { getAuthToken } from '../util/auth';

const ActualizarDatosGenerales = () => {
    const { userState } = useContext(UserContext);
    const { user, roles } = userState;

    const [datos, setDatos] = useState({
        nombre: '',
        siglas: '',
        logo: null,
        cabeceraHojas: null,
        cabeceraPortal: null,
        direccion: '',
        email: '',
        telefono: ''
    });

    const [idg, setIdg] = useState('');
    const [showPreview, setShowPreview] = useState(false);
    const [newPhotoPreview, setNewPhotoPreview] = useState({ logo: null, cabeceraHojas: null, cabeceraPortal: null });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const datgenState = useSelector((state) => state.datgen);
    const { loading, success, error, datgens } = datgenState || {};
    const UPLOADS_BASE_URL = process.env.REACT_APP_UPLOADS_BASE_URL;

    useEffect(() => {
        dispatch(fetchDatgens());
    }, [dispatch]);

    useEffect(() => {
        if (datgens && datgens.length > 0) {
            setDatos({
                nombre: datgens[0].dato1,
                siglas: datgens[0].dato2,
                logo: datgens[0].dato3,
                cabeceraHojas: datgens[0].dato4,
                cabeceraPortal: datgens[0].dato5,
                direccion: datgens[0].dato6,
                email: datgens[0].dato7,
                telefono: datgens[0].dato8,
            });
            setIdg(datgens[0].id);
        }
    }, [datgens]);

    useEffect(() => {
        if (success) {
            toast.success("La actualización ha sido un éxito...");
        } else if (error) {
            toast.error(`Error al actualizar datos generales: ${error}`);
        }
    }, [success, error]);

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        if (type === 'file') {
            const file = files[0];
            const newPhotoUrl = URL.createObjectURL(file);
            setDatos({ ...datos, [name]: file });
            setNewPhotoPreview(prevPreview => ({ ...prevPreview, [name]: newPhotoUrl }));
        } else {
            setDatos({
                ...datos,
                [name]: value
            });
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const fechaActual = new Date().toLocaleDateString();
        if (window.confirm(`${user} ${roles} ¿Estás seguro de que deseas actualizar los datos generales?`)) {
            const data = new FormData();
            data.append('dato1', datos.nombre);
            data.append('dato2', datos.siglas);
            if (datos.logo) data.append('dato3', datos.logo);
            if (datos.cabeceraHojas) data.append('dato4', datos.cabeceraHojas);
            if (datos.cabeceraPortal) data.append('dato5', datos.cabeceraPortal);
            data.append('dato6', datos.direccion);
            data.append('dato7', datos.email);
            data.append('dato8', datos.telefono);
            data.append('observaciones', `Usuario: ${user}, Fecha: ${fechaActual}`);
            
            // Verifica el contenido de FormData
            for (var pair of data.entries()) {
                //console.log(pair[0] + ', ' + pair[1]);
            }
    
            dispatch(updateDatgen(idg, data));
        }
    };
    
    const togglePreview = () => {
        setShowPreview(!showPreview);
    };

    const authToken = getAuthToken();

    const getImageUrl = (path) => `${UPLOADS_BASE_URL}/${path}?token=${authToken}&timestamp=${new Date().getTime()}`;

    return (
        <div className="list4-container container-list">
            <form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <FaExclamationTriangle style={{ color: 'red', marginRight: '5px', fontSize: '3em' }} />
                    <span style={{ fontSize: '1.5em', color: 'darkgreen', fontWeight: 'bold', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)' }}>Alerta:</span>
                </div>
                <p style={{ marginBottom: '20px' }}>
                    La actualización de los datos generales es un proceso importante. Asegúrese de que los valores sean correctos antes de proceder.
                </p>
                <div className="form-group">
                    <label>Nombre completo del colegio:</label>
                    <input
                        type="text"
                        name="nombre"
                        value={datos.nombre}
                        onChange={handleChange}
                        className="form-control"
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Siglas:</label>
                    <input
                        type="text"
                        name="siglas"
                        value={datos.siglas}
                        onChange={handleChange}
                        className="form-control"
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Logo:</label>
                    <input
                        type="file"
                        name="logo"
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Cabecera para hojas:</label>
                    <input
                        type="file"
                        name="cabeceraHojas"
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Cabecera para portal:</label>
                    <input
                        type="file"
                        name="cabeceraPortal"
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Dirección:</label>
                    <input
                        type="text"
                        name="direccion"
                        value={datos.direccion}
                        onChange={handleChange}
                        className="form-control"
                        required
                    />
                </div>
                <div className='row'>
                <div className="col-md-6">
                    <label>Email:</label>
                    <input
                        type="email"
                        name="email"
                        value={datos.email}
                        onChange={handleChange}
                        className="form-control"
                        required
                    />
                </div>
                <div className="col-md-6">
                    <label>Teléfono:</label>
                    <input
                        type="text"
                        name="telefono"
                        value={datos.telefono}
                        onChange={handleChange}
                        className="form-control"
                        required
                    />
                </div>
                </div>
                <button type="button" className="button btn btn-dark" onClick={togglePreview}>
                    {showPreview ? 'Ocultar Vista Previa' : 'Mostrar Vista Previa'}
                </button>
                {showPreview && (
                    <div className="preview-container">
                        <div>
                            <label>Logo Actual:</label>
                            {datgens[0]?.dato3 && (
                                <img src={getImageUrl(datgens[0].dato3)} alt="Logo Actual" style={{ width: '100px', height: '100px' }} />
                            )}
                            {newPhotoPreview.logo && (
                                <>
                                    <label>Nuevo Logo:</label>
                                    <img src={newPhotoPreview.logo} alt="Nuevo Logo" style={{ width: '100px', height: '100px' }} />
                                </>
                            )}
                        </div>
                        <div>
                            <label>Cabecera para Hojas Actual:</label>
                            {datgens[0]?.dato4 && (
                                <img src={getImageUrl(datgens[0].dato4)} alt="Cabecera para Hojas Actual" style={{ width: '100px', height: '100px' }} />
                            )}
                            {newPhotoPreview.cabeceraHojas && (
                                <>
                                    <label>Nueva Cabecera para Hojas:</label>
                                    <img src={newPhotoPreview.cabeceraHojas} alt="Nueva Cabecera para Hojas" style={{ width: '100px', height: '100px' }} />
                                </>
                            )}
                        </div>
                        <div>
                            <label>Cabecera para Portal Actual:</label>
                            {datgens[0]?.dato5 && (
                                <img src={getImageUrl(datgens[0].dato5)} alt="Cabecera para Portal Actual" style={{ width: '100px', height: '100px' }} />
                            )}
                            {newPhotoPreview.cabeceraPortal && (
                                <>
                                    <label>Nueva Cabecera para Portal:</label>
                                    <img src={newPhotoPreview.cabeceraPortal} alt="Nueva Cabecera para Portal" style={{ width: '100px', height: '100px' }} />
                                </>
                            )}
                        </div>
                    </div>
                )}
                <div className='button-group'>
                    <button className="button btn-cancel" onClick={() => navigate('/')} style={{ marginLeft: '10px' }}>
                   Cancelar
                    </button>
                    <button className="button btn-accent" type="submit" disabled={loading}>
                     <i className="bi bi-save"></i>  {loading ? 'Actualizando...' : 'Actualizar Datos'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ActualizarDatosGenerales;
