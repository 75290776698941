// cuotaActions.js
import cuotaService from '../services/cuotaService';

export const FETCH_CUOTAS_REQUEST = 'FETCH_CUOTAS_REQUEST';
export const FETCH_CUOTAS_SUCCESS = 'FETCH_CUOTAS_SUCCESS';
export const FETCH_CUOTAS_FAILURE = 'FETCH_CUOTAS_FAILURE';

export const UPDATE_CUOTA_REQUEST = 'UPDATE_CUOTA_REQUEST';
export const UPDATE_CUOTA_SUCCESS = 'UPDATE_CUOTA_SUCCESS';
export const UPDATE_CUOTA_FAILURE = 'UPDATE_CUOTA_FAILURE';

export const FETCH_CUOTAS_BY_RECIBO_REQUEST = 'FETCH_CUOTAS_BY_RECIBO_REQUEST';
export const FETCH_CUOTAS_BY_RECIBO_SUCCESS = 'FETCH_CUOTAS_BY_RECIBO_SUCCESS';
export const FETCH_CUOTAS_BY_RECIBO_FAILURE = 'FETCH_CUOTAS_BY_RECIBO_FAILURE';

export const GENERAR_CUOTAS_REQUEST = 'GENERAR_CUOTAS_REQUEST';
export const GENERAR_CUOTAS_SUCCESS = 'GENERAR_CUOTAS_SUCCESS';
export const GENERAR_CUOTAS_FAILURE = 'GENERAR_CUOTAS_FAILURE';

export const FETCH_CUOTAS_PENDIENTES_REQUEST ='FETCH_CUOTAS_PENDIENTES_REQUEST';
export const FETCH_CUOTAS_PENDIENTES_SUCCESS = 'FETCH_CUOTAS_PENDIENTES_SUCCESS';
export const FETCH_CUOTAS_PENDIENTES_FAILURE ='FETCH_CUOTAS_PENDIENTES_FAILURE';

export const CLEAR_CUOTAS = 'CLEAR_CUOTAS';

export const clearCuotas = () => ({
  type: CLEAR_CUOTAS,
});


export const fetchCuotasPendientes = (ci, fecha) => async (dispatch) => {
  dispatch({ type: FETCH_CUOTAS_PENDIENTES_REQUEST });
  console.log("En action de pendientes con ",ci,fecha)
  try {
      const data = await cuotaService.fetchCuotasPendientes(ci, fecha);
      dispatch({ type: FETCH_CUOTAS_PENDIENTES_SUCCESS, payload: data });

  } catch (error) {
      dispatch({ type: FETCH_CUOTAS_PENDIENTES_FAILURE, payload: error.message });
  }
};

export const generarCuotas = (fecha) => async (dispatch) => {
    dispatch({ type: GENERAR_CUOTAS_REQUEST });
    try {
      const response = await cuotaService.generarCuotas(fecha);
      dispatch({ type: GENERAR_CUOTAS_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({
        type: GENERAR_CUOTAS_FAILURE,
        payload: error.response?.data?.message || error.message,
      });
    }
  };

// Acción para obtener todas las cuotas de un socio
export const fetchCuotasBySocio = (socioId) => async (dispatch) => {
    dispatch({ type: FETCH_CUOTAS_REQUEST });
    try {
        const response = await cuotaService.fetchCuotasBySocio(socioId);
        dispatch({ type: FETCH_CUOTAS_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: FETCH_CUOTAS_FAILURE, payload: error.message });
    }
};

// Acción para actualizar una cuota existente
export const updateCuota = (id, cuotaData) => async (dispatch) => {
    dispatch({ type: UPDATE_CUOTA_REQUEST });
    //console.log("Datos recibidos en updateCuota (cuotaData):",id, cuotaData);

    try {
        const response = await cuotaService.updateCuota(id, cuotaData);
        dispatch({ type: UPDATE_CUOTA_SUCCESS, payload: response.data });
        return response.data;
    } catch (error) {
        dispatch({ type: UPDATE_CUOTA_FAILURE, payload: error.message });
        return Promise.reject(error);
    }
};

// Acción para obtener las cuotas por idrecibo
export const fetchCuotasByRecibo = (idRecibo) => async (dispatch) => {
    dispatch({ type: FETCH_CUOTAS_BY_RECIBO_REQUEST });
    try {
        const response = await cuotaService.fetchCuotasByRecibo(idRecibo);
        dispatch({ type: FETCH_CUOTAS_BY_RECIBO_SUCCESS, payload: response.data });
        return response.data; // Aquí retornamos los datos para asegurar que sea una promesa
    } catch (error) {
        dispatch({ type: FETCH_CUOTAS_BY_RECIBO_FAILURE, payload: error.message });
        throw error; // Aseguramos que el error se propague como una promesa rechazada
    }
};