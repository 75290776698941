import {
    FETCH_ROLES_SUCCESS,
    FETCH_ROLES_FAILURE,
    FETCH_ROL_SUCCESS,
    FETCH_ROL_FAILURE,
    CREATE_ROL_SUCCESS,
    CREATE_ROL_FAILURE,
    UPDATE_ROL_SUCCESS,
    UPDATE_ROL_FAILURE,
    DELETE_ROL_SUCCESS,
    DELETE_ROL_FAILURE
} from '../actions/rolActions';

const initialState = {
    roles: [],
    currentRol: null,
    error: null
};

const rolReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ROLES_SUCCESS:
            return {
                ...state,
                roles: action.payload,
                error: null
            };
        case FETCH_ROLES_FAILURE:
        case FETCH_ROL_FAILURE:
        case CREATE_ROL_FAILURE:
        case UPDATE_ROL_FAILURE:
        case DELETE_ROL_FAILURE:
            return {
                ...state,
                error: action.payload
            };
        case FETCH_ROL_SUCCESS:
            return {
                ...state,
                currentRol: action.payload,
                error: null
            };
        case CREATE_ROL_SUCCESS:
            return {
                ...state,
                roles: [...state.roles, action.payload],
                error: null
            };
        case UPDATE_ROL_SUCCESS:
            return {
                ...state,
                roles: state.roles.map(rol =>
                    rol.id === action.payload.id ? action.payload : rol
                ),
                currentRol: action.payload,
                error: null
            };
        case DELETE_ROL_SUCCESS:
            return {
                ...state,
                roles: state.roles.filter(rol => rol.id !== action.payload),
                error: null
            };
        default:
            return state;
    }
};

export default rolReducer;

