// actions/reciboActions.js
import reciboService from '../services/reciboService';

export const FETCH_RECIBOS_REQUEST = 'FETCH_RECIBOS_REQUEST';
export const FETCH_RECIBOS_SUCCESS = 'FETCH_RECIBOS_SUCCESS';
export const FETCH_RECIBOS_FAILURE = 'FETCH_RECIBOS_FAILURE';

export const CREATE_RECIBO_REQUEST = 'CREATE_RECIBO_REQUEST';
export const CREATE_RECIBO_SUCCESS = 'CREATE_RECIBO_SUCCESS';
export const CREATE_RECIBO_FAILURE = 'CREATE_RECIBO_FAILURE';


export const FETCH_RECIBOS_SOCIO_REQUEST = 'FETCH_RECIBOS_SOCIO_REQUEST';
export const FETCH_RECIBOS_SOCIO_SUCCESS = 'FETCH_RECIBOS_SOCIO_SUCCESS';
export const FETCH_RECIBOS_SOCIO_FAILURE = 'FETCH_RECIBOS_SOCIO_FAILURE';

export const fetchRecibosBySocio = (socioId) => async (dispatch) => {
    dispatch({ type: FETCH_RECIBOS_SOCIO_REQUEST });
    try {
        const response = await reciboService.fetchRecibosBySocio(socioId);
        dispatch({ type: FETCH_RECIBOS_SOCIO_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: FETCH_RECIBOS_FAILURE, payload: error.message });
    }
};

// Acción para obtener todos los recibos
export const fetchRecibos = () => async (dispatch) => {
    dispatch({ type: FETCH_RECIBOS_REQUEST });
    try {
        const response = await reciboService.fetchRecibos();
        dispatch({ type: FETCH_RECIBOS_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: FETCH_RECIBOS_FAILURE, payload: error.message });
    }
};

// Acción para crear un nuevo recibo
export const createRecibo = (reciboData) => async (dispatch) => {
    dispatch({ type: CREATE_RECIBO_REQUEST });
    //console.log("Entre a action de recibo con:",reciboData)
    try {
        const response = await reciboService.createRecibo(reciboData);
        dispatch({ type: CREATE_RECIBO_SUCCESS, payload: response.data });
        return response.data;
    } catch (error) {
        dispatch({ type: CREATE_RECIBO_FAILURE, payload: error.message });
        return Promise.reject(error);
    }
};