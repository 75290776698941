import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRecibosBySocio } from '../actions/reciboActions';
import { fetchCuotasByRecibo } from '../actions/cuotaActions';
import { Modal, Button } from 'react-bootstrap';
import ReciboModal from './ReciboModal'; // Asegúrate de ajustar la ruta según tu estructura de archivos
import './GestionRecibos.css'; // Asegúrate de tener un archivo CSS para los estilos

const GestionRecibos = ({ showGestionRecibosModal, closeGestionRecibosModal, socio }) => {
  const dispatch = useDispatch();
  const recibosState = useSelector(state => state.recibo);
  const { recibos, loading: loadingRecibos, error: errorRecibos } = recibosState;
  const cuotasState = useSelector(state => state.cuota);
  const { cuotas, loading: loadingCuotas, error: errorCuotas } = cuotasState;
  const [showReciboModal, setShowReciboModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reciboData, setReciboData] = useState({
    socio: null,
    cuotas: [],
    reciboNumero: null,
    reciboFecha: null
  });

  useEffect(() => {
    if (socio && socio.id) {
      dispatch(fetchRecibosBySocio(socio.id));
    }
  }, [dispatch, socio]);

  useEffect(() => {
    if (!showGestionRecibosModal) {
      setReciboData({
        socio: null,
        cuotas: [],
        reciboNumero: null,
        reciboFecha: null
      });
    }
  }, [showGestionRecibosModal]);

  useEffect(() => {
    if (isLoading && !loadingCuotas) {
      const formattedCuotas = (cuotas || []).map(cuota => ({
        ...cuota,
        fechaEmision: cuota.fechaEmision,// ? new Date(cuota.fechaEmision) : null,
        fechaPago: cuota.fechaPago// ? new Date(cuota.fechaPago) : null
      }));

      setReciboData(prevState => ({
        ...prevState,
        cuotas: formattedCuotas
      }));
      ////console.log("CUotas=",cuotas)
      setShowReciboModal(true);
      setIsLoading(false);
    }
  }, [cuotas, loadingCuotas, isLoading]);

  const handleOpenReciboModal = async (recibo) => {
    // Limpiar los datos antes de hacer el dispatch
    setReciboData({
      socio: socio,
      cuotas: [],
      reciboNumero: recibo.id,
      reciboFecha: new Date(recibo.fecha)
    });

    setIsLoading(true);
    await dispatch(fetchCuotasByRecibo(recibo.id));
    ////console.log("cuotas=",cuotas)
  };

  const handleCloseReciboModal = () => {
    setShowReciboModal(false);
    setReciboData({
      socio: null,
      cuotas: [],
      reciboNumero: null,
      reciboFecha: null
    });
  };

  if (loadingRecibos) return <div>Cargando recibos...</div>;
  if (errorRecibos) return <div>Error al cargar los datos: {errorRecibos}</div>;

  return (
    <>
      <Modal show={showGestionRecibosModal} onHide={closeGestionRecibosModal} size="lg" backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Gestión de Recibos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="socio-info">
            <p>{`Código Registro: ${socio.codreg}, Nombre: ${socio.nombres} ${socio.apPat} ${socio.apMat}`}</p>
          </div>
          <div className="recibos-list">
            {recibos && recibos.sort((a, b) => a.id - b.id).map(recibo => (
              <div key={recibo.id} className="recibo-item">
                <span className="recibo-details">Fecha: {new Date(recibo.fecha).toLocaleDateString()}</span>
                <span className="recibo-details">Recibo Nro: {recibo.id}</span>
                <Button variant="primary" onClick={() => handleOpenReciboModal(recibo)}>Imprimir</Button>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeGestionRecibosModal}>Cerrar</Button>
        </Modal.Footer>
      </Modal>

      <ReciboModal
        show={showReciboModal}
        handleClose={handleCloseReciboModal}
        socio={reciboData.socio}
        cuotas={reciboData.cuotas}
        reciboNumero={reciboData.reciboNumero}
        reciboFecha={reciboData.reciboFecha}
        copia={true}
      />
    </>
  );
};

export default GestionRecibos;

