import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createDirectiva, updateDirectiva, fetchDirectiva } from '../actions/directivaActions';
import { Modal, Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

const DirectivaForm = ({ show, handleClose, isEdit, selectedId, onSave }) => {
    const dispatch = useDispatch();
    const { currentDirectiva, loading } = useSelector(state => state.directiva);
    const [directivaData, setDirectivaData] = useState({
        nombre: '',
        feposes: '',
        fefinal: '',
        comite: false
    });

    useEffect(() => {
        if (isEdit && selectedId) {
            dispatch(fetchDirectiva(selectedId));
        }
    }, [dispatch, isEdit, selectedId]);

    useEffect(() => {
        if (currentDirectiva && isEdit) {
            setDirectivaData({
                nombre: currentDirectiva.nombre || '',
                feposes: currentDirectiva.feposes ? currentDirectiva.feposes.split('T')[0] : '',
                fefinal: currentDirectiva.fefinal ? currentDirectiva.fefinal.split('T')[0] : '',
                comite: currentDirectiva.comite || false
            });
        }
    }, [currentDirectiva, isEdit]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setDirectivaData({
            ...directivaData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isEdit) {
            await dispatch(updateDirectiva(selectedId, directivaData));
            toast.success('Directiva actualizada correctamente');
        } else {
            await dispatch(createDirectiva(directivaData));
            toast.success('Directiva creada correctamente');
        }
        handleClose();
        onSave();
    };

    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>{isEdit ? 'Editar Directiva' : 'Crear Directiva'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading && isEdit ? (
                    <p>Cargando...</p>
                ) : (
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="nombre">
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                                type="text"
                                name="nombre"
                                value={directivaData.nombre}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="feposes">
                            <Form.Label>Fecha de Posesión</Form.Label>
                            <Form.Control
                                type="date"
                                name="feposes"
                                value={directivaData.feposes}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="fefinal">
                            <Form.Label>Fecha Final</Form.Label>
                            <Form.Control
                                type="date"
                                name="fefinal"
                                value={directivaData.fefinal}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="comite">
                            <Form.Check
                                type="checkbox"
                                name="comite"
                                label="Comité"
                                checked={directivaData.comite}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <div className='button-group'>
                            <button className="button btn-cancel" onClick={handleClose} style={{ marginLeft: '10px' }}>
                                Cancelar
                            </button>
                            <button className="button btn-accent" type="submit">
                                Guardar
                            </button>
                        </div>
                    </Form>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default DirectivaForm;
