import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createPais, updatePais } from '../actions/paisActions';
import { toast } from 'react-toastify';

const PaisForm = ({ pais, onClose, onSave }) => {
  const dispatch = useDispatch();
  const [paisData, setPaisData] = useState({ nombre: '' });

  useEffect(() => {
    if (pais) {
      setPaisData({ nombre: pais.nombre });
    } else {
      setPaisData({ nombre: '' });
    }
  }, [pais]);

  const handleChange = (e) => {
    const newNombre = e.target.value; // Convertir a mayúsculas
    setPaisData({ ...paisData, nombre: newNombre });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!paisData.nombre) {
      toast.error("Por favor complete los campos requeridos: nombre");
      return;
    }
    const dataToSend = {
      ...paisData,
      nombre: paisData.nombre.toUpperCase()
    };

    if (pais) {
      dispatch(updatePais(pais.id, dataToSend))
        .then(() => {
          toast.success('País actualizado correctamente');
          onClose();
          onSave(); // Llamar a la función onSave para actualizar la lista
        })
        .catch(error => {
          toast.error(`Error al actualizar el país: ${error.message || error}`);
        });
    } else {
      dispatch(createPais(dataToSend))
        .then(() => {
          toast.success('País creado correctamente');
          setPaisData({ nombre: '' });
          onClose();
          onSave(); // Llamar a la función onSave para actualizar la lista
        })
        .catch(error => {
          toast.error(`Error al crear el país: ${error.message || error}`);
        });
    }
  };

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <label>Nombre del País</label>
        <input
          type="text"
          value={paisData.nombre}
          onChange={handleChange}
          required
        />
        <div className='button-group'>
          <button className="button btn-cancel" onClick={onClose} style={{ marginLeft: '10px' }}>
            Cancelar
          </button>
          <button className="button btn-accent" type="submit">
            Guardar
          </button>
        </div>
      </form>
  );
};

export default PaisForm;
