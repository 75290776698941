// reducers/reciboReducer.js
import {
    FETCH_RECIBOS_REQUEST,
    FETCH_RECIBOS_SUCCESS,
    FETCH_RECIBOS_FAILURE,
    CREATE_RECIBO_REQUEST,
    CREATE_RECIBO_SUCCESS,
    CREATE_RECIBO_FAILURE,
    FETCH_RECIBOS_SOCIO_REQUEST,
    FETCH_RECIBOS_SOCIO_SUCCESS,
    FETCH_RECIBOS_SOCIO_FAILURE

} from '../actions/reciboActions';

const initialState = {
    recibos: [],
    loading: false,
    error: null
};

const reciboReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RECIBOS_REQUEST:
        case FETCH_RECIBOS_SOCIO_REQUEST:
        case CREATE_RECIBO_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_RECIBOS_SOCIO_SUCCESS:
        case FETCH_RECIBOS_SUCCESS:
            return {
                ...state,
                loading: false,
                recibos: action.payload,
                error: null
            };
        case FETCH_RECIBOS_FAILURE:
        case FETCH_RECIBOS_SOCIO_FAILURE:    
        case CREATE_RECIBO_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case CREATE_RECIBO_SUCCESS:
            return {
                ...state,
                loading: false,
                recibos: [...state.recibos, action.payload],
                error: null
            };
        default:
            return state;
    }
};

export default reciboReducer;

