import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsuarioById, updatePassword } from '../actions/usuarioActions';
import { Modal, Button, Form, Image } from 'react-bootstrap';
import { toast } from 'react-toastify';
import placeholder from '../assets/user-profile-photo.png';
import { getAuthToken } from '../util/auth';
import 'bootstrap-icons/font/bootstrap-icons.css';

const UsuarioClave = ({ show, handleClose, selectedId }) => {
    const dispatch = useDispatch();
    const { currentUsuario, error, success } = useSelector(state => state.usuario);
    const [usuarioData, setUsuarioData] = useState({ usuario: '', habilitado: false, foto: null });
    const [fotoPreview, setFotoPreview] = useState(placeholder);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [showPasswords, setShowPasswords] = useState(false);
    const UPLOADS_BASE_URL = process.env.REACT_APP_UPLOADS_BASE_URL;

    useEffect(() => {
        if (selectedId) {
            dispatch(fetchUsuarioById(selectedId));
        }
    }, [dispatch, selectedId]);

    useEffect(() => {
        if (currentUsuario && currentUsuario.id === selectedId) {
            setUsuarioData({
                usuario: currentUsuario.usuario,
                habilitado: currentUsuario.habilitado,
                foto: currentUsuario.foto
            });
            const token = getAuthToken();
            const timestamp = new Date().getTime();

            if (currentUsuario.foto) {
                const fotoUrl = `${UPLOADS_BASE_URL}/${currentUsuario.foto}?token=${token}&timestamp=${timestamp}`;
                setFotoPreview(fotoUrl);
            }
        }
    }, [currentUsuario, selectedId, UPLOADS_BASE_URL]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'oldPassword') {
            setOldPassword(value);
        } else if (name === 'newPassword') {
            setNewPassword(value);
        } else if (name === 'confirmNewPassword') {
            setConfirmNewPassword(value);
        }
    };

    const toggleShowPasswords = () => {
        setShowPasswords(!showPasswords);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmNewPassword) {
            toast.error('Las nuevas contraseñas no coinciden.');
            return;
        }

        if (newPassword === oldPassword) {
            toast.error('La nueva contraseña no puede ser igual a la contraseña anterior.');
            return;
        }

        try {
            const response=await dispatch(updatePassword(selectedId, { oldPassword, newPassword }));
            //console.log("Response del back", response )
                toast.success('Contraseña actualizada correctamente.');
                handleClose();
            
        } catch (error) {
            toast.error(`Error al actualizar la contraseña: ${error.message || error}`);
            handleClose();
        }
    };

    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Cambiar Contraseña</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <div className="d-flex justify-content-between">
                        <div style={{ flex: 1 }}>
                            <Form.Group controlId="usuario">
                                <Form.Label>Usuario:</Form.Label>
                                <span style={{ fontSize: '1.5rem', color: 'darkgreen', fontWeight: 'bold' }}>
                                    {usuarioData.usuario}
                                </span>
                            </Form.Group>
                            <Form.Group controlId="oldPassword">
                                <Form.Label>Contraseña Anterior</Form.Label>
                                <div className="input-group">
                                    <Form.Control
                                        type={showPasswords ? "text" : "password"}
                                        name="oldPassword"
                                        value={oldPassword}
                                        onChange={handleChange}
                                        required
                                        autoComplete="current-password"
                                    />
                                    <Button variant="outline-secondary" onClick={toggleShowPasswords}>
                                        <i className={`bi ${showPasswords ? 'bi-eye-slash' : 'bi-eye'}`}></i>
                                    </Button>
                                </div>
                            </Form.Group>
                            <Form.Group controlId="newPassword">
                                <Form.Label>Nueva Contraseña</Form.Label>
                                <Form.Control
                                    type={showPasswords ? "text" : "password"}
                                    name="newPassword"
                                    value={newPassword}
                                    onChange={handleChange}
                                    required
                                    autoComplete="new-password"
                                />
                            </Form.Group>
                            <Form.Group controlId="confirmNewPassword">
                                <Form.Label>Confirmar Nueva Contraseña</Form.Label>
                                <Form.Control
                                    type={showPasswords ? "text" : "password"}
                                    name="confirmNewPassword"
                                    value={confirmNewPassword}
                                    onChange={handleChange}
                                    required
                                    autoComplete="new-password"
                                />
                            </Form.Group>
                        </div>
                        <div style={{ flex: 1, textAlign: 'center' }}>
                            {fotoPreview && typeof fotoPreview === 'string' && (
                                <div>
                                    <Image 
                                        src={fotoPreview} 
                                        alt="Foto previa" 
                                        style={{ width: '200px', height: '200px', border: '1px solid #ccc', padding: '10px' }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="button-group">
                        <button onClick={handleClose} className="button btn-cancel me-5">

                            Cancelar
                        </button>
                        <button type="submit" className="button btn-accent">
                            <i className="bi bi-save"></i> 
                            Guardar
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default UsuarioClave;


