import { getAuthToken } from "../util/auth";
import createAxiosInstance, {API_URL_BASE} from "../util/axiosInstance";

const api = createAxiosInstance('/socios');

// Definir las funciones de API usando la instancia de Axios configurada

const getSocioPhoto = async (id) => {
    const response = await api.get(`/photo/${id}`, {
      responseType: 'blob' // Importante para manejar imágenes
    });
    return response;
  };
 
  
const getFotoUrl = (id) => {
  const token = getAuthToken(); // Obtener el token de autenticación
  const timestamp = new Date().getTime(); // Obtener una marca de tiempo actual

  // Construir la URL de la imagen con el token como parámetro de consulta
  return `${API_URL_BASE}/socios/photo/${id}?token=${token}&timestamp=${timestamp}`; };

const fetchSocios = () => api.get('/');
const fetchSocio = (id) => api.get(`/${id}`);

const createSocio = (data) => api.post('/', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

const updateSocio = (id, data) => {
    return api.put(`/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  };
  
//const deleteSocio = (id) => api.delete(`/${id}`);

const deleteSocio = async (id) => {
  try {
    const response = await api.delete(`/${id}`);
    return response.data;
  } catch (error) {
    // Manejo de errores
    console.error("Error al eliminar socio:", error);
    throw error;
  }
};


const getMaxCodreg = async () => {
  const response = await api.get(`/maxcodreg`,{});
  //console.log("Respondiendo el service...",response, response.data);
  return response.data;
};

export default {
    fetchSocios,
    fetchSocio,
    createSocio,
    updateSocio,
    deleteSocio,
    getMaxCodreg,
    getFotoUrl,
    getSocioPhoto
};