import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchDirectivas, deleteDirectiva, updateDirectiva } from '../actions/directivaActions';
import { getAllDirecsoc } from '../actions/direcsocActions';
import { fetchSocios } from '../actions/socioActions';
import { fetchCargos } from '../actions/cargoActions';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import DirectivaForm from './DirectivaForm';
import DirectivaSocio from './DirectivaSocio';
//import DirectivaEstado from './DirectivaEstado';
import { Table, Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import CustomPrintModal from './CustomPrintModal';
import { FaExclamationTriangle } from 'react-icons/fa';
import './DirectivaList.css';

const DirectivaList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
   
    const { titulo, funcionalidad } = useParams();

    const { directivas, loading, error } = useSelector(state => state.directiva);
    const { direcsoc } = useSelector(state => state.direcsoc);
    const { socios } = useSelector(state => state.socio);
    const { cargos } = useSelector(state => state.cargo);

    const [deleteError, setDeleteError] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [showSocio, setShowSocio] = useState(false);
    const [showEstado, setShowEstado] = useState(false);
    const [showPrintModal, setShowPrintModal] = useState(false);
    const [content, setContent] = useState(null);
    const [showPrintSociosModal, setShowPrintSociosModal] = useState(false);
    const [contentSocios, setContentSocios] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [selectedDirectiva, setSelectedDirectiva] = useState(null);
    const [isLoadError, setIsLoadError] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [pendingDirectiva, setPendingDirectiva] = useState(null);

    useEffect(() => {
        dispatch(fetchDirectivas());
        dispatch(fetchSocios());
        dispatch(fetchCargos());
        dispatch(getAllDirecsoc());
    }, [dispatch]);

    const handleDelete = (id) => {
        confirmAlert({
            title: 'Confirmar eliminación',
            message: '¿Estás seguro de que deseas eliminar esta directiva?',
            buttons: [
                {
                    label: 'Sí',
                    onClick: async () => {
                        try {
                            setIsLoadError(false);
                            setDeleteError(null);
                            await dispatch(deleteDirectiva(id));
                            toast.success('Directiva eliminada correctamente');
                            dispatch(fetchDirectivas());
                        } catch (error) {
                            setDeleteError(error.message);
                            if (error.response && error.response.status === 400) {
                                toast.error('No se puede eliminar la directiva debido a una restricción de llave extranjera.');
                            } else {
                                toast.error(`Error al eliminar la directiva: ${error.message}`);
                            }
                        }
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    };

    const handleShowForm = (id = null) => {
        setSelectedId(id);
        setIsEdit(!!id);
        setShowForm(true);
    };

    const handleShowEstado = (directiva) => {
        setSelectedDirectiva(directiva);
        setShowEstado(true);
    };

    const handleShowSocio = (directiva) => {
        setSelectedDirectiva(directiva);
        setShowSocio(true);
    };

    const handleCloseForm = () => {
        setShowForm(false);
        setSelectedId(null);
        setIsEdit(false);
    };

    const handleCloseEstado = () => {
        setShowEstado(false);
        setSelectedDirectiva(null);
    };

    const handleCloseSocio = () => {
        setShowSocio(false);
        setSelectedDirectiva(null);
    };

    const handlePrintDirectivas = () => {
        const content = {
            head: [["Nombre", "Fecha de Posesión", "Fecha Final", "Estado"]],
            body: directivas.map(directiva => [
                directiva.nombre,
                directiva.feposes ? new Date(directiva.feposes).toLocaleDateString() : '',
                directiva.fefinal ? new Date(directiva.fefinal).toLocaleDateString() : '',
                directiva.estado === 1 ? 'Activa' : '--'
            ])
        };
        setContent(content);
        setShowPrintModal(true);
    };

    const handlePrintSocios = (directiva) => {
       
        const directivaId=directiva.id;
        const sociosFiltrados = direcsoc.filter(socio => socio.directiva === directivaId);
        const contentSocios = {
            head: [["N°", "Nombre", "Cargo"]],
            body: sociosFiltrados.map((socio, index) => {
                const socioData = socios.find(s => s.id === socio.idcodreg);
                const cargoData = cargos.find(c => c.id === socio.cargo);

                return [
                    index+1,
                    socioData ? `${socioData.apPat} ${socioData.apMat} ${socioData.nombres}` : '',
                    cargoData ? cargoData.cargo : ''
                ];
            })
        };
        setContentSocios(contentSocios);
        setSelectedDirectiva(directiva);
        ////console.log("DIrectiva=",directiva,selectedDirectiva)
        setShowPrintSociosModal(true);
    };

    const handlePrintModalClose = () => {
        setShowPrintModal(false);
    };

    const handlePrintSociosModalClose = () => {
        setShowPrintSociosModal(false);
    };

    const handleHabilitarDirectiva = async (id) => {
        const selectedDirectiva = directivas.find(d => d.id === id);
        if (!selectedDirectiva) {
            toast.error('Directiva no encontrada.');
            return;
        }

        const fechaActual = new Date();
        const fechaPosesion = new Date(selectedDirectiva.feposes);
        const fechaFinal = new Date(selectedDirectiva.fefinal);

        if (fechaActual < fechaPosesion || fechaActual > fechaFinal) {
            setShowAlert(true);
            setPendingDirectiva(null);
            return;
        }

        setPendingDirectiva(selectedDirectiva);
        setShowAlert(true);
    };

    const confirmHabilitarDirectiva = async () => {
        if (!pendingDirectiva) return;

        try {
            // Deshabilitar todas las directivas activas
            const disablePromises = directivas
                .filter(d => d.estado === 1)
                .map(d => dispatch(updateDirectiva(d.id, { estado: 0 })));

            // Habilitar la directiva seleccionada
            const enablePromise = dispatch(updateDirectiva(pendingDirectiva.id, { estado: 1 }));

            // Esperar todas las promesas
            await Promise.all([...disablePromises, enablePromise]);

            toast.success('Directiva habilitada correctamente');
            dispatch(fetchDirectivas());
        } catch (error) {
            toast.error('Error al habilitar la directiva: ' + error.message);
        } finally {
            setShowAlert(false);
            setPendingDirectiva(null);
        }
    };

    const handleCloseAlert = () => {
        setShowAlert(false);
        setPendingDirectiva(null);
    };

    if (loading && !showForm && !showSocio) return <p>Cargando directivas...</p>;
    if (isLoadError) return <p className='error-message'>Error al cargar los datos: {error}</p>;

    // Ordenar directivas por fecha de posesión en orden descendente
    const sortedDirectivas = [...directivas].sort((a, b) => new Date(b.feposes) - new Date(a.feposes));

    return (
        <div className="list10-container container-list">
            <h2>{titulo}</h2>
            {funcionalidad === '1' && (
                <div className="mt-3">
                    <button className="button btn-accent float-end" onClick={() => handleShowForm()}>
                        <i className="bi bi-plus-lg"></i> Nueva Directiva
                    </button>
                </div>
            )}
            <div className='table-container'>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th className="cod">N°</th>
                            <th className="nombre">Nombre</th>
                            <th className="fecha-posesion">Fecha de Posesión</th>
                            <th className="fecha-final">Fecha Final</th>
                            <th className="estado">Estado</th>
                            <th className="acciones">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedDirectivas.map((directiva, index) => (
                            <tr key={directiva.id}>
                                 <td className="nombre">{index + 1}</td>
                                <td className="nombre">{directiva.nombre}</td>
                                <td className="fecha-posesion">{directiva.feposes ? new Date(directiva.feposes).toLocaleDateString() : ''}</td>
                                <td className="fecha-final">{directiva.fefinal ? new Date(directiva.fefinal).toLocaleDateString() : ''}</td>
                                <td className="estado">{directiva.estado === 1 ? 'Activa' : '--'}</td>
                                <td>
                                    {funcionalidad === '1' && (
                                        <>
                                            <button
                                                onClick={() => handleShowForm(directiva.id)}
                                                className="button btn-accent-outline"
                                            >
                                                <i className="bi bi-pencil"></i>
                                            </button>
                                            <button
                                                onClick={() => handleDelete(directiva.id)}
                                                className="button btn-accent-outline m-1"
                                            >
                                                <i className="bi bi-trash"></i>
                                            </button>
                                            <button
                                                onClick={() => handleShowSocio(directiva)}
                                                className="button btn btn-outline-secondary m-1"
                                            >
                                                Miembros
                                            </button>
                                            <button
                                                onClick={() => handlePrintSocios(directiva)}
                                                className="btn btn-outline-secondary"
                                            >
                                                Imprimir
                                            </button>
                                        </>
                                    )}
                                    {funcionalidad === '2' && (
                                        <button
                                            onClick={() => handleHabilitarDirectiva(directiva.id)}
                                            className="button btn-accent-outline"
                                        >
                                            Habilitar
                                        </button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>

            <div className='text-center mb-3 button-group'>
                <button className="button btn-cancel" onClick={() => navigate("/")}> <i className="bi bi-arrow-left"></i> Volver</button>
                {funcionalidad === '1' && <button className="button btn-accent" onClick={handlePrintDirectivas}><i className="bi bi-printer"></i> Imprimir Lista</button>}
            </div>

            {showForm && (
                <DirectivaForm
                    show={showForm}
                    handleClose={handleCloseForm}
                    isEdit={isEdit}
                    selectedId={selectedId}
                    onSave={() => dispatch(fetchDirectivas())}
                />
            )}

            {showSocio && (
                <DirectivaSocio
                    show={showSocio}
                    handleClose={handleCloseSocio}
                    directiva={selectedDirectiva}
                />
            )}

            <CustomPrintModal show={showPrintModal} handleClose={handlePrintModalClose} title="Listado de Directivas" content={content} />
            <CustomPrintModal show={showPrintSociosModal} handleClose={handlePrintSociosModalClose} title={`Miembros de la Directiva - ${selectedDirectiva ? selectedDirectiva.nombre : " "}`} content={contentSocios} />

            {deleteError && <p>Error al eliminar la directiva: {deleteError}</p>}

            <Modal show={showAlert} onHide={handleCloseAlert}>
                <Modal.Header closeButton>
                    <Modal.Title>Alerta</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                        <FaExclamationTriangle style={{ color: 'red', marginRight: '5px', fontSize: '3em' }} />
                        <span style={{ fontSize: '1.5em', color: 'darkgreen', fontWeight: 'bold', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)' }}>Alerta:</span>
                    </div>
                    <p>
                        {pendingDirectiva ? '¿Está seguro que desea habilitar esta directiva? Esto deshabilitará cualquier otra directiva actualmente habilitada.' : 'No se puede activar la directiva fuera del periodo de posesión.'}
                    </p>
                </Modal.Body>
                <Modal.Footer className='button-group'>
                    <button className="button btn-cancel" onClick={handleCloseAlert}>
                        Cancelar
                    </button>
                    {pendingDirectiva && (
                        <button className="button btn-accent" onClick={confirmHabilitarDirectiva}>
                            Confirmar
                        </button>
                    )}
                   
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default DirectivaList;
