import rolService from '../services/rolService';

export const FETCH_ROLES_REQUEST = 'FETCH_ROLES_REQUEST';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_FAILURE = 'FETCH_ROLES_FAILURE';

export const FETCH_ROL_REQUEST = 'FETCH_ROL_REQUEST';
export const FETCH_ROL_SUCCESS = 'FETCH_ROL_SUCCESS';
export const FETCH_ROL_FAILURE = 'FETCH_ROL_FAILURE';

export const CREATE_ROL_REQUEST = 'CREATE_ROL_REQUEST';
export const CREATE_ROL_SUCCESS = 'CREATE_ROL_SUCCESS';
export const CREATE_ROL_FAILURE = 'CREATE_ROL_FAILURE';

export const UPDATE_ROL_REQUEST = 'UPDATE_ROL_REQUEST';
export const UPDATE_ROL_SUCCESS = 'UPDATE_ROL_SUCCESS';
export const UPDATE_ROL_FAILURE = 'UPDATE_ROL_FAILURE';

export const DELETE_ROL_REQUEST = 'DELETE_ROL_REQUEST';
export const DELETE_ROL_SUCCESS = 'DELETE_ROL_SUCCESS';
export const DELETE_ROL_FAILURE = 'DELETE_ROL_FAILURE';

export const fetchRoles = () => async (dispatch) => {
  dispatch({ type: FETCH_ROLES_REQUEST });
  try {
    const response = await rolService.fetchRoles();
    dispatch({ type: FETCH_ROLES_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_ROLES_FAILURE, payload: error.message });
  }
};

export const fetchRol = (id) => async (dispatch) => {
  dispatch({ type: FETCH_ROL_REQUEST });
  try {
    const response = await rolService.fetchRol(id);
    dispatch({ type: FETCH_ROL_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_ROL_FAILURE, payload: error.message });
  }
};

export const createRol = (RolData) => async (dispatch) => {
  dispatch({ type: CREATE_ROL_REQUEST });
  try {
    const response = await rolService.createRol(RolData);
    dispatch({ type: CREATE_ROL_SUCCESS, payload: response.data });
    return response.data;
  } catch (error) {
    dispatch({ type: CREATE_ROL_FAILURE, payload: error.message });
    return Promise.reject(error);
  }
};

export const updateRol = (id, RolData) => async (dispatch) => {
  dispatch({ type: UPDATE_ROL_REQUEST });
  try {
    const response = await rolService.updateRol(id, RolData);
    dispatch({ type: UPDATE_ROL_SUCCESS, payload: response.data });
    return response.data;
  } catch (error) {
    dispatch({ type: UPDATE_ROL_FAILURE, payload: error.message });
    return Promise.reject(error);
  }
};

export const deleteRol = (id) => async (dispatch) => {
  dispatch({ type: DELETE_ROL_REQUEST });
  try {
    await rolService.deleteRol(id);
    dispatch({ type: DELETE_ROL_SUCCESS, payload: id });
  } catch (error) {
    dispatch({ type: DELETE_ROL_FAILURE, payload: error.message });
    return Promise.reject(error);
  }
};