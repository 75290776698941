// src/actions/cargoActions.js
import cargoService from '../services/cargoService';

export const FETCH_CARGOS_REQUEST = 'FETCH_CARGOS_REQUEST';
export const FETCH_CARGOS_SUCCESS = 'FETCH_CARGOS_SUCCESS';
export const FETCH_CARGOS_FAILURE = 'FETCH_CARGOS_FAILURE';

export const FETCH_CARGO_REQUEST = 'FETCH_CARGO_REQUEST';
export const FETCH_CARGO_SUCCESS = 'FETCH_CARGO_SUCCESS';
export const FETCH_CARGO_FAILURE = 'FETCH_CARGO_FAILURE';

export const CREATE_CARGO_REQUEST = 'CREATE_CARGO_REQUEST';
export const CREATE_CARGO_SUCCESS = 'CREATE_CARGO_SUCCESS';
export const CREATE_CARGO_FAILURE = 'CREATE_CARGO_FAILURE';

export const UPDATE_CARGO_REQUEST = 'UPDATE_CARGO_REQUEST';
export const UPDATE_CARGO_SUCCESS = 'UPDATE_CARGO_SUCCESS';
export const UPDATE_CARGO_FAILURE = 'UPDATE_CARGO_FAILURE';

export const DELETE_CARGO_REQUEST = 'DELETE_CARGO_REQUEST';
export const DELETE_CARGO_SUCCESS = 'DELETE_CARGO_SUCCESS';
export const DELETE_CARGO_FAILURE = 'DELETE_CARGO_FAILURE';

export const fetchCargos = () => async (dispatch) => {
  dispatch({ type: FETCH_CARGOS_REQUEST });
  try {
    const response = await cargoService.fetchCargos();
    dispatch({ type: FETCH_CARGOS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_CARGOS_FAILURE, payload: error.message });
  }
};

export const fetchCargo = (id) => async (dispatch) => {
  dispatch({ type: FETCH_CARGO_REQUEST });
  try {
    const response = await cargoService.fetchCargo(id);
    dispatch({ type: FETCH_CARGO_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_CARGO_FAILURE, payload: error.message });
  }
};

export const createCargo = (cargoData) => async (dispatch) => {
  dispatch({ type: CREATE_CARGO_REQUEST });
  try {
    const response = await cargoService.createCargo(cargoData);
    dispatch({ type: CREATE_CARGO_SUCCESS, payload: response.data });
    return response.data;
  } catch (error) {
    dispatch({ type: CREATE_CARGO_FAILURE, payload: error.message });
    return Promise.reject(error);
  }
};

export const updateCargo = (id, cargoData) => async (dispatch) => {
  dispatch({ type: UPDATE_CARGO_REQUEST });
  try {
    const response = await cargoService.updateCargo(id, cargoData);
    dispatch({ type: UPDATE_CARGO_SUCCESS, payload: response.data });
    return response.data;
  } catch (error) {
    dispatch({ type: UPDATE_CARGO_FAILURE, payload: error.message });
    return Promise.reject(error);
  }
};

export const deleteCargo = (id) => async (dispatch) => {
  dispatch({ type: DELETE_CARGO_REQUEST });
  try {
    await cargoService.deleteCargo(id);
    dispatch({ type: DELETE_CARGO_SUCCESS, payload: id });
  } catch (error) {
    dispatch({ type: DELETE_CARGO_FAILURE, payload: error.message });
    return Promise.reject(error);
  }
};
