import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createUniversidad, fetchUniversidades, updateUniversidad } from '../actions/universidadActions';
import { fetchCiudades } from '../actions/ciudadActions';
import { toast } from 'react-toastify';

const UniversidadForm = ({ universidad, onClose }) => {
  const dispatch = useDispatch();
  const ciudades = useSelector(state => state.ciudad.ciudades);
  const [universidadData, setUniversidadData] = useState({ sigla: '', nombre: '', codciudad: '' });

  //console.log("Univ",universidad)
  useEffect(() => {
    dispatch(fetchCiudades());
    if (universidad) {
      setUniversidadData({ sigla: universidad.sigla, nombre: universidad.nombre, codciudad: universidad.codciudad });
    } else {
      setUniversidadData({ sigla: '', nombre: '', codciudad: '' }); // Reset form for new entry
    }
  }, [universidad, dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    if (name === 'sigla') {
      newValue = value.toUpperCase().slice(0, 8); // Convert to uppercase and limit to 8 characters
    } else if (name === 'nombre') {
      newValue = value.toUpperCase(); // Convert to uppercase
    }
    setUniversidadData({ ...universidadData, [name]: newValue });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (universidad) {
      // Update existing university
      if (!universidadData.sigla || !universidadData.nombre || !universidadData.codciudad) {
        toast.error("Por favor complete los campos requeridos: sigla, nombre y ciudad");
        return;
      }
      dispatch(updateUniversidad(universidad.id, universidadData))
        .then(() => {
          toast.success('Universidad actualizada correctamente');
          dispatch(fetchUniversidades()).catch(error => {
            toast.error(`Error fetching universities: ${error}`);
          });
          onClose();
        })
        .catch(error => {
          toast.error(`Error al actualizar la universidad: ${error.message || error}`);
        });
    } else {
      // Create new university
      if (!universidadData.sigla || !universidadData.nombre || !universidadData.codciudad) {
        toast.error("Por favor complete los campos requeridos: sigla, nombre y ciudad");
        return;
      }
      dispatch(createUniversidad(universidadData))
        .then(() => {
          toast.success('Universidad creada correctamente');
          setUniversidadData({ sigla: '', nombre: '', codciudad: '' }); // Reset form after successful creation
          dispatch(fetchUniversidades()).catch(error => {
            toast.error(`Error fetching universities: ${error}`);
          });
          onClose();
        })
        .catch(error => {
          toast.error(`Error al crear la universidad: ${error.message || error}`);
        });
    }
  };

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <label>Sigla:</label>
        <input
          type="text"
          name="sigla"
          value={universidadData.sigla}
          onChange={handleChange}
        />
        <label>Nombre:</label>
        <input
          type="text"
          name="nombre"
          value={universidadData.nombre}
          onChange={handleChange}
        />
        <label>Ciudad:</label>
        <select
          name="codciudad"
          value={universidadData.codciudad}
          onChange={handleChange}
        >
          <option value="">Seleccione una ciudad</option>
          {ciudades.map(ciudad => (
            <option key={ciudad.id} value={ciudad.id}>{ciudad.nombre}</option>
          ))}
        </select>
        <div className='button-group'>
          <button className="button btn-cancel" type="button" onClick={onClose} style={{ marginLeft: '10px' }}>
          Cancelar
          </button>
          <button className="button btn-accent" type="submit">
            Guardar
          </button>
        </div>
      </form>
  );
};

export default UniversidadForm;
