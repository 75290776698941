import { useState } from "react";
import imageCompression from 'browser-image-compression'

const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 800,
    useWebWorker: true
    };

function validationFn(type, file){
    if (!file) return false;
    
    if(type === "image" && file.type.startsWith('image/'))
        return true;
    else return false;
    
}

async function compressImage(image){
    const compressedFile = await imageCompression(image, options);
    // Set the name of the compressed file to be the same as the original file
    const fileExtension = image.name.split('.').pop();
    const fileNameWithoutExtension = image.name
      .replace(/\s+/g, '-')
      .replace(/_/g, '-')
      .replace(new RegExp(`.${fileExtension}$`), '');
    const newFileName = `${fileNameWithoutExtension}-compressed.${fileExtension}`;

    const compressedFileWithName = new File(
       [compressedFile],
       newFileName,
       {type: compressedFile.type}
   );
 
   return compressedFileWithName;
}
export function useInputFile(){
    const [file, setFile] = useState(null);
    const [isValid, setIsValid] = useState(true);

    async function handleFileChange(newFile) {
        if(validationFn("image", newFile)){
            try {
            const compressedFile = await compressImage(newFile)
            setFile(compressedFile);
            setIsValid(true);
        
        } catch (error) {
            setIsValid(false);
            //console.log(error);
        }
     }else{setFile(null);
        setIsValid(false);
     }
    }
    function resetInputFile(){
        setFile(null)
        setIsValid(true)
    }

    return {
        file,
        resetInputFile,
        handleFileChange, 
        isValid
    }
}