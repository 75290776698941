function numberToLiteral(number) {
    const units = ['', 'UNO', 'DOS', 'TRES', 'CUATRO', 'CINCO', 'SEIS', 'SIETE', 'OCHO', 'NUEVE'];
    const tens = ['', 'DIEZ', 'VEINTE', 'TREINTA', 'CUARENTA', 'CINCUENTA', 'SESENTA', 'SETENTA', 'OCHENTA', 'NOVENTA'];
    const teens = ['DIEZ', 'ONCE', 'DOCE', 'TRECE', 'CATORCE', 'QUINCE', 'DIECISÉIS', 'DIECISIETE', 'DIECIOCHO', 'DIECINUEVE'];
    const hundreds = ['', 'CIENTO', 'DOSCIENTOS', 'TRESCIENTOS', 'CUATROCIENTOS', 'QUINIENTOS', 'SEISCIENTOS', 'SETECIENTOS', 'OCHOCIENTOS', 'NOVECIENTOS'];

    if (number === 0) return 'CERO';
    if (number === 100) return 'CIEN';

    function getLiteral(num) {
        let numStr = num.toString();
        let literal = '';

        if (numStr.length === 3) {
            literal += hundreds[parseInt(numStr[0])] + ' ';
            numStr = numStr.slice(1);
        }

        if (parseInt(numStr) > 9 && parseInt(numStr) < 20) {
            literal += teens[parseInt(numStr) - 10] + ' ';
        } else {
            if (numStr.length === 2 && numStr[0] !== '0') {
                literal += tens[parseInt(numStr[0])] + ' ';
            }
            if (numStr.length >= 1 && numStr[numStr.length - 1] !== '0') {
                literal += units[parseInt(numStr[numStr.length - 1])] + ' ';
            }
        }

        return literal.trim();
    }

    let literal = '';
    let millions = Math.floor(number / 1000000);
    let thousands = Math.floor((number % 1000000) / 1000);
    let remainder = number % 1000;

    if (millions > 0) {
        literal += getLiteral(millions) + ' MILLONES ';
    }
    if (thousands > 0) {
        literal += getLiteral(thousands) + ' MIL ';
    }
    if (remainder > 0) {
        literal += getLiteral(remainder);
    }

    return literal.trim();
}

export function formatNumberToLiteral(number) {
    const integerPart = Math.floor(number);
    const decimalPart = Math.round((number - integerPart) * 100);
    const integerLiteral = numberToLiteral(integerPart);
    const decimalLiteral = decimalPart > 0 ? decimalPart.toString().padStart(2, '0') : '00';
    return `${integerLiteral} ${decimalLiteral}/100 Bs.`;
}
