// src/services/datgenService.js
import createAxiosInstance from "../util/axiosInstance";

const api = createAxiosInstance('/datgen');

const fetchDatgens = () => {
    return api.get('/');
};

const fetchDatgen = (id) => {
    return api.get(`/${id}`);
};
const updateDatgen = (id, formData) => {
    // Verificar los valores antes de enviar
    //console.log('FormData en el servicio:');

    for (var pair of formData.entries()) {
      //console.log(pair[0] + ', ' + pair[1]);
  }
    return api.put(`/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  };


export default {
    fetchDatgens,
    fetchDatgen,
    updateDatgen
};


