import createAxiosInstance from "../util/axiosInstance";

const api = createAxiosInstance('/directivas');

const fetchDirectivas = () => api.get('/');
const fetchDirectiva = (id) => api.get(`/${id}`);
const createDirectiva = (data) => api.post('/', data);
const updateDirectiva = (id, data) => api.put(`/${id}`, data);
const deleteDirectiva = async (id) => {
    try {
        await api.delete(`/${id}`);
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Error al eliminar la directiva');
    }
};

export default {
  fetchDirectivas,
  fetchDirectiva,
  createDirectiva,
  updateDirectiva,
  deleteDirectiva
};
