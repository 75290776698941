// src/reducers/datgenReducer.js
import {
    FETCH_DATGENS_SUCCESS,
    FETCH_DATGENS_FAILURE,
    FETCH_DATGEN_SUCCESS,
    FETCH_DATGEN_FAILURE,
    UPDATE_DATGEN_SUCCESS,
    UPDATE_DATGEN_FAILURE
} from '../actions/datgenActions';

const initialState = {
    datgens: [],
    currentDatgen: null,
    error: null,
    success: false
};

const datgenReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DATGENS_SUCCESS:
            return {
                ...state,
                datgens: action.payload,
                error: null
            };
        case FETCH_DATGENS_FAILURE:
        case FETCH_DATGEN_FAILURE:
        case UPDATE_DATGEN_FAILURE:
            return {
                ...state,
                error: action.payload
            };
        case FETCH_DATGEN_SUCCESS:
            return {
                ...state,
                currentDatgen: action.payload,
                error: null
            };
        case UPDATE_DATGEN_SUCCESS:
            return {
                ...state,
                datgens: state.datgens.map(datgen =>
                    datgen.id === action.payload.id ? action.payload : datgen
                ),
                currentDatgen: action.payload,
                error: null,
                success:true
            };
        default:
            return state;
    }
};

export default datgenReducer;
