const menus= 
[
    { title: "Administración",
      sections: [
       
  { name:"Administración del Sistema",
    path:"/sistema",
    options:  [ {name:"Gestión de Ciudades",  path:"/ciudades"},
                {name:"Gestión de Países",  path:"/paises"},
                {name:"Gestión de Universidades",  path:"/universidades"},
                {name:"Gestión Noticias",  path:"/noticias"},
    
              ]
  },  
        
      ]
    },
    { title: "Gestión Socios",
      sections: [
        { name:"Gestión Socios",
          path:"/socios",
          options:  [ {name:"Cuadro de Mando Socio",  path:"/socios/2"},
                      {name:"Estado de cuenta de un socio",  path:`/socio-list-gen/${encodeURIComponent("Estado de cuenta de un socio")}/5`},
                      {name:"Cambiar estado a un socio",  path:`/socio-list-gen/${encodeURIComponent("Cambio de Estado a un socio")}/1`},
                      {name:"Certificado de registro profesional", path:`/socio-list-gen/${encodeURIComponent("Certificado de Registro")}/2`},
                      {name:"Certificado de solvencia", path:`/socio-list-gen/${encodeURIComponent("Certificado de Solvencia")}/3`},
                      {name:"Ficha de Registro del Socio", path:`/socio-list-gen/${encodeURIComponent("Ficha de Registro del Socio")}/4`},
                    ]
        },
      ]
    },

    { title: "Gestión Directivas",
      sections: [
        { name:"Gestión Directivas",
        path:"/directivas",
        options:  [ {name:"Administración Directivas",  path:`/directivas/${encodeURIComponent("Administración directivas")}/1`},
                    {name:"Activación de la nueva directiva",  path:`/directivas/${encodeURIComponent("Activación nueva directiva")}/2`},
                  ]
      },
  
      ]
    },
   
  ];
  
export default menus;