import {
    FETCH_UNIVERSIDADES_REQUEST,
    FETCH_UNIVERSIDADES_SUCCESS,
    FETCH_UNIVERSIDADES_FAILURE,
    CREATE_UNIVERSIDAD_REQUEST,
    CREATE_UNIVERSIDAD_SUCCESS,
    CREATE_UNIVERSIDAD_FAILURE,
    UPDATE_UNIVERSIDAD_REQUEST,
    UPDATE_UNIVERSIDAD_SUCCESS,
    UPDATE_UNIVERSIDAD_FAILURE,
    DELETE_UNIVERSIDAD_REQUEST,
    DELETE_UNIVERSIDAD_SUCCESS,
    DELETE_UNIVERSIDAD_FAILURE,
    FETCH_UNIVERSIDAD_REQUEST,
    FETCH_UNIVERSIDAD_SUCCESS,
    FETCH_UNIVERSIDAD_FAILURE
} from '../actions/universidadActions';

const initialState = {
    universidades: [],
    error: null,
    loading: false
};

const universidadReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_UNIVERSIDADES_REQUEST:
        case CREATE_UNIVERSIDAD_REQUEST:
        case UPDATE_UNIVERSIDAD_REQUEST:
        case DELETE_UNIVERSIDAD_REQUEST:
        case FETCH_UNIVERSIDAD_REQUEST:
            return {
                ...state,
                loading: true
            };
        case FETCH_UNIVERSIDADES_SUCCESS:
            return {
                ...state,
                universidades: action.payload,
                error: null,
                loading: false
            };
        case FETCH_UNIVERSIDAD_SUCCESS:
            return {
                ...state,
                universidades: state.universidades.map(universidad =>
                    universidad.id === action.payload.id ? action.payload : universidad
                ),
                error: null,
                loading: false
            };
        case CREATE_UNIVERSIDAD_SUCCESS:
            return {
                ...state,
                universidades: [...state.universidades, action.payload],
                error: null,
                loading: false
            };
        case UPDATE_UNIVERSIDAD_SUCCESS:
            return {
                ...state,
                universidades: state.universidades.map(universidad =>
                    universidad.id === action.payload.id ? action.payload : universidad
                ),
                error: null,
                loading: false
            };
        case DELETE_UNIVERSIDAD_SUCCESS:
            return {
                ...state,
                universidades: state.universidades.filter(universidad => universidad.id !== action.payload),
                error: null,
                loading: false
            };
        case FETCH_UNIVERSIDADES_FAILURE:
        case CREATE_UNIVERSIDAD_FAILURE:
        case UPDATE_UNIVERSIDAD_FAILURE:
        case DELETE_UNIVERSIDAD_FAILURE:
        case FETCH_UNIVERSIDAD_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
};

export default universidadReducer;