import universidadService from '../services/universidadService'; // Ensure this service is correctly configured
import axios from 'axios';

export const FETCH_UNIVERSIDADES_REQUEST = 'FETCH_UNIVERSIDADES_REQUEST';
export const FETCH_UNIVERSIDADES_SUCCESS = 'FETCH_UNIVERSIDADES_SUCCESS';
export const FETCH_UNIVERSIDADES_FAILURE = 'FETCH_UNIVERSIDADES_FAILURE';

export const FETCH_UNIVERSIDAD_REQUEST = 'FETCH_UNIVERSIDAD_REQUEST';
export const FETCH_UNIVERSIDAD_SUCCESS = 'FETCH_UNIVERSIDAD_SUCCESS';
export const FETCH_UNIVERSIDAD_FAILURE = 'FETCH_UNIVERSIDAD_FAILURE';

export const CREATE_UNIVERSIDAD_REQUEST = 'CREATE_UNIVERSIDAD_REQUEST';
export const CREATE_UNIVERSIDAD_SUCCESS = 'CREATE_UNIVERSIDAD_SUCCESS';
export const CREATE_UNIVERSIDAD_FAILURE = 'CREATE_UNIVERSIDAD_FAILURE';

export const UPDATE_UNIVERSIDAD_REQUEST = 'UPDATE_UNIVERSIDAD_REQUEST';
export const UPDATE_UNIVERSIDAD_SUCCESS = 'UPDATE_UNIVERSIDAD_SUCCESS';
export const UPDATE_UNIVERSIDAD_FAILURE = 'UPDATE_UNIVERSIDAD_FAILURE';

export const DELETE_UNIVERSIDAD_REQUEST = 'DELETE_UNIVERSIDAD_REQUEST';
export const DELETE_UNIVERSIDAD_SUCCESS = 'DELETE_UNIVERSIDAD_SUCCESS';
export const DELETE_UNIVERSIDAD_FAILURE = 'DELETE_UNIVERSIDAD_FAILURE';

// Fetch all universities
export const fetchUniversidades = () => async (dispatch) => {
    dispatch({ type: FETCH_UNIVERSIDADES_REQUEST });
    try {
        const response = await universidadService.fetchUniversidades();
        dispatch({ type: FETCH_UNIVERSIDADES_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: FETCH_UNIVERSIDADES_FAILURE, payload: error.message });
    }
};


// Acción para obtener una ciudad específica por ID
export const fetchUniversidad = (id) => async (dispatch) => {
    dispatch({ type: FETCH_UNIVERSIDAD_REQUEST });
    try {
      const response = await universidadService.fetchUniversidad(id); // Ajusta la URL según tu configuración
      dispatch({ type: FETCH_UNIVERSIDAD_SUCCESS, payload: response.data });
      return response;
      
    } catch (error) {
      dispatch({ type: FETCH_UNIVERSIDAD_FAILURE, payload: error.message });
    }
  };

/*
// Fetch a single university by ID
export const fetchUniversidad = (id) => async (dispatch) => {
    dispatch({ type: FETCH_UNIVERSIDAD_REQUEST });
    try { //console.log("entre a actions...",id)
        const response = await universidadService.fetchUniversidad(id);
        dispatch({ type: FETCH_UNIVERSIDAD_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: FETCH_UNIVERSIDAD_FAILURE, payload: error.message });
    }
};*/


// Acción para crear una nueva ciudad
export const createUniversidad = (universidadData) => async (dispatch) => {
    dispatch({ type: CREATE_UNIVERSIDAD_REQUEST });
    try {
      const response = await universidadService.createUniversidad(universidadData);
      dispatch({ type: CREATE_UNIVERSIDAD_SUCCESS, payload: response.data });
      return response.data;
    } catch (error) {
      dispatch({ type: CREATE_UNIVERSIDAD_FAILURE, payload: error.message });
      return Promise.reject(error);
    }
  };

/*
// Create a new university
export const createUniversidad = (universidadData) => async (dispatch) => {
    dispatch({ type: CREATE_UNIVERSIDAD_REQUEST });
    try {
        const response = await universidadService.createUniversidad(universidadData);
        dispatch({ type: CREATE_UNIVERSIDAD_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: CREATE_UNIVERSIDAD_FAILURE, payload: error.message });
    }
};
*/
// Update an existing university
export const updateUniversidad = (id, universidadData) => async (dispatch) => {
    dispatch({ type: UPDATE_UNIVERSIDAD_REQUEST });
    try {
        const response = await universidadService.updateUniversidad(id, universidadData);
        dispatch({ type: UPDATE_UNIVERSIDAD_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: UPDATE_UNIVERSIDAD_FAILURE, payload: error.message });
    }
};

// Delete a university
export const deleteUniversidad = (id) => async (dispatch) => {
    dispatch({ type: DELETE_UNIVERSIDAD_REQUEST });
    try {
        const response = await universidadService.deleteUniversidad(id);
        if (response.status === 200) {
            dispatch({ type: DELETE_UNIVERSIDAD_SUCCESS, payload: id });
            dispatch(fetchUniversidades()); // Re-fetch the list to update the state
        } else {
            throw new Error('Deletion failed');
        }
    } catch (error) {
        dispatch({ type: DELETE_UNIVERSIDAD_FAILURE, payload: error.message });
    }
};