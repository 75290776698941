import createAxiosInstance from "../util/axiosInstance";

const api = createAxiosInstance('/roles');

const fetchRoles = () => {
    return api.get('/');
};

const fetchRol = (id) => {
    return api.get(`/${id}`);
};

const createRol = (data) => {
    return api.post('/', data);
};

const updateRol = (id, data) => {
    return api.put(`/${id}`, data);
};

const deleteRol = (id) => {
    return api.delete(`/${id}`);
};

export default {
    fetchRoles,
    createRol,
    updateRol,
    deleteRol,
    fetchRol
};
