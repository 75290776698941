import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchUniversidades, deleteUniversidad } from '../actions/universidadActions';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import { Pagination, Table, Button, Modal } from 'react-bootstrap';
import UniversidadForm from './UniversidadForm';
import CustomPrintModal from './CustomPrintModal';

const UniversidadList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        const fromHeader = location.state?.fromHeader;
        //console.log('fromHeader =', fromHeader);
        if (!fromHeader) {
            navigate('/'); // Redirige a la página principal si no es llamado desde Header
        }
    }, [navigate]);

    const dispatch = useDispatch();
    const { universidades, error, loading } = useSelector(state => state.universidad);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [showModal, setShowModal] = useState(false);
    const [selectedUniversidad, setSelectedUniversidad] = useState(null);
    const [showPrintModal, setShowPrintModal] = useState(false);
    const [content, setContent] = useState(null);

    useEffect(() => {
        dispatch(fetchUniversidades()).catch(error => {
            toast.error(`Error fetching universities: ${error}`);
        });
    }, [dispatch]);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentUniversidades = universidades.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(1);
    };
    const totalPages = Math.ceil(universidades.length / itemsPerPage);

    const handleDelete = (id) => {
        confirmAlert({
            title: 'Confirmar eliminación',
            message: '¿Estás seguro de que deseas eliminar esta universidad?',
            buttons: [
                {
                    label: 'Sí',
                    onClick: () => {
                        dispatch(deleteUniversidad(id))
                            .then(() => {
                                toast.success('Universidad eliminada correctamente');
                                dispatch(fetchUniversidades());
                            })
                            .catch(error => {
                                toast.error(`Error al eliminar la universidad: ${error.message}`);
                            });
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    };

    const handlePrintModalShow = () => {
        const content = {
            head: [["N°", "Nombre de la Universidad"]],
            body: universidades.map((universidad, index) => [
                index+1,
                universidad.nombre,
            ])
        };
        setContent(content);
        setShowPrintModal(true);
    };

    const handleModalClose = () => setShowModal(false);
    const handleModalShow = (universidad) => {
        setSelectedUniversidad(universidad);
        setShowModal(true);
    };

    const handlePrintModalClose = () => {
        setShowPrintModal(false);
    };

    if (loading) return <p>Cargando universidades...</p>;
    if (error) return <p>Error al cargar los datos: {error}</p>;

    return (
        <div className="list2-container container-list">
            <h2>Listado de Universidades</h2>
            <div className="mt-3">
                <button onClick={() => handleModalShow(null)} className="button btn-accent float-end">
                    <i className="bi bi-plus"></i> Nueva Universidad
                </button>
                <label htmlFor="items-per-page">Registros por página:</label>
                <select id="items-per-page" value={itemsPerPage} onChange={handleItemsPerPageChange}>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                </select>
            </div>
            <div className='table-container'>
                <Table hover striped border={1}>
                    <thead>
                        <tr>
                            <th className='th-cod'>N°</th>
                            <th>Nombre</th>
                            <th className='th-actions'>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentUniversidades.map((universidad, index) => (
                            <tr key={universidad.id}>
                                 <td className='td-cod'>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                <td>{universidad.nombre}</td>
                                <td>
                                    <button onClick={() => handleModalShow(universidad)} className="button btn-accent-outline"><i className="bi bi-pencil"></i></button>
                                    <button onClick={() => handleDelete(universidad.id)} className="button btn-accent-outline"><i className="bi bi-trash"></i></button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
            <div className='button-group'>
                <button onClick={() => navigate('/')} className="button btn-cancel" style={{ marginRight: '10px' }}><i className="bi bi-arrow-left"></i>  Volver</button>
                <button onClick={handlePrintModalShow} className="button btn-accent"><i className="bi bi-printer"></i> Imprimir</button>
            </div>
            <div className="pagination-container">
                <Pagination className="custom-pagination">
                    <Pagination.First
                        onClick={() => paginate(1)}
                        disabled={currentPage === 1}
                    />
                    <Pagination.Prev
                        onClick={() => paginate(currentPage - 1)}
                        disabled={currentPage === 1}
                    />
                    {[...Array(totalPages)].map((_, index) => (
                        <Pagination.Item
                            key={index + 1}
                            active={index + 1 === currentPage}
                            onClick={() => paginate(index + 1)}
                        >
                            {index + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next
                        onClick={() => paginate(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    />
                    <Pagination.Last
                        onClick={() => paginate(totalPages)}
                        disabled={currentPage === totalPages}
                    />
                </Pagination>
            </div>

            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedUniversidad ? 'Editar Universidad' : 'Nueva Universidad'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UniversidadForm universidad={selectedUniversidad} onClose={handleModalClose} />
                </Modal.Body>
            </Modal>
            <CustomPrintModal show={showPrintModal} handleClose={handlePrintModalClose} title="LISTADO DE UNIVERSIDADES" content={content} />
        </div>
    );
};

export default UniversidadList;
