import { Carousel, CarouselCaption, CarouselItem, Col, Row } from 'react-bootstrap';
import './Inicio.css';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNoticias } from '../actions/noticiaActions'; // Ajusta la ruta según tu estructura
import { getAuthToken } from '../util/auth';

function InicioPage() {
  const dispatch = useDispatch();
  const { noticias } = useSelector(state => state.noticia || {});
  const UPLOADS_BASE_URL = process.env.REACT_APP_UPLOADS_BASE_URL;

  useEffect(() => {
    dispatch(fetchNoticias());
  }, [dispatch]);

  const getImageUrl = (foto) => {
    const token = getAuthToken();
    const timestamp = new Date().getTime();
    return `${UPLOADS_BASE_URL}/${foto}?token=${token}&timestamp=${timestamp}`;
  };

  return (
    <>
      <section>
        <div id="section-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 160"
            className="graphic"
          >
            <path
              fill="#FAF6EB"
              fillOpacity="1"
              d="M1,112
                L34.3,96
                C68.6,80,137,48,206,45.3
                C274.3,43,343,70,411,72
                C480,74.5,549,53.5,617,66.35
                C685.7,70,754,106,823,120
                C891.4,133,960,123,1029,112
                C1097.1,102,1166,90,1234,80
                C1302.9,69.5,1371,59,1406,53.5
                L1440,48L1440,0
                L1405.7,0
                C1371.4,0,1303,0,1234,0
                C1165.7,0,1097,0,1029,0
                C960,0,891,0,823,0
                C754.3,0,686,0,617,0
                C548.6,0,480,0,411,0
                C342.9,0,274,0,206,0
                C137.1,0,69,0,34,0
                L0,0Z"
            ></path>
          </svg>

          <Carousel id="carousel">
            {noticias.map((noticia, index) => (
              <CarouselItem key={noticia.id}>
                <img
                  src={getImageUrl(noticia.foto)} 
                  alt={`Slide #${index + 1}`}
                />
                <CarouselCaption style={{ textShadow: "2px 2px 4px rgba(0,0,0,0.5)" }}>
                  <h5>{noticia.titulo}</h5> 
                </CarouselCaption>
              </CarouselItem>
            ))}
          </Carousel>
          <div className="container" id="title-box">
            <h1 className="gradient-text">COLEGIO DE INGENIEROS AGRÓNOMOS</h1>
            <h3>Filial Tarija</h3>
          </div>
        </div>
      </section>
      <section>
        <div id="section-2">
          <h2>...</h2>
        </div>
      </section>
    </>
  );
}

export default InicioPage;
