const menus= 
[
    { title: "Gestión Socios",
      sections: [
        { name:"Gestión Socios",
          path:"/socios",
          options:  [ 
                      {name:"Estado de cuenta de un socio",  path:`/socio-list-gen/${encodeURIComponent("Estado de cuenta de un socio")}/5`},
                      {name:"Certificado de registro profesional", path:`/socio-list-gen/${encodeURIComponent("Certificado de Registro")}/2`},
                      {name:"Certificado de solvencia", path:`/socio-list-gen/${encodeURIComponent("Certificado de Solvencia")}/3`},
                      {name:"Ficha de Registro del Socio", path:`/socio-list-gen/${encodeURIComponent("Ficha de Registro del Socio")}/4`},
                    ]
        },
      ]
    },
   
  ];
  
export default menus;