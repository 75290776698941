import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchUsuarios, deleteUsuario } from '../actions/usuarioActions';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import UsuarioForm from './UsuarioForm';
import UsuarioClave from './UsuarioClave';
import UsuarioRol from './UsuarioRol';
import { toast } from 'react-toastify';
import { Table, Button } from 'react-bootstrap';
import CustomPrintModal from './CustomPrintModal';

const UsuarioList = () => {
    const { titulo, funcionalidad } = useParams(); // Captura los parámetros
    ////console.log("titulo=", titulo, "funcionalidad=", funcionalidad);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { usuarios, error, success } = useSelector(state => state.usuario || {});
    const [showForm, setShowForm] = useState(false);
    const [showClave, setShowClave] = useState(false);
    const [showRolUsuario, setShowRolUsuario] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [showPrintModal, setShowPrintModal] = useState(false);
    const [content, setContent] = useState(null);

    useEffect(() => {
        dispatch(fetchUsuarios());
    }, [dispatch]);

    const handleDelete = (id) => {
        confirmAlert({
            title: 'Confirmar eliminación',
            message: '¿Estás seguro de que deseas eliminar este usuario?',
            buttons: [
                {
                    label: 'Sí',
                    onClick: async () => {
                        try {
                            const response = await dispatch(deleteUsuario(id));
                            if (success && !error) {
                                toast.success('Usuario eliminado de la base de datos.');
                                dispatch(fetchUsuarios());
                            } else {
                                toast.error('Error al eliminar el usuario de la base de datos...tiene dependencias...');
                            }
                        } catch (error) {
                            toast.error('Error al eliminar el usuario de la base de datos... tiene dependencias');
                        }
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    };

    const handleShowForm = (id = null) => {
        setSelectedId(id);
        setIsEdit(!!id);
        if (funcionalidad === '1') {
            setShowForm(true);
        } else if (funcionalidad === '2') {
            setShowClave(true);
        } else if (funcionalidad === '3') {
            setShowRolUsuario(true);
        }
    };

    const handleCloseForm = () => {
        setShowForm(false);
        setShowClave(false);
        setShowRolUsuario(false);
        setSelectedId(null);
        setIsEdit(false);
    };

    useEffect(() => {
        if (error) {
            toast.error(`Error al cargar los datos: ${error}`);
        }
    }, [error]);

    const handlePrintModalShow = () => {
        const content = {
            head: [["N°", "Usuario", "Habilitado"]],
            body: usuarios.map((usuario, index) => [
                index+1,
                usuario.usuario,
                usuario.habilitado ? 'Sí' : 'No'
            ])
        };
        setContent(content);
        setShowPrintModal(true);
    };

    const handlePrintModalClose = () => {
        setShowPrintModal(false);
    };

    return (
        <div className="list4-container container-list">
            <h2>{titulo}</h2>
            <div className="mt-3">
            {funcionalidad === '1' && ( 
                <button onClick={() => handleShowForm()} className="button btn-accent float-end">
                    <i className="bi bi-plus-lg"></i>
                    Nuevo Usuario
                </button>
            )}
            </div>
            <div className='table-container'>
                <Table hover striped border={1}>
                    <thead>
                        <tr>
                            <th>N°</th>
                            <th>Usuario</th>
                            <th>Habilitado</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {usuarios.map((usuario, index) => (
                            <tr key={usuario.id}>
                                <td>{index+1}</td>     
                                <td>{usuario.usuario}</td>
                                <td>{usuario.habilitado ? 'Sí' : 'No'}</td>
                                <td>
                                    <button onClick={() => handleShowForm(usuario.id)} className="button btn-accent-outline"><i className="bi bi-pencil"></i></button>
                                    {funcionalidad === '1' && (
                                        <button onClick={() => handleDelete(usuario.id)} className="button btn-accent-outline"><i className="bi bi-trash"></i></button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
            <div className="text-center mb-3">
                <button onClick={() => navigate("/")} className="button btn-cancel" style={{ marginRight: '10px' }}>
                    <i className="bi bi-arrow-left"></i>
                    Volver
                </button>
                <button onClick={handlePrintModalShow} className="button btn-accent">
                    <i className="bi bi-printer"></i>
                    Imprimir
                </button>
            </div>
            {showForm && funcionalidad === '1' && (
                <UsuarioForm
                    show={showForm}
                    handleClose={handleCloseForm}
                    isEdit={isEdit}
                    selectedId={selectedId}
                />
            )}
            {showClave && funcionalidad === '2' && (
                <UsuarioClave
                    show={showClave}
                    handleClose={handleCloseForm}
                    selectedId={selectedId}
                />
            )}
            {showRolUsuario && funcionalidad === '3' && (
                <UsuarioRol
                    show={showRolUsuario}
                    handleClose={handleCloseForm}             
                    selectedId={selectedId}
                    isEdit={isEdit}
                />
            )}
            <CustomPrintModal show={showPrintModal} handleClose={handlePrintModalClose} title="Listado de Usuarios" content={content} />
        </div>
    );
};

export default UsuarioList;
